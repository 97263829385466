@mixin apply-northern-sociallogin-customer-account-create-styles() {
    .customer-account-create {
        .social-login-container {
            @include social-login-button-styles($button-size: 'regular');
            @include spacing($spacing-name: 'small', $margin-sides: ('top'));
            @include spacing($spacing-name: 'small', $padding-sides: ('top'));
            @include border($sides: ('top'));
        }
    }
}
