@mixin product-badge() {
    @include font-size('product-badge');
    @include line-height('product-badge');
    @include property('width', $product-badge-size);
    @include property('height', $product-badge-size);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    letter-spacing: $product-badge-letter-spacing;
    text-transform: $product-badge-text-transform;

    &.new,
    &.sale {
        @include font-size('product-badge-large');
        letter-spacing: $product-badge-letter-spacing * 2;
    }
}

@mixin apply-product-badge-styles() {
    .product-badge {
        @include product-badge();
    }
}

@mixin apply-product-badge-positioning() {
    position: absolute;
    top: rem-calc(20);
    right: rem-calc(20);
    z-index: get-z-index('above-sibling');
    pointer-events: none;
}

@mixin apply-product-grid-badge-styles() {
    .product-badge-container {
        @include apply-product-badge-styles();
        @include apply-product-badge-positioning();
    }
}

@mixin apply-product-detail-page-badge-styles() {
    .product {
        &.media {
            position: relative;

            .product-badge-container {
                @include apply-product-badge-styles();
                @include apply-product-badge-positioning();
            }
        }
    }
}
