@mixin apply-footer-styles() {
    @include apply-footer-container-styles();
    @include apply-footer-main-styles();
    @include apply-footer-additional-styles();
    @include apply-footer-sticky-styles();
    @include apply-footer-top-styles();
}

@mixin apply-footer-top-styles() {
    .footer-top {
        @include border($color: get-color($page-footer-top-border-color), $sides: ('top'), $additive: true);
        @include border($width: rem-calc(2), $color: get-color($page-footer-top-border-color), $sides: ('bottom'), $additive: true);
        background-color: get-color($page-footer-top-background-color);
        color: get-color($page-footer-top-color);
    }

    .footer-top-content {
        @include padded-max-width-container();
    }

    .footer-top-value-adds {
        @include apply-value-adds-styles();
    }
}

@mixin apply-footer-container-styles() {
    .footer {
        background-color: get-color($page-footer-background-color);
        color: get-color($page-footer-text-color);
    }
}

@mixin apply-footer-main-styles() {
    @include apply-footer-main-nav-styles();
    @include apply-footer-main-contact-styles();

    .footer-main {
        @include spacing(
            $spacing-name: 'large',
            $padding-sides: ('top')
        );
        background-color: get-color($page-footer-main-background-color);
        color: get-color($page-footer-main-text-color);
    }

    .footer-main-content {
        @include padded-max-width-container();
    }

    .footer-main-content-row {
        @include grid-row();

        &::after {
            @include border($sides: ('bottom'));

            @include breakpoint($page-footer-breakpoint-desktop) {
                display: block;
                width: 100%;
                content: '';
            }

            @each $breakpoint, $grid-gutter in $grid-column-gutters {
                @include breakpoint($breakpoint) {
                    margin-left: get-side($grid-gutter, 'left');
                }
            }

            @include breakpoint($page-footer-breakpoint-tablet) {
                @include spacing(
                    $spacing-name: $page-footer-main-spacing,
                    $margin-sides: ('top')
                );
            }
        }
    }
}

@mixin apply-footer-main-nav-styles() {
    .footer-nav {
        $width: (
            'small': 100%,
            $page-footer-breakpoint-desktop: percentage(8 / 12),
        );
        @include grid-column($width);
    }

    .footer-nav-inner {
        @include breakpoint($page-footer-breakpoint-tablet) {
            @include breakpoint(get-previous-breakpoint($page-footer-breakpoint-desktop) down) {
                @include spacing(
                    $spacing-name: $page-footer-main-spacing,
                    $padding-sides: ('bottom', 'top')
                );
                @include border($sides: ('top', 'bottom'));
            }
        }
    }

    .footer-links-container {
        @include responsive-accordion-container($page-footer-breakpoint-tablet);
        @include grid-row();
    }

    .footer-links-column {
        @include responsive-accordion-item($page-footer-breakpoint-tablet);
        $width: (
            'small': 100%,
            $page-footer-breakpoint-tablet: percentage(3 / 12),
        );
        @include grid-column($width);

        @include breakpoint(get-previous-breakpoint($page-footer-breakpoint-tablet) 'down') {
            padding-left: 0;
        }
    }

    .footer-links-heading {
        @include responsive-accordion-item-heading($page-footer-breakpoint-tablet);
        @include theme-typography-heading(
            $heading-type: 'h3',
            $include-spacing: true
        );
    }

    .footer-links-button {
        @include breakpoint(get-previous-breakpoint($page-footer-breakpoint-tablet) down) {
            @include theme-accordion-item-title-large();
        }

        @include breakpoint($page-footer-breakpoint-tablet) {
            display: none;
        }
    }

    .footer-links-content {
        @include responsive-accordion-item-body($page-footer-breakpoint-tablet);
    }

    .footer-link-list {
        @include unstyled-list();
    }

    .footer-link-item {
        &:not(:last-child) {
            margin-bottom: rem-calc(10);
        }
    }

    .footer-link {
        @include typography-anchor(
            $font-size: 'small-copy',
            $color-hover: 'primary',
            $text-decoration-hover: underline
        );
    }
}

@mixin apply-footer-main-contact-styles() {
    @include apply-footer-newsletter-styles();
    @include apply-footer-social-media-styles();

    .footer-contact {
        $width: (
            'small': 100%,
            $page-footer-breakpoint-desktop: percentage(4 / 12)
        );
        @include grid-column($width);

        @include breakpoint(get-previous-breakpoint($page-footer-contact-section-desktop-breakpoint) 'down') {
            @include spacing(
                $spacing-name: $page-footer-main-spacing,
                $padding-sides: ('bottom')
            );
            order: -1;
        }

        @include breakpoint($page-footer-contact-section-tablet-breakpoint) {
            display: flex;
        }
    }

    .footer-contact-row {
        @include grid-row();
        flex: 1 1 auto;

        @include breakpoint-or('small' 'down', $page-footer-contact-section-desktop-breakpoint) { //sass-lint:disable-line mixins-before-declarations
            flex-direction: column;
        }
    }
}

@mixin apply-footer-newsletter-styles() {
    .newsletter-subscribe-form-container {
        @include breakpoint($page-footer-contact-section-tablet-breakpoint) {
            flex: 1 1 auto;
        }

        .newsletter-subscribe-title {
            @include typography-heading-margin('h3');
        }

        .newsletter-subscribe-form {
            display: flex;
        }

        .newsletter-form-main {
            flex: 1 1 auto;
        }

        .newsletter-form-field {
            margin-bottom: 0;
        }

        .newsletter-form-input {
            height: $page-footer-newsletter-form-input-height;
        }

        .newsletter-form-subscribe-button {
            flex: 0 0 auto;
            height: $page-footer-newsletter-form-input-height;

            @include breakpoint($page-footer-newsletter-form-desktop-breakpoint) {
                margin-left: rem-calc(10);
            }
        }
    }
}

@mixin apply-footer-social-media-styles() {
    .footer-social-links-container {
        @include breakpoint-or('small' 'down', $page-footer-contact-section-desktop-breakpoint) {
            margin-top: rem-calc(30);
        }

        @include breakpoint($page-footer-contact-section-tablet-breakpoint) {
            flex: 0 0 auto;
        }

        @include breakpoint(get-previous-breakpoint($page-footer-contact-section-desktop-breakpoint)) {
            min-width: rem-calc(260);
        }
    }

    .footer-social-links-title {
        @include typography-heading-margin('h3');
    }

    .footer-social-links-list {
        @include unstyled-list();
        display: flex;
    }

    .footer-social-link-item {
        @include breakpoint($page-footer-contact-section-tablet-breakpoint) {
            margin-top: rem-calc(7);
        }

        @include breakpoint($page-footer-contact-section-desktop-breakpoint) {
            margin-top: 0;
        }

        &:first-child {
            margin-left: rem-calc(-5);
        }

        &:not(:last-child) {
            @include spacing(
                $spacing-name: 'footer-social-media',
                $margin-sides: ('right')
            );
        }
    }

    .footer-social-link {
        &:hover,
        &:focus {
            color: get-color('accent-3');
        }
    }

    .footer-social-icon {
        @include icon-selector() {
            @include icon-base() {
                @include font-size('social-icons');
            }
        }
    }

    .footer-social-icon-facebook {
        @include icon-selector() {
            content: $icon-facebook;
        }
    }

    .footer-social-icon-twitter {
        @include icon-selector() {
            content: $icon-twitter;
        }
    }

    .footer-social-icon-youtube {
        @include icon-selector() {
            content: $icon-youtube;
        }
    }

    .footer-social-icon-googleplus {
        @include icon-selector() {
            content: $icon-google-plus;
        }
    }

    .footer-social-icon-instagram {
        @include icon-selector() {
            content: $icon-instagram;
        }
    }

    .footer-social-icon-linkedin {
        @include icon-selector() {
            content: $icon-linked-in;
        }
    }
}

@mixin apply-footer-additional-styles() {
    .footer-additional {
        @include spacing($spacing-name: 'small', $padding-sides: ('top', 'bottom'));
    }

    .footer-additional-content {
        @include padded-max-width-container();

        .trust-elements {
            @include grid-column(
                $responsive-sizes: (
                    'small': column-width-percent(12),
                    'large': column-width-percent(5)
                ),
                $flex-grow: 0,
                $flex-shrink: 1
            );
            @include apply-trust-elements-styles();

            @include breakpoint('large') {
                justify-content: flex-end;
            }
        }
    }

    .footer-additional-content-row {
        @include grid-row();
    }

    .footer-additional-logo-copyright-container {
        @include grid-column(
            $responsive-sizes: (
                'large': column-width-percent(5)
            ),
            $flex-grow: 0,
            $flex-shrink: 1
        );
        @include spacing($spacing-name: 'small', $margin-sides: ('bottom'));
        flex-grow: 1;

        @include breakpoint('medium') {
            display: flex;
            align-items: center;
        }

        @include breakpoint('large') {
            margin-bottom: 0;
        }

        .logo {
            @include spacing($spacing-name: 'gutter', $margin-sides: ('bottom'));
            @include spacing($spacing-name: 'gutter', $margin-sides: ('right'), $min-size: 'medium');

            @include breakpoint('medium') {
                margin-bottom: 0;
            }

            display: block;
            flex-shrink: 0;
            width: 100%;
            max-width: rem-calc(100);
        }

        .copyright {
            @include font-size('small-copy');
            flex: 0 1 auto;
            color: get-color('primary');

            a {
                text-decoration: underline;
            }
        }
    }
}

@mixin apply-footer-sticky-styles() {
    .footer-sticky {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: get-z-index('footer-sticky');
    }
}

