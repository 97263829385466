@mixin theme-accordion-container() {
    @include accordion-container();
}

@mixin theme-accordion-item() {
    @include accordion-item();
    @include border($theme-accordion-border-width, $accordion-border-style, $accordion-border-color, ('bottom'), true);

    &:first-child {
        @include border($theme-accordion-border-width, $accordion-border-style, $accordion-border-color, ('top'), true);
    }
}

@mixin theme-accordion-item-title-large() {
    @include theme-accordion-item-title('h3');
}

@mixin theme-accordion-item-title(
    $heading-type: 'h4',
    $text-color: $accordion-title-color,
    $text-color-hover: 'accent-3',
    $padding: $accordion-title-padding,
    $background-color: $accordion-title-background-color,
    $background-color-hover: $accordion-title-hover-color-scale
) {
    @include accordion-title(
        $text-color: $text-color,
        $text-color-hover: $text-color-hover,
        $padding: $padding,
        $justify-content: space-between,
        $background-color: $background-color,
        $background-color-hover: $background-color-hover
    );
    @include typography-heading(
        $heading-type: $heading-type,
        $color: 'primary',
        $font-weight: 'semi-bold'
    );
    position: relative;
    outline: none;

    &[aria-expanded='true'] {
        &::before {
            @include accordion-title-collapse-icon();
        }
    }
}

@mixin theme-accordion-item-body(
    $padding: $accordion-padding,
    $border-width: $accordion-border-width,
    $text-color: $accordion-body-color,
    $border-sides: ()
) {
    @include accordion-body(
        $padding: $padding,
        $border-width: $border-width,
        $border-sides: $border-sides,
        $color: $text-color
    );
}

@mixin accordion-title-icon-base-extra(
    $padding: null,
    $extras: ()
) {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    order: 1;
    transform: translateY(-50%);
    line-height: 0;
    font-size: rem-calc(20);
    font-weight: get-font-weight('normal');
}
