@mixin apply-cart-page-layout-styles() {
    .content-columns {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;

        @include breakpoint('large') {
            grid-template-columns: (100% * (2/3)) (100% / 3);
        }
    }

    .main-content-panel {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
        width: auto;
    }

    .right-content-panel {
        @include spacing($spacing-name: 'medium', $margin-sides: ('top'));
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
        width: auto;

        @include breakpoint('large') {
            margin-top: 0;
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
        }
    }

    .bottom-content-panel {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 3;
        grid-row-end: 3;

        @include breakpoint('large') {
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 2;
            grid-row-end: 2;
        }
    }
}

@mixin checkout-cart-order-item-information-spacing() {
    @include property('margin-bottom', ('small': rem-calc(15), 'large': rem-calc(25)));
}

@mixin apply-checkout-cart-sidebar-styles() {
    .checkout-methods-items {
        @include unstyled-list();
    }

    .primary {
        &.checkout {
            @include button-with-icon($icon-cart, 'secondary', 'regular');
            @include spacing('xsmall', $margin-sides: ('top'));
            width: 100%;
            text-align: center;
        }
    }

    .cart-summary {
        @include theme-accordion-container();
        @include spacing($checkout-sales-order-item-outer-spacing, $margin-sides: ('top'));

        .checkout-sidebar-accordion-item,
        > .block {
            @include theme-accordion-item();

            &:not(:first-of-type) {
                > .title {
                    @include border($color: $checkout-sales-order-item-border-color, $sides: ('top'));
                }
            }

            .checkout-sidebar-accordion-header,
            > .title {
                @include theme-accordion-item-title($background-color: 'neutral-lightest');
                @include border($color: $checkout-sales-order-item-border-color, $sides: ('left', 'right', 'top'));
                @include spacing($spacing-name: 'checkout-sidebar', $padding-sides: ('left', 'right'));

                &::before,
                &[aria-expanded='true']::before {
                    @include property('right', get-spacing('checkout-sidebar'));
                }
            }

            .checkout-sidebar-accordion-body {
                @include theme-accordion-item-body();
                padding-bottom: 0;
            }

            .checkout-sidebar-accordion-body,
            > .content {
                @include border($color: $checkout-sales-order-item-border-color, $sides: ('left', 'right'));
            }
        }

        .checkout-sidebar-promo-form {
            @include apply-checkout-sidebar-promo-form();

            .field {
                margin-bottom: 0;
            }
        }

        .cart-totals {
            @include border($color: $checkout-sales-order-item-border-color, $sides: ('left', 'bottom', 'right'), $additive: true);
        }

        .shipping {
            display: none;
        }
    }
}

@mixin checkout-cart-action-styles() {
    .action {
        @include font('primary', 'normal');
        @include font-size('small-copy');
        display: inline-flex;
        align-items: center;
        text-transform: none;

        &:hover,
        &:focus {
            color: get-color('secondary');
        }

        &:not(:last-child) {
            margin-right: rem-calc(20);
        }
    }

    .action-edit {
        @include icon($icon: $icon-edit) {
            margin-right: rem-calc(5);
            font-size: rem-calc(18);
        }
    }

    .action-delete {
        @include icon($icon: $icon-warning) {
            margin-right: rem-calc(5);
            font-size: rem-calc(18);
        }
    }
}

@mixin apply-checkout-cart-order-item-styles() {
    .order-items {
        @include spacing($checkout-sales-order-item-outer-spacing, $margin-sides: ('top'));
        @include border($color: $checkout-sales-order-item-border-color);
        background-color: get-color($checkout-sales-order-item-background-color);
    }

    .order-item-row {
        @include spacing($checkout-sales-order-item-inner-spacing, $padding-sides: ('all'));
        @include grid-row($vertical-gutters: true);
        @include checkout-sales-order-item($image-responsive-sizes: $checkout-cart-order-item-image-sizes, $product-name-font-size: 'product-name-medium');

        .item-product-info {
            .product-item-name,
            .item-options,
            .item-total {
                @include checkout-cart-order-item-information-spacing();
            }

            .item-options {
                :last-child {
                    margin-bottom: 0;
                }

                .product-info-option-label {
                    text-align: left;
                }
            }

            .item-price {
                margin: 0;
            }
        }

        .item-price-row {
            display: flex;
            align-items: center;
        }

        .item-quantity {
            display: flex;
            flex: 1 0 auto;
            align-items: center;

            .label {
                width: rem-calc(60);

                input {
                    padding-top: rem-calc(6);
                }
            }

            .divider {
                @include icon($icon: $icon-close) {
                    font-size: rem-calc(20);
                }
                margin-left: rem-calc(15);
                font-size: rem-calc(20);
            }

            .cart-price {
                margin-left: rem-calc(10);
            }
        }

        .actions-toolbar {
            @include checkout-cart-action-styles();

            @include breakpoint('small' 'only') {
                display: none;
            }
        }

        .mobile-actions-toolbar {
            @include checkout-cart-action-styles();
            @include border($color: $checkout-sales-order-item-border-color, $sides: ('top'));
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: rem-calc(20);
            margin-left: rem-calc(20);
            padding-left: 0;

            @include breakpoint('medium') {
                display: none;
            }

            .gift-options-cart-item {
                display: none;
            }
        }

        .item-options {
            order: 1;
        }

        .item-price {
            order: 2;
        }
    }
}

@mixin apply-cart-items-actions-styles() {
    .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: rem-calc(30);

        .clear {
            display: none;
        }

        .update,
        .continue {
            @include tertiary-button();
            flex: 0 0 auto;

            @include breakpoint('small' 'only') {
                width: 100%;
                text-align: center;
            }

            &:not(:first-child) {
                @include breakpoint('small' 'only') {
                    margin-top: rem-calc(10);
                }
            }
        }
    }
}

@mixin apply-cart-page-cross-sell-styles() {
    .cart-cross-sell-section {
        @include spacing($spacing-name: 'cart-cross-sell', $padding-sides: ('top'), $margin-sides: ('top'));
        @include border($sides: ('top'));
    }

    .cart-cross-sell-title {
        @include spacing($spacing-name: 'cart-cross-sell', $margin-sides: ('bottom'));
    }

    .cross-sell {
        @include product-grid();
        @include product-cards();
    }
}

@mixin apply-checkout-cart-styles() {
    .checkout-cart-index {
        @include apply-cart-page-layout-styles();
        @include apply-checkout-sidebar-totals-styles();
        @include apply-checkout-cart-order-item-styles();
        @include apply-cart-page-cross-sell-styles();
        @include apply-cart-items-actions-styles();
        @include apply-cart-image-zoom-styles();

        .page-title-wrapper {
            @include theme-page-title();
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .page-title {
                @include breakpoint('small' 'only') {
                    width: 100%;
                    margin-bottom: rem-calc(20);
                }
            }

            .primary {
                @include button-with-icon($icon-cart, 'secondary', 'small');
                padding-right: rem-calc(30);
                padding-left: rem-calc(30);

                @include breakpoint('small' 'only') {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .product-photo-inner {
            position: relative;
        }

        .right-content-panel {
            @include apply-checkout-cart-sidebar-styles();
        }

        .checkout-value-adds {
            @include apply-checkout-value-adds-list();
            @include spacing($spacing-name: 'checkout-sidebar', $margin-sides: ('top'));
            @include spacing($spacing-name: 'checkout-sidebar', $padding-sides: ('top'));
            @include border($sides: ('top'));

            @include breakpoint('large') {
                border-bottom: 0;
            }

            ul {
                line-height: 1.5;

                &:first-child {
                    margin-bottom: rem-calc(20);
                }
            }
        }
    }
}
