$responsive-video-padding: ((9 / 16) * 100%);

@mixin apply-responsive-videos-styles() {
    .responsive-iframe {
        @include spacing('xxsmall', $margin-sides: ('top'));
        position: relative;
        overflow: hidden;
        padding-top: $responsive-video-padding;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}

