$barstools-list-spacing: (
    'small': rem-calc(5)
);
$barstools-ordered-list-style-right-spacing: rem-calc(8);
$barstools-list-style-spacing: rem-calc(18);
$barstools-list-style-type: none;
$barstools-list-style-font-size: rem-calc(25);
$barstools-list-style-font-weight: 'bold';
$barstools-list-color: 'primary';

@mixin unordered-list(
    $list-spacing: $barstools-list-spacing,
    $list-style-spacing: $barstools-list-style-spacing,
    $list-style-font-size: $barstools-list-style-font-size,
    $list-style-font-weight: $barstools-list-style-font-weight,
    $list-color: $barstools-list-color
) {
    @include typography-unordered-list(
        $style-type: $barstools-list-style-type
    );

    li {
        @each $breakpoint, $margin in $list-spacing {
            @include breakpoint($breakpoint) {
                margin-bottom: $margin;
            }
        }

        &::before {
            float: left;
            margin-left: -$list-style-spacing;
            width: $list-style-spacing;
            line-height: 1;
            color: get-color($list-color);
            font-size: $list-style-font-size;
            font-weight: get-font-weight($list-style-font-weight);
            content: '•';
        }
    }
}

@mixin ordered-list(
    $list-spacing: $barstools-list-spacing,
    $list-style-spacing: $barstools-list-style-spacing,
    $list-style-font-size: $barstools-list-style-font-size,
    $list-style-font-weight: $barstools-list-style-font-weight,
    $list-color: $barstools-list-color,
    $ordered-list-style-right-spacing: $barstools-ordered-list-style-right-spacing
) {
    @include typography-unordered-list(
        $style-type: $barstools-list-style-type
    );

    li {
        @each $breakpoint, $margin in $list-spacing {
            @include breakpoint($breakpoint) {
                margin-bottom: $margin;
            }
        }
        counter-increment: li;

        &::before {
            margin-right: $ordered-list-style-right-spacing;
            margin-left: -$list-style-spacing;
            color: get-color($list-color);
            font-weight: get-font-weight($list-style-font-weight);
            content: counter(li) '.';
        }
    }
}
