@mixin product-review-title() {
    @include heading-styles-h3();
    @include spacing('gutter', $padding-sides: ('bottom'), $max-size: 'small');
    @include border($sides: ('bottom'));
}

@mixin apply-product-review-styles() {
    .product-review-container {
        @include spacing('medium', $margin-sides: ('top'), $max-size: 'small');
    }

    .review-list-title,
    .review-form-title {
        @include product-review-title();
    }

    .reviews-toggle {
        @include reset-default-button-styles();
        @include spacing('medium', $padding-sides: ('all'), $max-size: 'small');
        background: get-color('neutral-lighter');
        width: 100%;
        text-align: center;

        .reviews-toggle-inner {
            @include button('primary', 'small');
            padding-right: rem-calc(22);
            padding-left: rem-calc(22);
        }
    }

    .review-items {
        @include unstyled-list();
    }

    .review-item {
        @include spacing('gutter', $padding-sides: ('top','bottom'));
        @include grid-row();

        &:not(:first-child) {
            @include border($sides: ('top'));
        }

        .review-title {
            @include heading-styles-h5();
            margin-bottom: rem-calc(10);
        }

        .review-ratings {
            @include icon-rating($icon: $rating-icon);
        }

        .rating-summary-container {
            float: left;
            margin: rem-calc(0 20 10 0);
            font-size: rem-calc(25);

            .review-field-label {
                @include font-size('small-copy');
                color: get-color('neutral-light');
                font-family: get-font-stack('sans-serif');
            }
        }

        .rating-summary {
            position: relative;
            line-height: 1;
        }

        .review-details {
            @include font-size('h4');
            @include grid-column($responsive-review-details-sizes, 0, 0);
            color: get-color('neutral-dark');

            .review-author {
                color: get-color('neutral-dark');
                margin-bottom: rem-calc(10);
            }

            .review-date {
                display: block;
                margin-bottom: rem-calc(10);
            }
        }

        .review-content-wrapper {
            @include grid-column($responsive-review-content-sizes, 0, 0);
        }

        &.hidden {
            display: none;
        }
    }

    .review-field-inputs {
        @include form-styles();
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .field {
            display: inline-flex;
            flex-basis: auto;
            width: 100%;

            @include breakpoint('large') {
                width: 50%;

                @each $breakpoint, $value in get-breakpoint-values-greater-than($form-margin, 'large') {
                    @include breakpoint($breakpoint) {
                        width: calc(50% - #{$value * 0.5});
                    }
                }
            }

            &:last-child {
                flex-grow: 1;
            }

            textarea {
                display: block;
                min-height: rem-calc(150);
                resize: vertical;
            }

            .label {
                @include kindling-sneaky-labels();
            }
        }
    }

    .review-form-actions {
        .action.submit {
            @include primary-button();
            width: 100%;

            @include breakpoint('large') {
                width: auto;
            }
        }
    }

    .review-form-content {
        @include grid-row();
        @include spacing('gutter', $padding-sides: ('top'));
    }

    .review-fieldset {
        &.review-product-info {
            @include grid-column($responsive-review-details-sizes, 0, 0);
        }

        &.review-product-form {
            @include grid-column($responsive-review-content-sizes, 0, 0);
        }

        .review-legend {
            @include font-size('small-copy');
        }

        .review-product-name {
            @include heading-styles-h4();
            display: block;
            margin: rem-calc(5 0 10);
        }

        .review-field-label {
            @include font-size('small-copy');
            color: get-color('neutral-dark');
            margin-top: rem-calc(5);
        }
    }

    .review-field-rating {
        float: left;
        margin: rem-calc(0 20 20 0);
        line-height: 1;

        .review-rating {
            @include icon-rating-form-field();
        }

        .mage-error {
            @include validation-error();
        }
    }

    .rating-label {
        @include icon-rating($icon: $rating-icon);
        @include icon-rating-hover();
        @include font-size('h3');
        vertical-align: top;
        line-height: 1;

        &.rating-5 {
            position: static;
        }
    }

    .rating-result.filled {
        overflow: hidden;
        white-space: nowrap;
    }

    .rating-label,
    .rating-result.filled {
        display: inline-flex;
        position: absolute;
        top: 0;
        left: 0;
    }

    .review-add {
        @include spacing($spacing-name: 'medium', $padding-sides: ('top'), $max-size: 'small');
    }

    .message {
        &.notlogged {
            text-align: center;

            a {
                text-decoration: underline;
            }
        }
    }
}
