@mixin apply-sales-order-view-navigation-tab-styles() {
    .order-links {
        @include unstyled-list();
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .nav.item {
            @include border($sides: ('top', 'left', 'right'));
            flex-grow: 1;

            @include breakpoint('medium') {
                flex-grow: 0;
            }

            &.current {
                background: get-color('neutral-lighter');

                @include breakpoint(get-previous-breakpoint('medium') down) {
                    order: 10;
                }
            }

            a,
            strong {
                @include spacing($spacing-name: 'xsmall', $padding-sides: ('all'));
                @include font-size('small-copy');
                display: block;
            }
        }
    }
}
