@mixin styleguide-buttons() {
    @each $size, $config in $button-sizes {
        .primary-#{$size} {
            @include button('primary', $size);
        }
    }

    @each $size, $config in $button-sizes {
        .secondary-#{$size} {
            @include button('secondary', $size);
        }
    }

    @each $size, $config in $button-sizes {
        .tertiary-#{$size} {
            @include button('tertiary', $size);
        }
    }

    @each $size, $config in $button-sizes {
        .icon-button-left-#{$size} {
            @include button-with-icon($icon-lock, 'primary', $size) {
                vertical-align: 0;
            }
        }

        .icon-button-right-#{$size} {
            @include button-with-icon($icon-lock, 'primary', $size, false) {
                vertical-align: 0;
            }
        }
    }

    .default-button-reset {
        @include reset-default-button-styles();
    }

    .link-with-icon-1 {
        @include link-with-icon($icon-warning);
    }

    .link-with-icon-2 {
        @include link-with-icon($icon-alert);
    }
}
