@mixin apply-brands-list-styles() {
    .brands-listing {
        @include grid-row($vertical-gutters: true);
        align-self: start;
        justify-content: flex-start;
    }

    .brand-item-wrapper {
        @include grid-column($brand-grid-item-flex-size-map, 0, 1);
        @include apply-flex-direction-column-ie-fix();
        display: flex;
        flex-direction: column;
    }

    .brand-item-info {
        @include spacing('gutter', $padding-sides: ('all'), $max-size: 'small');
        @include border();
        @include apply-flex-direction-column-ie-fix();
        display: flex;
        background: get-color('neutral-white');
        flex: 1 0 auto;
        position: relative;
        flex-direction: column;

        &:hover,
        &:focus {
            .brand-item-image-wrapper {
                @include border($color: 'primary', $sides: ('bottom'));
            }

            .brand-item-name {
                color: get-color('primary');
            }
        }
    }

    .brand-item-image-wrapper {
        @include spacing('gutter', $padding-sides:('bottom'), $margin-sides: ('bottom'), $max-size: 'small');
        @include border($sides: ('bottom'));
        display: inline-block;
        flex: 0 0 auto;
        width: 100%;
        text-align: center;
    }

    .brand-item-image {
        width: 100%;
        height: auto;
    }

    .brand-item-details {
        display: flex;
        position: relative;
        flex: 1 0 auto;
        align-items: center;
        text-align: center;
        word-break: break-word;

        .brand-item-name {
            @include product-name('product-name-small');
            flex: 1 0 auto;
            margin-bottom: 0;
            font-weight: get-font-weight('semi-bold');
        }
    }
}
