@mixin featured-list(
    $layout: $featured-items-layout,
    $spacing: $featured-items-spacing
) {
    @include grid-row($column-gutters: $spacing);
    justify-content: space-between;

    .item-wrapper {
        @include grid-column($responsive-sizes: $layout);
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }
}

@mixin featured-list-apply(
    $spacing: 'medium',
    $size-map: $featured-items-size-map
) {
    .shop-by-style-wrapper {
        @include featured-list();

        .item-wrapper {
            @include spacing($spacing, $margin-sides: ('bottom'));

            &:hover,
            &:focus {
                .item-name {
                    @include heading-offset-underline();
                }
            }
        }

        .item-image {
            @include border();
            @include property('width', $size-map);
            @include property('height', $size-map);
            flex: 0 0 auto;
            border-radius: 50%;
        }

        .item-name {
            @include heading-styles-h5();
            @include spacing($spacing, $margin-sides: ('top'), $ratio: 0.5);
            text-align: center;
        }
    }
}

@mixin category-image-list-apply(
    $spacing: 'small',
    $size-map: $category-image-list-layout
) {
    .row {
        @include grid-row();

        .item-wrapper {
            @include grid-column($size-map, $flex-grow: 0);
            @include spacing($spacing, $margin-sides: ('bottom'));

            .item-image {
                width: 100%;
            }
        }
    }
}
