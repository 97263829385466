@mixin icon-rating-form-field(
    $font-size: $rating-font-size,
    $hover-color: $rating-hover-color
) {
    @include font-size($font-size);
    position: relative;

    input[type='radio'] {
        @include visually-hidden();

        &:checked {
            ~ * {
                color: get-color($hover-color);
            }

            ~ .rating-label .rating-icon,
            .rating-icon {
                &::before {
                    content: $rating-icon;
                }
            }
        }

        &:focus {
            ~ .focus-indicator {
                position: absolute;
                bottom: rem-calc(-5);
                left: rem-calc(-5);
                box-shadow: 0 0 0 rem-calc(1) get-color('secondary-light');
                width: calc(100% + #{rem-calc(10)});
                height: calc(100% + #{rem-calc(30)});
            }
        }

        &:hover {
            ~ .focus-indicator {
                display: none;
            }
        }
    }
}

@mixin icon-rating(
    $icon: $rating-icon,
    $font-family: $rating-icon-font-family,
    $hover-color: $rating-hover-color,
    $background-rating-color: $rating-background-rating-color,
    $disable-cursor: true
) {
    margin: 0;
    border: 0;
    background-color: get-color('transparent');
    padding: 0;
    overflow: hidden;
    font-family: $font-family;

    @if $icon == $rating-icon-outline {
        color: get-color($rating-hover-color);
    } @else {
        color: get-color($background-rating-color);
    }

    .rating-result {
        &::before {
            content: $icon + $icon + $icon + $icon + $icon;
        }

        &.filled {
            color: get-color($hover-color);

            &::before {
                content: $icon + $icon + $icon + $icon + $icon;
            }
        }
    }

    .rating-icon {
        @include icon($icon: $icon);
    }

    .outline {
        color: get-color('transparent');
    }
}

@mixin icon-rating-hover(
    $color: $rating-hover-color
) {
    cursor: pointer;

    &:hover,
    &:focus {
        color: get-color($color);

        ~ * {
            color: get-color($color);
        }

        ~ .rating-label .rating-icon,
        .rating-icon {
            &::before {
                content: $rating-icon;
            }
        }
    }
}

@mixin short-review-summary-rating(
    $icon: $rating-icon,
    $filled-color: $rating-hover-color,
    $unfilled-color: $rating-background-rating-color
) {
    .rating-summary {
        display: inline-block;
        position: relative;

        .rating-result {
            display: inline-flex;

            &.filled {
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;

                .star {
                    @include icon($icon: $icon) {
                        color: get-color($filled-color);
                    }
                }
            }

            &.unfilled {
                .star {
                    @include icon($icon: $icon) {
                        color: get-color($unfilled-color);
                    }
                }
            }
        }
    }
}
