@mixin apply-customer-account-entry-page-styles() {
    @include apply-customer-account-entry-layout();

    .customer-account-create,
    .customer-account-login {
        .block-content {
            @include grid-column(
                $responsive-sizes: (
                    'small': column-width-percent(12),
                    'medium': column-width-percent(10)
                )
            );
        }
    }
}
