@mixin breadcrumb() {
    @include font-size('small-copy');
    display: inline-block;

    &::after {
        content: '/';
        vertical-align: bottom;
        margin: 0 rem-calc(4);
    }

    &:last-child {
        &::after {
            content: normal;
        }
    }

    strong {
        color: get-color('primary');
        font-weight: get-font-weight('normal');
    }
}

@mixin apply-breadcrumb-styles() {
    .breadcrumbs {
        .items {
            @include unstyled-list();
        }

        .item {
            @include breadcrumb();
        }
    }
}
