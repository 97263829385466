@mixin product-item-details() {
    display: flex;
    position: relative;
    flex: 1 0 auto;
    flex-direction: column;
    text-align: center;
    word-break: break-word;

    .product-item-name {
        @include product-name($product-name-font-size);
        flex: 1 0 auto;
    }

    .price-box {
        flex: 0 0 auto;

        .price-box .price,
        > .price-container .price,
        .normal-price {
            @include price('price-medium');
        }

        .special-price {
            @include special-price('price-medium');
        }

        .old-price,
        .map-old-price {
            &:not(:first-child) {
                @include old-price();
            }

            &:first-child {
                @include price('price-medium');
                text-decoration: line-through;

                &.with-msrp {
                    text-decoration: none;
                }
            }
        }

        .map-price {
            @include old-price($font-size: 'price-small', $text-decoration: none);
            display: block;
        }

        .msrp-tooltip-button {
            @include custom-tooltip-button-styles($apply-button-position-styles: false);
        }
    }

    .product-card-text {
        @include font-size('small-element');
        margin-top: rem-calc(10);
        color: get-color('accent-3');
        font-weight: get-font-weight('bold');
    }

    .action {
        @include product-grid-card-action();
    }
}
