@mixin styleguide-slider-apply() {
    .styleguide-slider-example-1 {
        @include apply-slider-styles();
        @include property('padding-bottom', $slider-dot-bottom, 1.5);
        @include property('padding-left', $slider-arrow-size);
        @include property('padding-right', $slider-arrow-size);
    }

    .styleguide-slider-example-2 {
        @include apply-slider-styles();
        @include apply-image-slider-base-styles();
    }
}
