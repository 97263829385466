@mixin apply-global-vertical-page-spacing() {
    .page-main-content {
        .content-main {
            @include apply-page-content-padding();
        }
    }

    .account {
        .page-main-content {
            .content-main {
                @include breakpoint(get-previous-breakpoint($customer-account-navigation-breakpoint) down) {
                    @include reset-page-content-padding($sides: ('top'));
                }
            }
        }
    }

    .cms-index-index {
        .page-main-content {
            .content-main {
                @include reset-page-content-padding($sides: ('top'));
            }
        }
    }

    .northern-checkout {
        .page-main-content {
            .content-main {
                @include breakpoint(get-previous-breakpoint($checkout-sidebar-navigation-breakpoint) down) {
                    @include reset-page-content-padding($sides: ('top'));
                }
            }
        }
    }

    .page-products {
        .page-main-content {
            .content-main {
                @include breakpoint(get-previous-breakpoint($layered-navigation-mobile-desktop-breakpoint) down) {
                    @include reset-page-content-padding($sides: ('top'));
                }
            }
        }
    }
}
