@mixin apply-homepage-styles() {
    @include apply-homepage-hero-slider-styles();

    .cms-index-index {
        @include featured-list-apply();

        .section-heading {
            @include spacing('large', $margin-sides: ('bottom'));
            text-align: center;

            .title {
                @include heading-offset-underline();
            }
        }

        .shop-by-category-wrapper {
            @include grid-row();
            justify-content: center;

            .shop-by-category {
                @include grid-column($responsive-sizes: $category-image-section-layout);
                @include category-image-list-apply();
            }
        }
    }

    .home-hero-slider,
    .most-popular-wrapper,
    .buyers-guide-section,
    .limited-time-wrapper,
    .shop-by-category-wrapper {
        @include spacing($homepage-content-spacing, $margin-sides: ('bottom'));
    }

    .most-popular-wrapper,
    .limited-time-wrapper {
        .products-grid {
            @include apply-product-slider-styles();
        }
    }

    .most-popular-wrapper {
        @include inline-banners-apply();
    }

    .limited-time-wrapper {
        @include inline-banners-apply($banner-order: $inline-banner-right-order);
    }
}

@mixin apply-homepage-hero-slider-styles() {
    .home-hero-slider {
        @include apply-hero-slider-styles();
    }
}
