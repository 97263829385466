@mixin apply-print-view-specific-styles() {
    .sales-order-print {
        .page-title-wrapper {
            margin-bottom: rem-calc(20);
        }

        .product-item-image {
            width: rem-calc(150);
        }
    }
}
