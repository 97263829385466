@mixin apply-body-scroll-styles() {
    .disable-body-scroll {
        overflow: hidden;
    }
}

@mixin custom-scroll-bar(
    $overflow: $scroll-bar-default-overflow,
    $side: $scroll-bar-default-side,
    $thickness: $scroll-bar-thumb-thickness,
    $color: $scroll-bar-thumb-color,
    $background-color: $scroll-bar-background-color
) {
    @if $side == 'both' {
        overflow: unquote($overflow);
    } @else {
        overflow-#{$side}: unquote($overflow);
    }

    &::-webkit-scrollbar {
        background-color: get-color($background-color);
        width: $thickness;
        height: $thickness;
    }

    &::-webkit-scrollbar-thumb {
        background: get-color($color);
    }
}

@mixin hide-custom-scroll-bar() {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        display: none;
    }
}
