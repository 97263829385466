@mixin short-review-summary-action() {
    @include font-size('small-element');
    color: get-color('neutral-dark');
}

@mixin product-review-summary() {
    .product-reviews-summary {
        @include short-review-summary-rating();
    }

    .reviews-actions {
        display: inline-block;

        .action {
            @include short-review-summary-action();
        }
    }
}
