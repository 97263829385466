@mixin apply-customer-account-sidebar() {
    .account-nav {
        @include theme-box-container();
        @include spacing($spacing-name: 'small', $margin-sides: ('bottom'));

        .account-nav-title {
            @include theme-box-header($background-color: $customer-account-navigation-sidebar-background-color);
            @include spacing($spacing-name: 'xsmall', $padding-sides: ('bottom'));
            @include heading-styles-h3();
        }

        .account-nav-content {
            @include theme-box-body($background-color: $customer-account-navigation-sidebar-background-color);

            @include breakpoint($customer-account-navigation-breakpoint) {
                padding-top: 0;
            }
        }

        .nav.items {
            @include unstyled-list();

            .item {
                @include font-size('customer-account-navigation-link');

                &:not(:last-of-type) {
                    @include spacing($spacing-name: 'xsmall', $margin-sides: ('bottom'));
                }

                &.current {
                    color: get-color('secondary');

                    strong {
                        font-weight: get-font-weight('normal');
                    }
                }
            }
        }

        @include breakpoint(get-previous-breakpoint($customer-account-navigation-breakpoint) down) {
            @include spacing($spacing-name: 'gutter', $margin-sides: ('left', 'right'), $ratio: -1);

            .account-nav-title {
                @include border($width: rem-calc(2), $sides: ('bottom'), $additive: false);
                @include spacing($spacing-name: $customer-account-navigation-mobile-title-padding, $padding-sides: ('all'));
                @include font-size('h4');
                @include icon($icon: $icon-plus, $use-before: false);
                position: relative;
                background-color: get-color($customer-account-navigation-mobile-background-color);

                &::after {
                    position: absolute;
                    $spacing: get-spacing($customer-account-navigation-mobile-title-padding);

                    @each $breakpoint, $values in $spacing {
                        @include breakpoint($breakpoint) {
                            top: get-side($values, 'top');
                            right: get-side($values, 'right');
                        }
                    }
                }

                &.active {
                    &::after {
                        content: $icon-minus;
                    }
                }
            }

            .account-nav-content {
                @include border($width: rem-calc(2), $sides: ('bottom'), $additive: false);
                display: none;

                &.active {
                    display: block
                }
            }
        }
    }
}
