@mixin apply-customer-account-page-titles() {
    .page-title-wrapper {
        @include theme-page-title();
    }
}

@mixin apply-customer-account-box-titles() {
    .box {
        .box-title {
            strong {
                @include heading-styles-h3();
            }
        }
    }
}

@mixin apply-customer-account-section-titles() {
    .block-title,
    .legend {
        @include spacing($spacing-name: 'small', $margin-sides: ('bottom'));
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        > span,
        > strong {
            @include heading-styles-h2();
            @include spacing($spacing-name: 'xsmall', $padding-sides: ('top', 'bottom'), $ratio: 0.5);
        }

        .action {
            @include button($size: 'small');
        }
    }
}
