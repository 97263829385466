@mixin apply-contact-form-legend-styles() {
    .contact-form-legend {
        margin-bottom: map-safe-get(get-spacing('gutter'), 'large');
    }

    .contact-form-legend-title {
        @include theme-typography-heading('h3');
        margin-bottom: map-safe-get(get-spacing('gutter'), 'small');
    }
}
