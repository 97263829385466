@mixin apply-checkout-section-styles() {
    .checkout-step-container {
        @include box-container(
            $margin-bottom: get-spacing($checkout-section-vertical-spacing),
            $border: $checkout-section-include-borders
        );
    }

    .checkout-step-section {
        $padding-ratio: $checkout-section-padding-ratio-default;

        @if $checkout-section-include-padding == false {
            $padding-ratio: 0;
        }

        @include box-section($background-color: $checkout-section-background-color, $text-color: null, $padding-ratio: $padding-ratio);
    }

    .checkout-option-set {
        @include box-container($border: false, $margin-bottom: null);
        @include border($sides: ('top'));
        margin-bottom: 0;

        .checkout-option-set-header {
            @include border($sides: ('right', 'left'));
            @include spacing($spacing-name: 'xsmall', $padding-sides: ('all'));
        }

        .checkout-option-set-body {
            @include box-section($border-sides: ('left', 'right'), $background-color: $checkout-option-set-body-background-color, $text-color: null);
            @include border($sides: ('top'), $additive: true);
        }

        &:last-of-type {
            @include border($sides: ('bottom'), $additive: true);
        }

        .checkout-option-set-label {
            display: flex;
            align-items: center;
        }
    }
}

@mixin apply-northern-checkout-form-styles() {
    .checkout-shipping-address-form,
    .checkout-billing-address-form {
        @include apply-address-form-layout($shorten: false);
    }

    .tooltip-button {
        @include custom-tooltip-button-styles()
    }

    .custom-tooltip {
        @include custom-tooltip-styles();
    }
}

@mixin apply-northern-checkout-steps-styles() {
    @include apply-checkout-step-customer-info-styles();
    @include apply-checkout-step-shipping-method-styles();
    @include apply-checkout-step-payment-method-styles();

    .checkout-step-body {
        @include apply-reduced-width-form-styles();
    }
}

@mixin apply-northern-checkout-actions-styles() {
    .checkout-actions {
        @include spacing($spacing-name: $checkout-section-vertical-spacing, $padding-sides: ('top'), $ratio: 1.5);
        @include border($sides: ('top'));

        .checkout-action-toolbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

        .back-tocart-button,
        .prev-step-button,
        .next-step-button,
        .complete-checkout-button {
            @include spacing($spacing-name: 'gutter', $margin-sides: ('bottom'));
            flex-shrink: 0;

            @include breakpoint(small only) {
                flex-grow: 1;
            }
        }

        .back-tocart-button {
            @include button-with-icon($icon: $checkout-actions-back-cart-button-icon, $type: $checkout-actions-back-cart-button-type, $size: $checkout-actions-button-size);
        }

        .prev-step-button {
            @include button-with-icon($icon: $checkout-actions-back-button-icon, $type: $checkout-actions-back-button-type, $size: $checkout-actions-button-size);
        }

        .next-step-button {
            @include button-with-icon($icon: $checkout-actions-next-button-icon, $type: $checkout-actions-next-button-type, $size: $checkout-actions-button-size, $use-before: false);
        }

        .complete-checkout-button {
            @include button-with-icon($icon: $checkout-actions-complete-button-icon, $type: $checkout-actions-complete-button-type, $size: $checkout-actions-button-size, $use-before: false);
        }
    }
}

@mixin apply-northern-checkout-layout-styles() {
    .checkout-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .checkout-sidebar-container {
        @include spacing($spacing-name: 'small', $margin-sides: ('bottom'));
        order: -1;

        @include breakpoint('medium' down) {
            @include spacing($spacing-name: 'gutter', $margin-sides: ('left', 'right'), $ratio: -1);

            @each $breakpoint, $value in get-spacing('gutter') {
                @include breakpoint($breakpoint) {
                    width: calc(100% + (#{$value * 2}));
                }
            }
        }
    }

    @include breakpoint('large') {
        .checkout-wrapper {
            @include grid-row();
        }

        .checkout-steps-container {
            @include content-grid-main-content-panel-styles();
        }

        .checkout-sidebar-container {
            @include content-grid-right-content-panel-styles();
            order: initial;
        }
    }
}

@mixin apply-northern-checkout-onepage-styles() {
    .northern-checkout {
        @include apply-northern-checkout-layout-styles();

        .page-title-wrapper {
            @include visually-hidden();
        }

        @include apply-checkout-section-styles();

        .legend {
            .subheading {
                @include typography-heading($checkout-section-legend-subheading-type);
                @include typography-margin($checkout-section-legend-subheading-type)
            }
        }

        @include apply-northern-checkout-form-styles();
        @include apply-northern-checkout-steps-styles();
        @include apply-northern-checkout-actions-styles();

        .checkout-content-after {
            .checkout-value-adds {
                @include apply-checkout-value-adds-list();
                @include spacing($spacing-name: 'xxsmall', $margin-sides: ('top'));
                @include spacing($spacing-name: 'xxsmall', $padding-sides: ('top'));
                @include border($sides:('top'));

                @include breakpoint('large') {
                    display: none;
                }
            }
        }
    }
}
