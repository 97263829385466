@mixin global-header-apply() {
    @include global-header-promo-apply();
    @include global-header-main-apply();

    .page-header {
        position: sticky;
        top: 0;
        z-index: get-z-index('header');
    }
}

@mixin global-header-main-apply() {
    @include global-header-main-left-apply();
    @include global-header-main-right-apply();
    @include search-autocomplete();

    .page-header-main-container {
        @include border(
            $width: (
                'small': rem-calc(2)
            ),
            $color: $page-header-border-color,
            $sides: ('bottom')
        );
        position: relative;
        z-index: get-z-index('header-main');
        background-color: get-color('neutral-white');
    }

    .page-header-main {
        @include property('height', $page-header-height);
    }

    .page-header-main-content {
        @include padded-max-width-container();
        display: flex;
        align-items: center;
        height: 100%;
    }

    .header-logo-container {
        $page-header-logo-width: (
            'small': rem-calc(130),
            'large': rem-calc(160),
            $page-header-desktop-breakpoint: rem-calc(190),
        );
        @include property('width', $page-header-logo-width);
        flex: 0 0 auto;

        .logo {
            display: block;
            width: 100%;

            img {
                width: inherit;
            }
        }

        .action {
            &.nav-toggle {
                display: none;
            }
        }
    }
}

@mixin global-header-promo-apply() {
    .page-header-promo {
        position: relative;
        background-color: get-color('primary');
        padding: rem-calc(8 0);
        color: get-color('neutral-white');

        @include breakpoint($page-header-promo-desktop-breakpoint) {
            padding: rem-calc(15 0);
        }
    }

    .page-header-promo-content {
        @include padded-max-width-container();
    }

    .page-header-promo-text {
        @include small-copy();
        padding: rem-calc(0 15);
        text-align: center;
        color: inherit;
        font-weight: get-font-weight('bold');

        @include breakpoint($page-header-promo-desktop-breakpoint) {
            padding: 0;
        }

        * {
            color: inherit;
        }

        a {
            text-decoration: underline;
        }
    }

    .page-header-promo-button-close {
        @include reset-default-button-styles();
        @include property('height', $page-header-promo-close-button-height);
        position: absolute;
        top: 0;
        right: rem-calc(7);
        width: rem-calc(20);
        text-align: center;
        color: inherit;

        @include breakpoint($page-header-promo-desktop-breakpoint) {
            right: rem-calc(10);
        }
    }

    .page-header-promo-close-icon {
        @include icon($icon: $icon-close) {
            line-height: 1;

            @include breakpoint($page-header-promo-desktop-breakpoint) {
                font-size: rem-calc(20);
            }
        }
    }
}

@mixin global-header-main-left-apply() {
    @include mobile-menu();

    .page-header-main-left {
        @include global-header-search-desktop();
        @include header-main-column-fill-empty-space();
        align-items: center;
    }
}

@mixin mobile-menu() {
    .mobile-menu-open-button {
        @include header-main-button();
        @include icon($icon: $icon-menu) {
            font-size: rem-calc(24);

            @include breakpoint($page-header-tablet-breakpoint) {
                font-size: rem-calc(26);
            }
        }
        margin-left: rem-calc(-8); // visually match distance to edge of screen of button on opposite side

        @include breakpoint($page-header-desktop-breakpoint) {
            display: none;
        }
    }

    .mobile-menu {
        @include menu-container();
    }

    .mobile-menu-header {
        @include menu-header();
    }

    .mobile-menu-header-title {
        @include menu-header-title()
    }

    .mobile-menu-close-button {
        @include menu-header-close-button();
    }

    .mobile-menu-content {
        @include menu-content();
    }

    .mobile-menu-backdrop {
        @include pullout-menu-backdrop();
    }

    .mobile-menu-accordion {
        @include theme-accordion-container();
        @include apply-megamenu-special-link-styles();
    }

    .mobile-menu-accordion-item {
        @include menu-accordion-item();

        &:not(.mobile-menu-accordion-item-has-children) {
            .mobile-menu-accordion-title {
                &::before {
                    display: none;
                }
            }
        }
    }

    .mobile-menu-accordion-title {
        @include menu-accordion-item-title();

        &::before {
            @include property('right', $menu-item-horizontal-spacing);
        }

        &[aria-expanded='true'] {
            &::before {
                @include property('right', $menu-item-horizontal-spacing);
            }
        }
    }

    .mobile-menu-accordion-body {
        @include menu-accordion-item-body();

        ul {
            &.megamenu-additional-links {
                @include apply-base-dropdown-list-styles();
                @include apply-megamenu-dropdown-list-styles($negate-parent-menu-padding: false);
            }
        }
    }

    .mobile-menu-item-list {
        @include unstyled-list();
    }

    .mobile-menu-item-link {
        @include spacing(
            $spacing-name: 'small',
            $padding-sides: ('top', 'bottom'),
            $max-size: 'small',
            $ratio: 0.5
        );
        @include font-size('small-copy');
        display: block;
        line-height: 1;

        .shop-all-category-name {
            @include visually-hidden();
        }
    }
}

@mixin global-header-main-right-apply() {
    .page-header-main-right {
        @include global-header-search-mobile();
        @include header-main-column-fill-empty-space();
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .header-secondary-nav {
        display: none;

        @include breakpoint($page-header-desktop-breakpoint) {
            display: block;
        }
    }

    .header-secondary-nav-list {
        @include unstyled-list();
        display: flex;
    }

    .header-secondary-nav-item {
        margin: rem-calc(0 20);
    }

    .header-secondary-nav-link {
        @include font-size('small-copy');
    }
}

@mixin global-header-search-mobile() {
    .header-search-container {
        @include breakpoint($page-header-desktop-breakpoint) {
            display: none;
        }

        &:not(.active) {
            .header-search-content-container {
                display: none;
            }
        }
    }

    .header-search-content-container {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: get-color('neutral-light');
        padding: rem-calc(20 0);
        width: 100%;
    }

    .header-search-content {
        @include padded-max-width-container();
        @include header-main-search-button-apply();

        .field {
            flex: 1 1 auto;
            margin-bottom: 0;
        }

        .input-text {
            @include input-box-shadow-border($input-border-width, darken(get-color($input-border-color), 7%));
            padding-right: $page-header-search-button-width;
        }
    }

    .header-search-content-inner {
        position: relative;
    }

    .header-form-search {
        display: flex;
    }

    .header-search-mobile-close {
        @include reset-default-button-styles();
        @include center-icon-in-span($icon-close, '.header-search-close-icon') {
            font-size: rem-calc(12);
        }
        position: absolute;
        top: 50%;
        right: rem-calc(10);
        transform: translateY(-50%);
        z-index: get-z-index('above-sibling');
        border-radius: 50%;
        background-color: get-color('neutral-light');
        width: rem-calc(25);
        height: rem-calc(25);
        color: get-color('primary');
    }

    .header-search-open-button {
        @include header-main-button();
        @include icon($icon: $icon-search) {
            font-size: rem-calc(22);

            @include breakpoint($page-header-tablet-breakpoint) {
                font-size: rem-calc(24);
            }
        }
    }
}

@mixin global-header-search-desktop() {
    .header-search-container {
        display: none;

        @include breakpoint($page-header-desktop-breakpoint) {
            display: flex;
        }
    }

    .header-form-search {
        @include header-main-search-button-apply($side: 'right');
        display: flex;
        position: relative;
        width: rem-calc(275);

        .field {
            flex: 1 1 auto;
            margin-bottom: 0;
        }
    }
}

@mixin header-main-column-fill-empty-space() {
    flex: 1 1 auto;
    width: 50%;
}

@mixin header-main-button() {
    @include reset-default-button-styles($remove-outline: true);
    @include property('height', $page-header-button-height);
    @include property('width', $page-header-button-width);
    position: relative;
    margin: rem-calc(0 5);
    text-align: center;
    color: get-color('primary');

    &:hover,
    &:focus {
        color: get-color('accent-3');
    }
}

@mixin header-main-search-button-apply($side: 'left') {
    .form-label-text {
        @if $side == 'left' {
            left: 0;
        }
        padding-#{$side}: $page-header-search-button-width;
    }

    .search {
        @include reset-default-button-styles();
        @include center-icon-in-span($icon-search, '.search-icon') {
            font-size: rem-calc(22);
        }
        position: absolute;
        top: 0;
        #{$side}: 0;
        z-index: get-z-index('above-sibling');
        width: $page-header-search-button-width;
        height: 100%;
        text-align: center;
        color: get-color('primary');

        &:hover,
        &:focus {
            color: get-color('accent-3');
        }
    }

    .input-text {
        display: block; // fix IE bug: unwanted inline spacing
        padding-#{$side}: calc(#{$page-header-search-button-width});
    }
}
