@mixin theme-box-container() {
    @include box-container($border: false);
    @include border($sides: ('top', 'bottom'));
}

@mixin theme-box-body(
    $background-color: $box-body-background-color,
    $color: $box-body-text-color
) {
    @include spacing($spacing-name: 'box', $padding-sides: ('all'));
    @include border($sides: ('left', 'right'));
    background-color: get-color($background-color);
    color: get-color($color);
}

@mixin theme-box-header(
    $background-color: $box-header-background-color,
    $color: $box-header-text-color
) {
    @include border($sides: ('right', 'left'));
    @include spacing($spacing-name: 'box-header', $padding-sides: ('all'));
    background-color: get-color($background-color);
    color: get-color($color);

    + [class*='box-body'] {
        padding-top: 0;
    }
}

@mixin theme-box-footer(
    $background-color: $box-footer-background-color,
    $color: $box-footer-text-color,
    $top-bottom-padding-ratio: 0.5
) {
    @include border($sides: ('right', 'top', 'left'));
    @include spacing($spacing-name: 'box', $padding-sides: ('left', 'right'));

    @each $breakpoint, $padding in $box-padding {
        @include breakpoint($breakpoint) {
            padding-top: get-side($padding, 'top') * $top-bottom-padding-ratio;
            padding-bottom: get-side($padding, 'bottom') * $top-bottom-padding-ratio;
        }
    }

    background-color: get-color($background-color);
    color: get-color($color);
}
