$checkout-value-add-help-icon: $icon-phone;
$checkout-value-add-secure-icon: $icon-lock;
$checkout-value-add-shipping-icon: $icon-truck;
$checkout-value-add-returns-icon: $icon-return;
$checkout-value-add-prices-icon: $icon-star-off;

@mixin checkout-value-add-icon($icon) {
    @if ($icon) {
        .checkout-sidebar-value-add-icon {
            @include icon($icon: $icon) {
                margin-right: rem-calc(5);
                font-size: rem-calc(26);
            }
        }
    }
}

@mixin apply-checkout-value-adds-list() {
    @include apply-payment-method-icons-styles();

    ul {
        @include unstyled-list();
        @include font-size('checkout-value-adds');
        @include line-height('checkout-value-adds');
        color: get-color($checkout-value-adds-list-color);

        .checkout-sidebar-value-add-text {
            white-space: nowrap;
        }

        .need-help {
            @include checkout-value-add-icon($checkout-value-add-help-icon);
        }

        .secure-payment {
            @include checkout-value-add-icon($checkout-value-add-secure-icon);
        }

        .free-shipping {
            @include checkout-value-add-icon($checkout-value-add-shipping-icon);
        }

        .easy-returns {
            @include checkout-value-add-icon($checkout-value-add-returns-icon);
        }

        .usd-prices {
            @include checkout-value-add-icon($checkout-value-add-prices-icon);
        }
    }
}
