@mixin apply-northern-checkout-header-styles() {
    .checkout-page-header {
        .checkout-header-main {
            @include border($color: $checkout-header-main-border-color, $sides: 'bottom');
            @include spacing($spacing-name: 'xsmall', $padding-sides: ('top', 'bottom'));
            @include set-background-color($checkout-header-main-background-color, $checkout-header-main-foreground-colors);

            .checkout-header-main-inner {
                @include padded-max-width-container();
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .checkout-header-logo-container {
            @include property('width', $checkout-header-logo-width);
            flex: 0 0 auto;

            .logo {
                display: block;
                width: 100%;

                img {
                    width: inherit;
                }
            }
        }

        .checkout-header-bottom {
            @include border($color: $checkout-header-bottom-border-color, $sides: 'bottom');
            background-color: get-color($checkout-header-bottom-background-color);

            .checkout-header-bottom-inner {
                @include padded-max-width-container();
            }
        }

        .checkout-header-contact {
            .contact-icon {
                @include icon($icon: $checkout-header-contact-icon) {
                    @include font-size($checkout-header-main-icon-font-size);
                }
            }
        }

        .checkout-header-trust-elements {
            .secure-icon {
                @include icon($icon: $checkout-header-trust-element-secure-icon) {
                    @include font-size($checkout-header-main-icon-font-size);
                }
            }
        }

        @include breakpoint(small only) { //@TODO barstools only, do not transfer to compass. remove comment once this has been ported
            .checkout-header-trust-elements {
                display: none;
            }

            .checkout-header-contact {
                order: 2;
            }
        }
    }
}
