@mixin styleguide-tooltip-styles() {
    @at-root {
        .styleguide-tooltip {
            @include custom-tooltip-styles(
                $side: null,
                $align: null
            );
        }
    }

    .styleguide-tooltips {
        @include spacing('gutter', $margin-sides: ('bottom'));

        h6 {
            @include spacing('gutter', $margin-sides: ('top'));
            @include spacing('gutter', $margin-sides: ('bottom'), $ratio: 0.5);
        }
    }

    .styleguide-tooltip-button {
        @include custom-tooltip-button-styles($apply-button-position-styles: false);
    }

    .styleguide-input-tooltip {
        @include custom-tooltip-button-styles();
    }

    .styleguide-tooltip-text {
        @include reset-default-button-styles();
        @include font-size('tiny-copy');
        display: inline-block;
        color: get-color('primary');
        font-weight: get-font-weight('semi-bold');
    }
}
