@mixin apply-global-message-styles() {
    .message-success {
        @include global-message(
            $text-color: 'neutral-white',
            $icon-color: 'neutral-white',
            $background-color: 'success',
            $icon: $icon-success
        );
    }

    .message-error {
        @include global-message(
            $text-color: 'neutral-white',
            $icon-color: 'neutral-white',
            $background-color: 'error-dark',
            $icon: $icon-warning
        );
    }

    .message-notice {
        @include global-message(
            $text-color: 'neutral-white',
            $icon-color: 'neutral-white',
            $background-color: 'information',
            $icon: $icon-info
        );
    }

    .message-warning {
        @include global-message(
            $text-color: 'neutral-white',
            $icon-color: 'neutral-white',
            $background-color: 'warning',
            $icon: $icon-alert
        );
    }
};

@mixin global-message(
    $text-color,
    $icon-color,
    $background-color,
    $icon,
    $padding: $message-padding,
    $icon-spacing: $message-icon-spacing
) {
    margin-bottom: $message-spacing;
    background-color: get-color($background-color);

    &:first-of-type {
        margin-top: $message-spacing;
    }

    &:last-of-type {
        margin-bottom: $message-spacing;
    }

    .message-inner {
        @include icon($icon: $icon) {
            margin-right: $icon-spacing;
            line-height: 1;
            color: get-color($icon-color);
            font-size: rem-calc(20);
        }
        display: flex;
        padding: $padding;
        color: get-color($text-color);
    }

    .message-text {
        @include font-size('small-copy');

        a {
            text-decoration: underline;
            color: inherit;
        }
    }
};
