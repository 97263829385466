@mixin apply-layered-navigation-styles() {
    @include apply-mobile-layered-navigation-styles();
    @include apply-desktop-layered-nav-styles();
}

@mixin apply-mobile-layered-navigation-styles() {
    .mobile-layered-nav-open-button {
        @include menu-accordion-item-title();
        @include font-size('small-copy');
        margin-left: -$layered-navigation-mobile-button-gutter-width;
        width: calc(100% + (#{$layered-navigation-mobile-button-gutter-width} * 2));
        background-color: get-color('neutral-lighter');
        font-weight: get-font-weight('semi-bold');

        @include breakpoint($layered-navigation-mobile-desktop-breakpoint) {
            display: none;
        }

        &:hover,
        &:focus {
            background-color: get-color('neutral-lighter');
        }

        &::before {
            @include property('right', $menu-item-horizontal-spacing);
        }

        &[aria-expanded='true'] {
            &::before {
                @include property('right', $menu-item-horizontal-spacing);
            }
        }
    }

    .layered-nav-mobile-backdrop {
        @include pullout-menu-backdrop();
    }

    .layered-nav-mobile-container {
        @include menu-container();
        @include apply-base-layered-nav-styles();

        @include breakpoint($layered-navigation-mobile-desktop-breakpoint) {
            display: none;
        }

        .mobile-layered-nav-header {
            @include menu-header();
        }

        .mobile-layered-nav-header-title {
            @include menu-header-title()
        }

        .mobile-layered-nav-close-button {
            @include menu-header-close-button();
        }

        .layered-nav-mobile-content {
            @include menu-content();
        }

        .layered-navigation-accordion-body {
            background: get-color('neutral-white');
        }

        .layered-navigation-link-list {
            .layered-navigation-links-view-more-toggle {
                button {
                    background: get-color('neutral-white');
                }
            }
        }
    }
}

@mixin apply-desktop-layered-nav-styles() {
    .layered-navigation-desktop-header-button {
        @include menu-accordion-item-title();
        @include font-size('small-copy');
        display: none;
        background: get-color('neutral-lighter');

        &:hover,
        &:focus {
            background: get-color('neutral-lighter');
        }

        &::before {
            @include property('right', $menu-item-horizontal-spacing);
        }

        &[aria-expanded='true'] {
            &::before {
                @include property('right', $menu-item-horizontal-spacing);
            }
        }

        @include breakpoint($layered-navigation-mobile-desktop-breakpoint) {
            display: inline-flex;
        }
    }

    .layered-nav-desktop-container {
        @include apply-base-layered-nav-styles();
        @include border();
        display: none;
        background: get-color($body-background-color);

        @include breakpoint($layered-navigation-mobile-desktop-breakpoint) {
            display: block;
        }

        .layered-navigation-accordion-container {
            @include border($sides: ('top'));
        }

        .layered-navigation-desktop-content {
            @include theme-accordion-item-body(
                $padding: (
                    'small': 0
                )
            );
        }

        .layered-navigation-accordion-item {
            margin-right: $menu-item-horizontal-spacing;
            margin-left: $menu-item-horizontal-spacing;

            &:first-child {
                border-top: 0;
            }
        }

        .layered-navigation-accordion-title {
            @include font-size('h3');
            background: get-color($body-background-color);
            padding-right: 0;
            padding-left: 0;

            &:hover,
            &:focus {
                background: get-color($body-background-color);
            }
        }

        .layered-navigation-accordion-body {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

@mixin apply-base-layered-nav-styles() {
    .layered-navigation-state-list {
        @include unstyled-list();
        background: get-color('neutral-lighter');
        padding: ($menu-item-horizontal-spacing / 2) $menu-item-horizontal-spacing;

        @include breakpoint($layered-navigation-mobile-desktop-breakpoint) {
            padding-top: 0;
        }

        a {
            @include icon($icon: $icon-warning) {
                margin-right: rem-calc(4);
                font-size: rem-calc(20);
                vertical-align: rem-calc(-3);
            }
            @include font-size('small-copy');
        }
    }

    .layered-navigation-accordion-item {
        @include menu-accordion-item();
    }

    .layered-navigation-accordion-title {
        @include menu-accordion-item-title();

        &::before {
            @include property('right', $menu-item-horizontal-spacing);
        }

        &[aria-expanded='true'] {
            &::before {
                @include property('right', $menu-item-horizontal-spacing);
            }
        }
    }

    .layered-navigation-accordion-body {
        @include menu-accordion-item-body();
    }

    .layered-navigation-link-list {
        @include unstyled-list();

        .layered-navigation-list-item {
            a {
                @include form-checkbox-base-styles();

                &:hover,
                &:focus {
                    @include form-checkbox-checked();
                    color: get-color('primary');
                }

                &.is-active {
                    @include form-checkbox-checked();
                }
            }

            &.hidden {
                &:not(.is-active) {
                    display: none;
                }
            }
        }

        .layered-navigation-links-view-more-toggle {
            padding-top: rem-calc(10);

            button {
                @include button('tertiary', 'tiny');
            }
        }
    }

    :last-child > & .layered-navigation-accordion-body {
        &:last-child {
            border-bottom: 0;
        }
    }
}
