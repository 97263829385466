@mixin apply-northern-sociallogin-northern-checkout-styles() {
    .northern-checkout {
        .social-login-container {
            @include social-login-button-styles($button-size: 'regular');
            @include apply-reduced-width-form-styles();
            @include spacing($spacing-name: 'small', $margin-sides: ('top'));
            @include spacing($spacing-name: 'small', $padding-sides: ('top'));
            @include border($sides: ('top'));
        }
    }
}
