@mixin cms-clearfix() {
    &::after {
        display: table;
        clear: both;
        content: '';
    }
}

@mixin apply-cms-typography-styles() {
    ul {
        @include unordered-list();
    }

    ol {
        @include ordered-list();
    }

    p {
        @include typography-paragraph();
    }

    blockquote {
        @include blockquote();
    }

    figcaption {
        @include typography-copy(
            $color: $cms-caption-font-color,
            $font-size: $cms-caption-font-size,
            $font-stack: 'secondary'
        );
        display: block;
        margin: rem-calc(0 0 10 0);
        background-color: get-color('neutral-lighter');
        padding: rem-calc(10);
    }

    .cms-small-copy {
        @include small-copy();
    }

    .cms-tiny-copy {
        @include tiny-copy();
    }
}

@mixin apply-cms-page-layout-styles() {
    body {
        &.cms-page-view {
            .page-title-wrapper {
                @include theme-page-title();
            }

            &.page-layout-empty-full-width {
                .cms-container {
                    @include padded-max-width-container();
                }
            }

            &:not(.page-layout-empty-full-width) {
                .content-main {
                    @include max-width-container-columns-width(
                        $column-count: (
                            'small': 12,
                            'xxlarge': 10
                        ),
                        $center-element: true
                    );
                }
            }
        }
    }
}

@mixin cms-tooltips() {
    @at-root {
        .cms-tooltip {
            @include custom-tooltip-styles(
                $side: null,
                $align: null
            );
        }
    }

    .cms-tooltip-button {
        &.tooltip-button {
            @include custom-tooltip-button-styles($apply-button-position-styles: false);
        }

        &.tooltip-text {
            @include reset-default-button-styles();
            @include font-size('tiny-copy');
            display: inline-block;
            color: get-color('primary');
            font-weight: get-font-weight('semi-bold');
        }
    }
}

@mixin apply-cms-content-layout-styles() {
    .cms-split-container {
        @include cms-clearfix();
    }

    .cms-split-container {
        @include typography-margin('p');

        p {
            &:last-child {
                margin: 0;
            }
        }
    }

    .cms-split-left,
    .cms-split-right {
        margin-bottom: $cms-split-section-spacing;

        img,
        figure {
            margin-bottom: 0;
        }
    }

    .cms-split-left {
        @include breakpoint('large') {
            float: left;
            padding-right: $cms-split-section-spacing;
            width: 50%;
        }
    }

    .cms-split-right {
        @include breakpoint('large') {
            float: right;
            padding-left: $cms-split-section-spacing;
            width: 50%;
        }
    }

    img {
        display: block;
    }

    figure {
        display: inline-block;
        margin-bottom: rem-calc(30);
        max-width: 100%;

        img {
            margin-bottom: 0;
        }
    }
}

@mixin cms-responsive-video() {
    position: relative;
    padding-top: $cms-responsive-video-padding;

    video,
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin cms-scroll-styles() {
    @include property('top', $fixed-header-scroll-offset);
    position: relative;
}

@mixin apply-cms-styles() {
    @include apply-cms-page-layout-styles();

    .apply-cms-styles {
        @include apply-cms-content-layout-styles();
        @include apply-cms-typography-styles();
        @include kindling-typography-margin();
        @include cms-tooltips();

        .cms-button-primary {
            @include primary-button();
        }

        .cms-button-secondary {
            @include secondary-button();
        }

        .cms-accordion {
            @include theme-accordion-container();

            li {
                @include theme-accordion-item();
            }

            button {
                @include theme-accordion-item-title();
            }

            div {
                @include theme-accordion-item-body();
            }
        }

        .cms-table-wrapper {
            @include apply-table-responsive-styles('table');
            @include box-container($border: false);
            margin: rem-calc(50 0);
            width: 100%;
        }

        .cms-specs-list-wrapper {
            @include apply-table-alternate-styles();
            @include box-container($border: false);
            margin: rem-calc(50 0);
            width: 100%;
        }

        .cms-responsive-video {
            @include cms-responsive-video();
        }

        .cms-scroll {
            @include cms-scroll-styles();
        }
    }
}
