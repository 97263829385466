@mixin empty-category-page-styles() {
    .main-content-panel {
        .message {
            @include spacing('gutter', $margin-sides: ('top'));
        }

        .search {
            .block {
                display: none;
            }
        }
    }
}

@mixin apply-category-page-styles() {
    @include apply-catalog-toolbar-styles();
    @include apply-category-description-styles();
    @include apply-category-page-banner-styles();

    .page-products {
        @include empty-category-page-styles();

        .page-title-wrapper {
            @include apply-breadcrumb-styles();
            @include spacing($spacing-name: 'small', $margin-sides: ('bottom'));
            @include spacing($spacing-name: 'xxsmall', $padding-sides: ('bottom'));
            @include property('margin-bottom', ('small': 0, 'large': 0));
        }

        .left-content-panel {
            @include spacing('gutter', $margin-sides: ('bottom'));

            @include breakpoint('large') {
                width: column-width-percent(3);
            }
        }
    }

    .catalog-category-view {
        .content-main {
            @include apply-flex-direction-column-ie-fix();
            display: flex;
            flex-direction: column;

            .messages {
                order: -1;
            }

            .category-view,
            .messages {
                min-height: unset;
            }

            .content-columns {
                order: 0;

                @include breakpoint('large') {
                    order: 1;
                }
            }

            .category-page-banners {
                order: 1;

                @include breakpoint('large') {
                    order: 0;
                }
            }
        }
    }
}
