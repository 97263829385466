@mixin menu-container() {
    @include pullout-menu-visibility-styles($side: 'left', $display: flex);
    @include pullout-menu-theme();
    position: fixed;
    top: 0;
    width: 100%;
    max-height: 100%;

    &.pullout-menu-is-open {
        flex-direction: column;
    }
}

@mixin menu-header() {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    background: get-color('primary');
    padding-left: get-side($menu-item-horizontal-spacing, 'left');
    width: 100%;
    height: $menu-header-height;
    color: get-color('neutral-white');
}

@mixin menu-header-title() {
    @include ellipsis();
    @include typography-heading(
        $heading-type: 'h3',
        $color: inherit
    );
    flex: 1 1 auto;
}

@mixin menu-header-close-button() {
    @include icon($icon: $icon-close) {
        font-size: rem-calc(24);
    }
    @include reset-default-button-styles();
    flex: 0 0 auto;
    padding: rem-calc(9);
    width: $menu-header-close-icon-width;
    text-align: center;
    color: get-color('neutral-white');
    font-weight: get-font-weight('normal');
}

@mixin menu-content() {
    @include custom-scroll-bar();
}

@mixin menu-accordion-item() {
    @include theme-accordion-item();

    &:last-child {
        border-bottom: 0;
    }
}

@mixin menu-accordion-item-title() {
    @include theme-accordion-item-title($background-color: 'neutral-white');
    @include property('padding-right', $menu-item-horizontal-spacing);
    @include property('padding-left', $menu-item-horizontal-spacing);
}

@mixin menu-accordion-item-body() {
    $padding: get-side(map-safe-get($accordion-padding, 'small'), 'bottom') $menu-item-horizontal-spacing;
    @include theme-accordion-item-body(
        $padding: (
            'small': $padding
        ),
        $border-width: $theme-accordion-border-width,
        $border-sides: ('top')
    );
    @include apply-my-account-dropdown-content-styles();
}
