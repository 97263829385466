@mixin apply-reduced-width-form-styles() {
    @include grid-column(
        $responsive-sizes: (
            'small': column-width-percent(12),
            'xlarge': column-width-percent(11)
        )
    );
}

@mixin apply-address-form-layout($shorten: true) {
    @if $shorten == true {
        @include apply-reduced-width-form-styles();
    }

    .fieldset {
        @include grid-row();
    }

    .field {
        @include grid-column(
            $responsive-sizes: (
                'small': column-width-percent(12),
                'large': column-width-percent(6)
            )
        );
    }

    .inline-fields {
        @include grid-row();
        flex-grow: 1;
        width: 100%;

        .field {
            @include grid-column(
                $responsive-sizes: (
                    'small': column-width-percent(12),
                    'large': column-width-percent(4)
                )
            );
        }
    }
}
