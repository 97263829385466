@mixin card-link-overlay() {
    position: relative;

    .product-item-link-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: get-z-index('above-sibling');
    }
}

@mixin product-grid-card-action() {
    position: relative;
    z-index: get-z-index('above-sibling');
}
