@mixin toolbar-select() {
    @include form-select();
    height: rem-calc(35);
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);

    &:not([multiple]) {
        height: rem-calc(35);
    }
}

@mixin apply-catalog-toolbar-styles() {
    .toolbar-products {
        .toolbar-top {
            @include border($sides: ('bottom'));
            @include spacing($spacing-name: 'xxsmall', $padding-sides: ('bottom'));
            display: flex;
            flex-wrap: wrap;

            .toolbar-sorter {
                @include breakpoint('xlarge') {
                    display: none;
                }
            }
        }

        .toolbar-bottom {
            @include toolbar-layout();
            padding-top: rem-calc(5);

            .toolbar-sorter {
                display: none;

                @include breakpoint('xlarge') {
                    display: flex;
                }
            }
        }

        .toolbar-amount {
            @include toolbar-amount();
        }

        .toolbar-limiter {
            display: flex;
            padding-top: rem-calc(15);

            .limiter-label {
                @include toolbar-label();
                @include line-height('toolbar-limiter-label');
                flex: 0 0 auto;
                margin-right: rem-calc(11);

                @include breakpoint('large') {
                    margin-right: rem-calc(15);
                }
            }

            .limiter-options {
                display: flex;
            }

            .limiter-option {
                @include button($type: 'tertiary', $size: 'small');
                @include border($color: 'primary');
                @include spacing($spacing-name: 'toolbar-limiter-options', $padding-sides: ('all'));
                display: block;
                width: rem-calc(34);
                height: rem-calc(34);
                box-shadow: none;
                border-left-width: 0;

                &.selected {
                    @include button($type: 'primary', $size: 'small');
                }

                &:hover,
                &:focus,
                &.selected {
                    @include spacing($spacing-name: 'toolbar-limiter-options', $padding-sides: ('all'));
                    box-shadow: none;
                }

                &:first-child {
                    border-left-width: 1px;
                }

                &.limit-all {
                    @include spacing($spacing-name: 'xxsmall', $margin-sides: ('left'));
                    border-left-width: 1px;
                    width: rem-calc(92);

                    @include breakpoint('large') {
                        width: rem-calc(95);
                    }
                }
            }
        }

        .toolbar-sorter {
            display: flex;
            flex: 1 1 auto;
            flex-wrap: nowrap;
            align-items: center;
            padding-bottom: rem-calc(10);

            @include breakpoint('large') {
                justify-content: flex-end;
                padding-top: rem-calc(0);
            }

            @include breakpoint('xlarge') {
                padding-top: rem-calc(15);
                padding-bottom: rem-calc(0);
            }

            .sorter-label {
                @include toolbar-label();
                flex: 0 0 auto;
                margin-right: rem-calc(15);
            }

            .sorter-options {
                @include toolbar-select();
                flex: 0 1 auto;
                min-width: rem-calc(200);

                @include breakpoint('large') {
                    flex: 0 1 rem-calc(200);
                }
            }
        }

        .page-nav,
        .page-count {
            display: none;
        }

        .toolbar-pagination {
            @include toolbar-pagination();
        }

        ~ .toolbar-products {
            .toolbar-top {
                display: none;
            }

            .toolbar-bottom {
                padding-top: rem-calc(20);
            }

            .page-nav {
                display: block;
                flex: 0 0 auto;

                .page-nav-text {
                    @include toolbar-label();
                }

                .page-nav-select {
                    @include toolbar-select();
                    width: rem-calc(60);
                    padding-right: rem-calc(26)
                }
            }

            .page-count {
                @include toolbar-label();
                display: block;
                flex: 1 0 auto;

                &:not(.only-child) {
                    padding-left: rem-calc(7);
                }
            }

            .toolbar-pagination {
                width: 100%;

                @include breakpoint('medium') {
                    width: auto;
                }
            }

            .toolbar-amount,
            .toolbar-sorter,
            .toolbar-limiter {
                display: none;
            }
        }
    }
}
