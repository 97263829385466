@mixin apply-brands-page-styles() {
    body {
        &.brands-index-index {
            @include apply-brands-list-styles();

            .page-title-wrapper {
                @include theme-page-title();
            }
        }
    }
}
