$social-login-google-grey: 'google-grey';
$social-login-facebook-blue: 'facebook-blue';
$social-login-twitter-blue: 'twitter-blue';
$sociallogin-button-facebook-icon: $icon-facebook;
$sociallogin-button-google-icon: $icon-google-plus;

@mixin social-login-button-styles($button-size: 'small') {
    display: flex;
    flex-wrap: wrap;

    .social-login-legend {
        @include heading-styles-h3();
        @include spacing($spacing-name: 'small', $padding-sides: ('bottom'));
        width: 100%;
    }

    .social-login-link {
        @include spacing($spacing-name: 'gutter', $margin-sides: ('right', 'bottom'), $ratio: 0.5);

        &.social-login-link-facebook {
            @include button-with-icon($icon: $sociallogin-button-facebook-icon, $type: 'secondary', $size: $button-size);
            background: get-color($social-login-facebook-blue);

            &:hover,
            &:focus {
                background: darken(get-color($social-login-facebook-blue), 10%);
            }
        }

        &.social-login-link-google {
            @include button-with-icon($icon: $sociallogin-button-google-icon, $type: 'secondary', $size: $button-size);
            background: get-color($social-login-google-grey);

            &:hover,
            &:focus {
                background: darken(get-color($social-login-google-grey), 10%);
            }
        }
    }
}

.social-login-link-list {
    margin-left: 0;

    .social-login-link-item {
        @include unstyled-list();
    }
}
