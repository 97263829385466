@mixin styleguide-responsive-accordion-apply() {
    .example-responsive-accordion {
        @include responsive-accordion-container();
    }

    .example-responsive-accordion-item {
        @include responsive-accordion-item();
    }

    .example-responsive-accordion-heading {
        @include responsive-accordion-item-heading();
    }

    .example-responsive-accordion-title {
        @include responsive-accordion-item-title();
    }

    .example-responsive-accordion-body {
        @include responsive-accordion-item-body();
    }
}
