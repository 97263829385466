@mixin product-cards() {
    .product-item {
        @include product-grid-item();
        @include product-review-summary();
        @include apply-product-grid-badge-styles();
    }
}

@mixin product-grid(
    $layout: $product-grid-layout,
    $gutters: $product-grid-gutters,
    $outer-spacing: 'xsmall'
) {
    @include border();
    @include spacing($outer-spacing, $padding-sides: ('all'));
    background-color: get-color('white');

    .product-items {
        @include unstyled-list();
        @include grid-row($column-gutters: $product-grid-gutters);

        .product-item {
            @include grid-column($layout, $flex-grow: 0, $flex-shrink: 0);
        }
    }
}

@mixin apply-product-slider-styles(
    $outer-spacing: 'xsmall'
) {
    @include product-cards();

    .product-items {
        @include apply-slider-styles();
        @include border();
        @include spacing($outer-spacing, $padding-sides: ('all'));
        background-color: get-color('white');

        .slick-dots {
            position: static;
            justify-content: center;
            transform: none;
        }
    }
}
