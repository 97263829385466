@mixin apply-table-alternate-styles() {
    table {
        @include table-alternate-styles();
    }
}

@mixin table-alternate-styles() {
    @include table(
        $is-hover: false,
        $background-color: 'neutral-white',
        $padding: (
            'small': rem-calc(10 15)
        ),
        $border-color: $table-border-color,
        $is-striped: false,
        $head-cell-font-weight: 'bold'
    );

    > tbody {
        tr {
            color: get-color('body');

            &:hover {
                color: get-color('body');
            }

            &:not(:last-child) {
                border-bottom-width: rem-calc(2);
            }
        }

        th {
            background-color: get-color('neutral-lighter');
            text-align: left;
            color: inherit;

            @each $breakpoint, $size in $table-border-width {
                @include breakpoint($breakpoint) {
                    border-right: $size $table-border-style get-color($table-border-color);
                }
            }
        }

        td {
            background-color: get-color('neutral-white');
        }
    }
}
