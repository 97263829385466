@mixin product-grid-item-hover-styles(
    $box-shadow: $product-grid-item-hover-box-shadow,
    $underline-name: false,
    $section-divider: $product-grid-item-section-divider
) {
    &:hover,
    &:focus {
        box-shadow: $box-shadow;

        @if $underline-name {
            .product-item-name {
                text-decoration: underline;
            }
        }

        .product-item-name {
            color: get-color($product-grid-item-hover-product-name-color);
        }

        .product-item-top {
            &::after {
                border-color: get-color('primary');
            }
        }
    }
}

@mixin product-item-info() {
    @include card-link-overlay();
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    z-index: get-z-index('neutral-sibling');
    min-height: 1px;
}
