@mixin apply-price($font-size: $price-font-size) {
    .price {
        @include price($font-size);
        @include spacing($spacing-name: 'xsmall', $margin-sides: ('right'), $ratio: 0.25);
    }

    .special-price {
        .price {
            @include special-price($font-size);
        }
    }

    .old-price,
    .map-old-price {
        .price {
            @include old-price($font-size);
        }
    }

    .map-price {
        @include old-price($font-size: 'price-small', $text-decoration: none);
        @include spacing($spacing-name: 'xsmall', $margin-sides: ('left'), $ratio: 0.5);

        .price {
            @include old-price($font-size: 'price-small', $text-decoration: none);
        }
    }

    .price-label {
        @include visually-hidden();
    }
}

@mixin product-name(
    $font-size: $product-name-font-size,
    $margin-size: $product-name-margin-size
) {
    @include font($product-name-font-stack, $product-name-font-weight);
    @include font-size($font-size);
    @include line-height($font-size);
    @include typography-margin($margin-size);
    display: block;
    text-transform: $product-name-text-transform;
    color: get-color($product-name-color);
}

@mixin price($font-size: $price-font-size, $color: $price-color, $font-weight: $price-font-weight) {
    @include font($price-font-stack, $font-weight);
    @include font-size($font-size);
    @include line-height($font-size);
    text-transform: $price-text-transform;
    color: get-color($color);
}

@mixin special-price($font-size: $price-font-size) {
    @include price($font-size, $color: $sale-price-color);

    .price-label {
        display: none;
    }
}

@mixin old-price($font-size: $price-font-size, $text-decoration: $old-price-text-decoration) {
    @include price($font-size, $color: $old-price-color, $font-weight: $old-price-font-weight);
    text-decoration: $text-decoration;

    .price-label {
        display: none;
    }
}

@mixin product-attribute-list-styles(
    $font-size: $product-attribute-font-size,
    $color: 'neutral-dark'
) {
    @include font-size($font-size);
    display: table;
    line-height: $product-attribute-line-height;
    color: get-color($color);
    font-weight: get-font-weight('normal');
}

@mixin product-attribute-row-styles() {
    display: table-row;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
}

@mixin product-attribute-label-styles() {
    display: table-cell;
    padding-bottom: $product-attribute-item-spacing;
    text-align: left;
    line-height: inherit;
    color: inherit;
    font-weight: get-font-weight('bold');

    abbr {
        text-decoration: none;
    }
}

@mixin product-attribute-value-styles() {
    display: table-cell;
    padding-bottom: $product-attribute-item-spacing;
    padding-left: rem-calc(10);
    text-align: left;
    line-height: inherit;
    color: inherit;
    font-weight: inherit;
}
