@mixin pullout-menu-styles(
    $side: $pullout-menu-x-axis-origin,
    $top: $pullout-menu-top,
    $width: $pullout-menu-width,
    $menu-border: false,
    $open-display: $pullout-menu-open-styles-display-value,
    $open-duration: $pullout-menu-open-styles-display-duration
) {
    @include box-container($margin-ratio: 0, $border: $menu-border);
    @include pullout-menu-visibility-styles($side);
    @include pullout-menu-theme();
    position: fixed;
    top: $top;
    bottom: 0;
    width: $width;
}

@mixin pullout-menu-theme() {
    box-shadow: 0 rem-calc(17) rem-calc(15) 0 rgba(get-color('neutral-black'), 0.2);
    background-color: get-color('neutral-white');
}

@mixin pullout-menu-visibility-styles($side, $display: $pullout-menu-open-styles-display-value) {
    @include slide-from-animation-x-positioning($side);
    display: none;
    z-index: get-z-index($pullout-menu-z-index);

    &.pullout-menu-is-open {
        @include pullout-menu-open-styles(
            $display: $display,
            $duration: $pullout-menu-open-styles-display-duration,
            $side: $side
        );
    }
}

@mixin pullout-menu-backdrop(
    $top: $pullout-menu-top,
    $duration: $pullout-menu-backdrop-display-duration,
    $z-index: $pullout-menu-overlay-z-index
) {
    @include overlay(
        $z-index: $z-index,
        $hidden: false
    );
    @include pullout-menu-fade-in-out-hidden-state($duration: $duration);
    top: $top;

    &.pullout-menu-is-open {
        @include pullout-menu-fade-in-out-active-state($duration: $duration);
    }
}

@mixin pullout-menu-fade-in-out-hidden-state(
    $duration: $fade-in-animation-display-duration
) {
    left: 100%;
    transition: opacity get-duration($duration), visibility get-duration($duration) step-end, visibility get-duration($duration) step-end;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

@mixin pullout-menu-fade-in-out-active-state(
    $duration: $fade-in-animation-display-duration
) {
    left: 0;
    transition: opacity get-duration($duration), visibility get-duration($duration) step-start, visibility get-duration($duration) step-start;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

@mixin pullout-menu-open-styles(
    $display: $pullout-menu-open-styles-display-value,
    $duration: $pullout-menu-open-styles-display-duration,
    $side: $pullout-menu-open-styles-direction
) {
    @include slide-from-animation(
        $display: $display,
        $duration: $duration,
        $side: $side
    );
}
