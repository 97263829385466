@mixin overlay(
    $z-index: $overlay-z-index,
    $background-color: $overlay-color,
    $hidden: $overlay-hidden-by-default
) {
    @include reset-default-button-styles();
    @if $hidden == true {
        display: none;
    }
    position: fixed;
    top: 0;
    left: 0;
    z-index: get-z-index($z-index);
    background-color: get-color($background-color);
    width: 100%;
    height: 100%;
}
