@mixin apply-my-account-dropdown-styles() {
    .my-account-dropdown {
        @include apply-base-dropdown-anchor-styles();
        @include apply-my-account-dropdown-content-styles();

        .dropdown {
            @include apply-base-dropdown-styles();
        }

        .dropdown-button {
            text-transform: lowercase;
        }
    }
}

@mixin apply-my-account-dropdown-content-styles() {
    .authorization-link-top-bar {
        @include button('primary');
    }

    .authorization-message-top-bar {
        @include font-size('tiny-copy');
        margin-top: rem-calc(15);
    }

    .registration-link-top-bar {
        text-decoration: underline;
    }

    .myaccount-dropdown-links {
        @include apply-base-dropdown-list-styles();
        @include border($sides: ('top'));
        @include spacing(
            $spacing-name: 'gutter',
            $margin-sides: ('top'),
            $max-size: 'small'
        );
        @include spacing(
            $spacing-name: 'gutter',
            $padding-sides: ('top'),
            $max-size: 'small'
        );
    }

    .my-account-dropdown-anchor {
        &.my-account {
            @include icon($icon: $icon-account) {
                margin-right: rem-calc(8);
            }
        }

        &.my-orders {
            @include icon($icon: $icon-cart) {
                margin-right: rem-calc(8);
            }
        }
    }
}
