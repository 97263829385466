@mixin apply-table-styles() {
    table {
        @include table-styles();
    }
}

@mixin table-styles() {
    @include font-size('small-copy');
    @include table(
        $is-hover: false,
        $head-cell-font-weight: 'semi-bold',
        $padding: (
            'small': rem-calc(15 20)
        ),
        $border-color: $table-border-color,
        $is-striped: false
    );

    > thead {
        border-bottom-width: rem-calc(2);
    }

    > tbody {
        border: 0;

        tr {
            color: get-color('body');

            &:hover {
                color: get-color('body');
            }
        }
    }
}

// Overwrite table mixin from northern/module-framework-my-account
@mixin account-table-styles() {
    @include apply-table-styles();
}

@mixin -kindling-table-unstripe(
    $background-color: null,
    $border-width: null,
    $border-style: null,
    $border-color: null,
    $extras: ()
) {
    > tr {
        @include border($border-width, $border-style, $border-color, ('bottom'));
    }
}
