@mixin toolbar-label() {
    @include font-size('small-copy');
    color: get-color('primary');
    font-weight: get-font-weight('semi-bold');
}

@mixin toolbar-amount() {
    @include toolbar-label();
    flex: 1 0 auto;
    padding-right: rem-calc(15);
    padding-bottom: rem-calc(10);
    line-height: 2.4;

    @include breakpoint('large') {
        flex: 0 0 auto;
        width: auto;
    }

    @include breakpoint('xlarge') {
        padding-bottom: 0;
    }
}

@mixin toolbar-layout() {
    @include spacing('gutter', $padding-sides: ('top', 'bottom'), $max-size: 'small');
    @include grid-row($column-gutters: ('small': map-safe-get(get-spacing('gutter'), 'small')));
    justify-content: space-between;
    align-items: center;

    @include breakpoint('large') {
        flex-wrap: nowrap;
    }
}

@mixin toolbar-pagination() {
    display: flex;
    flex: 1 0 auto;
    padding-top: rem-calc(15);

    .button-previous,
    .button-next {
        @include button('primary', 'small');
        flex: 1 0 auto;
    }

    .button-next {
        margin-left: rem-calc(8)
    }

    @include breakpoint('large') {
        flex: 0 0 auto;
        text-align: right;

        .button-previous,
        .button-next {
            flex: 0 0 auto;
        }

        .button-next {
            margin-left: rem-calc(4)
        }
    }
}

@mixin apply-pager-styles() {
    .pager {
        @include toolbar-layout();

        .toolbar-amount {
            @include toolbar-amount();
        }

        .toolbar-pagination {
            @include toolbar-pagination();
        }

        .limiter {
            display: none;
        }
    }
}
