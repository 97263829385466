@mixin apply-customer-account-entry-layout() {
    .customer-account-login,
    .customer-account-create,
    .customer-account-forgotpassword,
    .customer-account-createpassword {
        .page-title-wrapper {
            @include theme-page-title();
        }

        .block-title {
            @include typography-margin('h3');
        }

        .account-entry-container {
            @include grid-row($vertical-gutters: true);
        }

        .block {
            @include grid-column(
                $responsive-sizes: (
                    'small': column-width-percent(12),
                    'medium': column-width-percent(6)
                ),
                $flex-grow: 0
            );
        }

        .fields {
            @include grid-row();

            .field {
                @include grid-column(
                    $responsive-sizes: (
                        'small': column-width-percent(12),
                        'large': column-width-percent(6)
                    )
                );
            }
        }
    }
}
