@mixin apply-value-adds-styles() {
    .value-adds-list {
        @include unstyled-list();
        @include grid-row();
        @include spacing($spacing-name: 'small', $padding-sides: ('top'));
        justify-content: space-between;
    }

    .value-add-item {
        @include grid-column(
            $responsive-sizes: (
                'small': column-width-percent(12, 12),
                'medium': column-width-percent(6, 12),
                'large': column-width-percent(2, 12)
            )
        );
        @include spacing($spacing-name: 'small', $padding-sides: ('bottom'));
        display: flex;
        align-items: center;

        @include breakpoint('large') {
            display: block;
            text-align: center;
        }

        img {
            flex-shrink: 0;
            margin-right: rem-calc(16);
            height: rem-calc(40);

            @include breakpoint('large') {
                margin-right: 0;
                margin-bottom: rem-calc(5);
            }
        }

        p {
            @include font-size('subheading-small');
        }
    }
}
