@mixin styleguide-accordion-apply() {
    .example-accordion {
        @include theme-accordion-container();
    }

    .example-accordion-item {
        @include theme-accordion-item();
    }

    .example-accordion-title {
        @include theme-accordion-item-title();
    }

    .example-accordion-body {
        @include theme-accordion-item-body();
    }
}
