// tooltips are appended to the end of the DOM (to prevent z-index issues) so this mixin should not be nested
@mixin apply-global-tooltip-styles() {
    .tooltip-button {
        @include custom-tooltip-button-styles();
    }

    .custom-tooltip {
        @include custom-tooltip-styles();
    }
}

@mixin custom-tooltip-styles(
    $side: $custom-tooltip-side,
    $align: $custom-tooltip-align,
    $border-size: $custom-tooltip-border-size,
    $border-color: $custom-tooltip-border-color
) {
    @include tooltip(
        $side: $side,
        $align: $align
    );
    @if $border-size {
        // partial fake border on side of the arrow using another pseudo element.
        @include custom-tooltip-arrow(
            $psuedo-element: 'after',
            $side: $side,
            $align: $align,
            $color: $border-color
        );
        // the border around the message
        box-shadow: 0 0 0 $border-size get-color($border-color);

        &::after {
            z-index: -1;
            margin-#{$side}: $border-size;
        }
    }

    a {
        text-decoration: underline;
        color: get-color($tooltip-color);

        &:hover,
        &:focus {
            color: get-color('secondary');
        }
    }
}

@mixin custom-tooltip-button-base(
    $size: $custom-tooltip-button-size,
    $icon: $custom-tooltip-button-icon,
    $active-icon: $custom-tooltip-button-active-icon,
    $icon-color: $custom-tooltip-button-icon-color,
    $active-icon-color: $custom-tooltip-button-active-icon-color,
    $icon-font-size: $custom-tooltip-button-icon-font-size,
    $active-icon-font-size: $custom-tooltip-button-active-icon-font-size,
    $background: $custom-tooltip-button-background,
    $active-background: $custom-tooltip-button-active-background,
    $transition-speed: $custom-tooltip-button-transition-speed,
    $transition-timing: $custom-tooltip-button-transition-timing
) {
    @include has-tip();
    @include icon($icon: $icon) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: get-color($icon-color);
        font-size: $icon-font-size;
    }
    transition: get-duration($transition-speed) get-timing-function($transition-timing);
    outline: none;
    border-bottom: 0;
    border-radius: 50%;
    background: get-color($background);
    padding: 0;
    width: $size;
    height: $size;
    line-height: 1;

    &.active {
        @include icon($icon: $active-icon) {
            color: get-color($active-icon-color);
            font-size: $active-icon-font-size;
        }
        background: get-color($active-background);
    }
}

@mixin custom-tooltip-button-position(
    $side: $custom-tooltip-button-side,
    $side-offset: $custom-tooltip-button-side-offset,
    $top: $custom-tooltip-button-position-top
) {
    position: absolute;
    #{$side}: $side-offset;
    top: $top;
    transform: translateY(-50%);
    z-index: 10;
}

@mixin custom-tooltip-button-larger-clickable-target(
    $psuedo-element: $custom-tooltip-button-larger-clickable-target-psuedo-element,
    $sizes: $custom-tooltip-button-larger-clickable-target-sizes
) {
    &::#{$psuedo-element} {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';

        @each $breakpoint, $size in $sizes {
            @include breakpoint($breakpoint) {
                width: $size;
                height: $size;
            }
        }
    }
}

@mixin custom-tooltip-arrow(
    $psuedo-element,
    $side: $custom-tooltip-side,
    $align: $custom-tooltip-align,
    $color: $custom-tooltip-background-color,
    $pip-width: $tooltip-pip-width
) {
    &::#{$psuedo-element} {
        position: absolute;
    }

    @if $side == 'bottom' {
        &::#{$psuedo-element} {
            @include css-triangle($pip-width, $color, 'up');
            bottom: 100%;

            @if $align == 'center' {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    @if $side == 'top' {
        &::#{$psuedo-element} {
            @include css-triangle($pip-width, $color, 'down');
            top: 100%;

            @if $align == 'center' {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    @if $side == 'left' {
        &::#{$psuedo-element} {
            @include css-triangle($pip-width, $color, right);
            left: 100%;

            @if $align == 'center' {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
            }
        }
    }

    @if $side == 'right' {
        &::#{$psuedo-element} {
            @include css-triangle($pip-width, $color, left);
            right: 100%;
            left: auto;

            @if $align == 'center' {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
            }
        }
    }

    @if $align == 'top' {
        &::#{$psuedo-element} {
            top: 10%;
            bottom: auto;
        }
    }

    @if $align == 'bottom' {
        &::#{$psuedo-element} {
            top: auto;
            bottom: 10%;
        }
    }

    @if $align == 'left' {
        &::#{$psuedo-element} {
            right: auto;
            left: 10%;
        }
    }

    @if $align == 'right' {
        &::#{$psuedo-element} {
            right: 10%;
            left: auto;
        }
    }
}

@mixin custom-tooltip-button-styles(
    $size: $custom-tooltip-button-size,
    $icon: $custom-tooltip-button-icon,
    $active-icon: $custom-tooltip-button-active-icon,
    $icon-color: $custom-tooltip-button-icon-color,
    $active-icon-color: $custom-tooltip-button-active-icon-color,
    $icon-font-size: $custom-tooltip-button-icon-font-size,
    $active-icon-font-size: $custom-tooltip-button-active-icon-font-size,
    $background: $custom-tooltip-button-background,
    $active-background: $custom-tooltip-button-active-background,
    $transition-speed: $custom-tooltip-button-transition-speed,
    $transition-timing: $custom-tooltip-button-transition-timing,
    $side: $custom-tooltip-button-side,
    $side-offset: $custom-tooltip-button-side-offset,
    $psuedo-element: $custom-tooltip-button-larger-clickable-target-psuedo-element,
    $sizes: $custom-tooltip-button-larger-clickable-target-sizes,
    $button-position-top: $custom-tooltip-button-position-top,
    $apply-button-larger-clickable-target-psuedo-element-styles: $custom-tooltip-apply-button-larger-clickable-target-psuedo-element-styles,
    $apply-button-position-styles: $custom-tooltip-apply-button-position-styles
) {
    @include custom-tooltip-button-base(
        $size: $size,
        $icon: $icon,
        $active-icon: $active-icon,
        $icon-color: $icon-color,
        $active-icon-color: $active-icon-color,
        $icon-font-size: $icon-font-size,
        $active-icon-font-size: $active-icon-font-size,
        $background: $background,
        $active-background: $active-background,
        $transition-speed: $transition-speed,
        $transition-timing: $transition-timing
    );
    @if $apply-button-position-styles {
        @include custom-tooltip-button-position($side, $side-offset, $button-position-top);
    }
    @if $apply-button-larger-clickable-target-psuedo-element-styles {
        @include custom-tooltip-button-larger-clickable-target($psuedo-element, $sizes);
    }
}

@mixin checkout-tooltip-button-styles($parameters: $checkout-button-tooltip-style-options) {
    @include custom-tooltip-button-styles($parameters...);
}

@mixin checkout-tooltip-styles($parameters: $checkout-tooltip-style-options) {
    @include custom-tooltip-styles($parameters...);
}
