@mixin apply-northern-sociallogin-customer-connections-styles() {
    .account {
        .customer-account-social-connections {
            .social-type-container {
                @include spacing($spacing-name: 'small', $margin-sides: ('bottom'));
                @include spacing($spacing-name: 'box', $padding-sides: ('all'));
                @include border();
                background-color: get-color($box-body-background-color);
                color: get-color($box-body-text-color);

                .social-container-inner {
                    @include grid-row($vertical-gutters: true);
                }

                .social-type-image-container {
                    @include grid-column(
                        $responsive-sizes: (
                            'small': column-width-percent(12),
                            'medium': column-width-percent(3)
                        )
                    )
                }

                .social-type-info-container {
                    @include grid-column(
                        $responsive-sizes: (
                            'small': column-width-percent(12),
                            'medium': column-width-percent(6)
                        ), $flex-shrink: 1
                    )
                }

                .social-type-info {
                    @include unstyled-list();
                }

                .social-type-actions {
                    @include grid-column(
                        $responsive-sizes: (
                            'small': column-width-percent(12),
                            'medium': column-width-percent(12),
                            'large': column-width-percent(3)
                        )
                    )
                }

                .social-type-connect {
                    @include button($type: 'primary', $size: 'small');
                }

                .social-type-disconnect {
                    @include button($type: 'tertiary', $size: 'small');
                    width: 100%;
                }
            }
        }
    }
}
