@mixin checkout-product-list-img-styles(
    $width: $checkout-summary-product-img-width,
    $margin-right: $checkout-summary-image-margin
) {
    display: block;
    flex: 0 0 auto;
    margin-right: $margin-right;
    align-self: baseline;
    width: $width;

    .photo {
        display: block;
        width: 100%;
    }
}

@mixin checkout-product-list-item-name-styles($font-size: $product-name-font-size) {
    @include product-name($font-size);
    margin: rem-calc(0 0 10);

    > a {
        text-decoration: inherit;
        color: inherit;
        font-size: inherit;
    }
}

@mixin checkout-product-list-item-price-styles() {
    @include apply-price();
    margin-bottom: rem-calc(10);
}
