@mixin apply-category-description-styles() {
    .category-description {
        @include border($sides: ('top'));
        @include spacing('gutter', $padding-sides: ('top', 'bottom'));

        .category-description-title {
            @include spacing('gutter', $margin-sides: ('bottom'), $max-size: 'small');
        }
    }
}
