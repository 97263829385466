// GLOBALS
$global-font-size: 16px !default; // must be pixel value

$global-max-width: rem-calc(1660);

$global-border-color: 'neutral-light' !default;
$global-border-style: solid !default;
$global-border-width: (
    'small': 1px
) !default;

$grid-columns: 12;

// SPACING
$global-spacing: (
    'gutter': (
        'small': rem-calc(20),
        'large': rem-calc(30)
    ),
    'box': (
        'small': rem-calc(22 26)
    ),
    'box-header': (
        'small': rem-calc(22 26 12)
    ),
    'xxsmall': (
        'small': rem-calc(10)
    ),
    'xsmall': (
        'small': rem-calc(15)
    ),
    'small': (
        'small': rem-calc(20),
        'large': rem-calc(30)
    ),
    'medium': (
        'small': rem-calc(30),
        'large': rem-calc(40)
    ),
    'large': (
        'small': rem-calc(40),
        'large': rem-calc(60)
    ),
    'xlarge': (
        'small': rem-calc(60),
        'large': rem-calc(80)
    ),
    // Duplicate for northern module compatibility
    'xxlarge': (
        'small': rem-calc(60),
        'large': rem-calc(80)
    ),
    'footer-social-media': (
        'small': rem-calc(10),
        'large': rem-calc(20)
    ),
    'minicart': (
        'small': rem-calc(20)
    ),
    'checkout-sidebar': (
        'small': rem-calc(30)
    ),
    'cart-cross-sell': (
        'small': rem-calc(30)
    ),
    'review-summary-spacing': (
        'small': rem-calc(10)
    ),
    'swatch-picker-modal': (
        'small': rem-calc(40)
    ),
    'toolbar-limiter-options': (
        'small': rem-calc(9 0)
    )
) !default;

$global-margin: get-spacing('gutter') !default;
$global-padding: get-spacing('gutter') !default;

// TYPOGRAPHY
$font-family-primary: 'averta' !default;
$primary-font-face-weights: (
    'normal': (
        'normal': 'averta-regular'
    ),
    'semi-bold': (
        'normal': 'averta-semibold'
    ),
    'bold': (
        'normal': 'averta-bold'
    )
) !default;

$font-family-secondary: 'playfairdisplay' !default;
$secondary-font-face-weights: (
    'normal': (
        'italic': 'playfairdisplay-italic'
    )
) !default;

$font-family-icon: 'barstools-icon' !default;
$icon-font-family: $font-family-icon !default;
$icons-font-face-weights: (
    'normal': (
        'normal': 'barstools-icon',
    )
);

$font-path: '../font' !default;
$icon-vertical-align: -10% !default;

$font-stacks: (
    'primary': ($font-family-primary, sans-serif),
    'secondary': ($font-family-secondary, serif),
    'sans-serif': ($font-family-primary, sans-serif),
    'serif': ($font-family-secondary, serif),
    'icon': ($icon-font-family),
    'monospace': monospace
);

$font-primary-stack: 'primary' !default;
$font-primary-weight: 'normal' !default;
$font-secondary-stack: 'secondary' !default;
$font-secondary-weight: 'normal' !default;

$body-font-stack: $font-primary-stack !default;
$body-font-weight: $font-primary-weight !default;
$body-font-color: 'body' !default;

$heading-font-weight: 'semi-bold' !default;
$heading-text-transform: null;
$theme-heading-text-transform: (
    'h1': uppercase,
    'h2': uppercase,
    'h3': null,
    'h4': null,
    'h5': null,
    'h6': null
);
$heading-font-stack: 'primary' !default;

$subheading-font-stack: 'secondary' !default;
$subheading-font-weight: 'normal' !default;
$subheading-color: 'primary' !default;
$subheading-font-style: italic !default;
$subheading-text-transform: null !default;
$subheading-small-font-color: null !default;

$small-copy-color: 'body' !default;
$tiny-copy-color: 'body' !default;

$anchor-text-decoration: none !default;
$anchor-color: 'primary' !default;
$anchor-color-hover: 'secondary' !default;

$font-sizes: (
    'normal': (
        'small': rem-calc($global-font-size)
    ),
    'h1': (
        'small': rem-calc(22),
        'large': rem-calc(22)
    ),
    'h2': (
        'small': rem-calc(18),
        'large': rem-calc(20)
    ),
    'h3': (
        'small': rem-calc(18)
    ),
    'h4': (
        'small': rem-calc(16)
    ),
    'h5': (
        'small': rem-calc(15)
    ),
    'h6': (
        'small': rem-calc(14)
    ),
    'subheading-large': (
        'small': rem-calc(18)
    ),
    'subheading-medium': (
        'small': rem-calc(16)
    ),
    'subheading-small': (
        'small': rem-calc(14)
    ),
    'subheading-tiny': (
        'small': rem-calc(12)
    ),
    'small-copy': (
        'small': rem-calc(14)
    ),
    'tiny-copy': (
        'small': rem-calc(12)
    ),
    'hyperlink-small': (
        'small': rem-calc(13)
    ),
    'icon-link-anchor': (
        'small': rem-calc(14)
    ),
    'icon-link-icon': (
        'small': rem-calc(20)
    ),
    'small-element': (
        'small': rem-calc(12)
    ),
    'blockquote': (
        'small': rem-calc(18)
    ),
    'table': (
        'small': rem-calc(14)
    ),
    'p': (
        'small': rem-calc(16)
    ),
    'li': (
        'small': rem-calc($global-font-size)
    ),
    'cite': (
        'small': rem-calc($global-font-size)
    ),
    'form-error': (
        'small': rem-calc(12)
    ),
    'input': (
        'small': rem-calc(14)
    ),
    //'input-empty-placeholder': (
    //    'small': rem-calc(12)
    //),
    'radio': (
        'small': rem-calc(14)
    ),
    'checkbox': (
        'small': rem-calc(14)
    ),
    'sneaky-label': (
        'small': rem-calc(8)
    ),
    'megamenu-anchor': (
        'small': rem-calc(15)
    ),
    'checkout-step': (
        'small': rem-calc(15)
    ),
    'checkout-header-main-icon': (
        'small': rem-calc(24)
    ),
    'product-name-large': (
        'small': rem-calc(22)
    ),
    'product-name-medium': (
        'small': rem-calc(16)
    ),
    'product-name-small': (
        'small': rem-calc(14)
    ),
    'price-large': (
        'small': rem-calc(20)
    ),
    'price-medium': (
        'small': rem-calc(16)
    ),
    'price-small': (
        'small': rem-calc(12)
    ),
    'customer-account-navigation-link': (
        'small': rem-calc(14)
    ),
    //'sideline-anchor': (
    //    'small': rem-calc(13),
    //    'xlarge': rem-calc(16)
    //),
    'top-bar-copy': (
        'small': rem-calc(12),
        'xlarge': rem-calc(13)
    ),
    //'tiny-label': (
    //    'small': rem-calc(9)
    //),
    //'footer-navigation-heading': (
    //    'small': rem-calc(18)
    //),
    //'home-grid-text': (
    //    'small': rem-calc(18)
    //),
    //'table-title': (
    //    'small': rem-calc(13),
    //    'large': rem-calc(16)
    //),
    'tooltip': (
        'small': rem-calc(12)
    ),
    //'accordion-title': (
    //    'small': rem-calc(16)
    //),
    'product-action-large': (
        'small': rem-calc(14),
        'large': rem-calc(18)
    ),
    'social-icons': (
        'small': rem-calc(22)
    ),
    'product-badge-large': (
        'small': rem-calc(10),
        'xxlarge': rem-calc(12)
    ),
    'product-badge': (
        'small': rem-calc(8),
        'xxlarge': rem-calc(10)
    ),
    'checkout-sidebar-grand-total': (
        'small': rem-calc(18)
    ),
    'checkout-sidebar-totals': (
        'small': rem-calc(14)
    ),
    'checkout-sidebar-subtotal': (
        'small': rem-calc(16)
    ),
    'checkout-value-adds': (
        'small': rem-calc(15)
    ),
    //'product-social-icon': (
    //    'small': rem-calc(15)
    //),
    //'swatch-option-label': (
    //    'small': rem-calc(13)
    //),
    //'megamenu-toggle-button': (
    //    'small': rem-calc(9)
    //),
    //'megamenu-mobile-close-button': (
    //    'small': rem-calc(18)
    //),
    //'megamenu-item-link': (
    //    'small': rem-calc(14)
    //),
    //'megamenu-top-level-caret': (
    //    'small': rem-calc(12)
    //),
    //'megamenu-leaf-items': (
    //    'small': rem-calc(14)
    //),
    //'catalogue-grid-item-title': (
    //    'small': rem-calc(14),
    //    'large': rem-calc(18)
    //),
    //'overview-totals-mark': (
    //    'small': rem-calc(13)
    //),
    //'overview-totals-amount': (
    //    'small': rem-calc(14)
    //),
    //'overview-totals-grand-mark': (
    //    'small': rem-calc(16)
    //),
    //'overview-totals-grand-amount': (
    //    'small': rem-calc(18),
    //    'large': rem-calc(22)
    //),
    //'footer-copy': (
    //    'small': rem-calc(12),
    //    'xlarge': rem-calc(14)
    //),
    //'checkout-header-element': (
    //    'small': rem-calc(14),
    //    'large': rem-calc(18)
    //)
) !default;
$tooltip-font-size: 'tooltip' !default;

$line-heights: (
    'normal': (
        'small': 1.5
    ),
    'h1': (
        'small': line-height-calc(map-safe-get(get-font-size('h1'), 'small'), rem-calc(26)),
        'large': line-height-calc(map-safe-get(get-font-size('h1'), 'large'), rem-calc(28))
    ),
    'h2': (
        'small': line-height-calc(map-safe-get(get-font-size('h2'), 'small'), rem-calc(22)),
        'large': line-height-calc(map-safe-get(get-font-size('h2'), 'large'), rem-calc(24))
    ),
    'h3': (
        'small': line-height-calc(map-safe-get(get-font-size('h3'), 'small'), rem-calc(22))
    ),
    'h4': (
        'small': line-height-calc(map-safe-get(get-font-size('h4'), 'small'), rem-calc(20))
    ),
    'h5': (
        'small': line-height-calc(map-safe-get(get-font-size('h5'), 'small'), rem-calc(19))
    ),
    'h6': (
        'small': line-height-calc(map-safe-get(get-font-size('h6'), 'small'), rem-calc(18))
    ),
    'subheading-large': (
        'small': line-height-calc(map-safe-get(get-font-size('subheading-large'), 'small'), rem-calc(28))
    ),
    'subheading-medium': (
        'small': line-height-calc(map-safe-get(get-font-size('subheading-medium'), 'small'), rem-calc(25))
    ),
    'subheading-small': (
        'small': line-height-calc(map-safe-get(get-font-size('subheading-small'), 'small'), rem-calc(28))
    ),
    'p': (
        'small': line-height-calc(map-safe-get(get-font-size('p'), 'small'), rem-calc(24))
    ),
    'blockquote': (
        'small': line-height-calc(map-safe-get(get-font-size('blockquote'), 'small'), rem-calc(22))
    ),
    'table': (
        'small': line-height-calc(map-safe-get(get-font-size('blockquote'), 'small'), rem-calc(18))
    ),
    'small-copy': (
        'small': line-height-calc(map-safe-get(get-font-size('small-copy'), 'small'), rem-calc(20))
    ),
    'tiny-copy': (
        'small': line-height-calc(map-safe-get(get-font-size('tiny-copy'), 'small'), rem-calc(16))
    ),
    'input': (
        'small': 1.15
    ),
    'textarea': (
        'small': 1.15,
    ),
    'product-name-large': (
        'small': line-height-calc(map-safe-get(get-font-size('product-name-large'), 'small'), rem-calc(22))
    ),
    'product-name-medium': (
        'small': line-height-calc(map-safe-get(get-font-size('product-name-medium'), 'small'), rem-calc(22))
    ),
    'product-name-small': (
        'small': line-height-calc(map-safe-get(get-font-size('product-name-small'), 'small'), rem-calc(20))
    ),
    'price-large': (
        'small': rem-calc(24)
    ),
    'price-medium': (
        'small': rem-calc(20)
    ),
    'price-small': (
        'small': rem-calc(14)
    ),
    'price-tiny': (
        'small': rem-calc(14)
    ),
    'product-badge': (
        'small': 1.25
    ),
    'checkout-value-adds': (
        'small': 2
    ),
    'toolbar-limiter-label': (
        'small': 2.4
    )
) !default;

$letter-spacing: (
    'banner': null,
    'h1': (
        'small': -kindling-bp-to-em(1.9px),
        'large': -kindling-bp-to-em(1.74px)
    ),
    'h2': -kindling-bp-to-em(1.7px),
    'h3': null,
    'h4': null,
    'h5': null,
    'h6': null,
    'subheading-large': null,
    'subheading-medium': null,
    'subheading-small': null,
    'subheading-tiny': null,
    'button-regular': -kindling-bp-to-em(2px),
    'button-large': -kindling-bp-to-em(2px),
    'button-small': -kindling-bp-to-em(1.4px),
    'button-tiny': -kindling-bp-to-em(1.2px)
    //'nav-item-small': (
    //    'small': rem-calc(2)
    //),
    //'checkout-product-name': (
    //    'small': rem-calc(2)
    //),
    //'product-badge-letter-spacing': (
    //    'small': rem-calc(2)
    //)
) !default;

$typography-margins: (
    'p': (
        'small': rem-calc(30)
    ),
    'h1': (
        'small': rem-calc(20),
        'large': rem-calc(25)
    ),
    'h2': (
        'small': rem-calc(15),
        'large': rem-calc(20)
    ),
    'h3': (
        'small': rem-calc(15)
    ),
    'h4': (
        'small': rem-calc(10)
    ),
    'h5': (
        'small': rem-calc(5),
        'large': rem-calc(7)
    ),
    'h6': (
        'small': rem-calc(5)
    ),
    'subheading-large': (
        'small': rem-calc(20)
    ),
    'subheading-medium': (
        'small': rem-calc(20)
    ),
    'subheading-small': (
        'small': rem-calc(15)
    ),
    'subheading-tiny': (
        'small': rem-calc(15)
    ),
    'ul': (
        'small': rem-calc(30)
    ),
    'ul-li': (
        'small': rem-calc(10)
    ),
    'dropdown-li': (
        'small': rem-calc(18)
    ),
    'ol': (
        'small': rem-calc(30)
    ),
    'ol-li': (
        'small': rem-calc(10)
    ),
    'dl': $global-margin,
    'dt': (
        'small': 0.3rem,
    ),
    'dd': (
        'small': 0.3rem,
    ),
    'caption': (
        'small': rem-calc(10),
        'large': rem-calc(15)
    ),
    'product-name-large': (
        'small': rem-calc(20)
    ),
    'product-name-medium': (
        'small': rem-calc(15)
    ),
    'product-name-small': (
        'small': rem-calc(10)
    ),
    'blockquote': (
        'small': rem-calc(30)
    )
) !default;

// COLORS/CONTEXTS
$color-palette: (
    'black': #000000,
    'grey': #6e7172,
    'taupe': #e2ded2,
    'beige': #eeecdf,
    'ivory': #f6f4ee,
    'white': #ffffff,
    'orange': #ea6244,
    'dark-orange': #ce4729,
    'light-orange': #efd5d0,
    'dark-green': #2b4530,
    'green': #11592c,
    'light-green': #cde4d6,
    'blue': #355a79,
    'light-blue': #4b7090,
    'lighter-blue': #d2e1ee,
    'dark-blue': #113859,
    'darker-blue': #0b253b,
    'turquoise': #7bb8bd,
    'dark-turquoise': #569095,
    'gold': #dcaf4a,
    'light-gold': #f4e7cc,
    'dark-gold': #b88e2f,
    'darker-gold': #6c5624,
    'google-grey': #6e7172,
    'facebook-blue': #3b5998,
    'twitter-blue': #00aced,
    'transparent': transparent
) !default;

$color-contexts: (
    'body': 'grey',
    'primary': 'dark-blue',
    'primary-light': 'dark-blue',
    'primary-dark': 'darker-blue',
    'secondary': 'orange',
    'secondary-light': 'orange',
    'secondary-dark': 'dark-orange',
    'accent-1': 'gold',
    'accent-1-dark': 'dark-gold',
    'accent-2': 'turquoise',
    'accent-2-dark': 'dark-turquoise',
    'accent-3': 'light-blue',
    'accent-3-dark': 'blue',
    'neutral-white': 'white',
    'neutral-lightest': 'ivory',
    'neutral-lighter': 'beige',
    'neutral-light': 'taupe',
    'neutral-dark': 'grey',
    'neutral-darker': 'grey',
    'neutral-darkest': 'grey',
    'neutral-black': 'black',
    'success-light': 'light-green',
    'success': 'green',
    'success-dark': 'dark-green',
    'information-light': 'lighter-blue',
    'information': 'light-blue',
    'information-dark': 'dark-blue',
    'warning-light': 'light-gold',
    'warning': 'gold',
    'warning-dark': 'darker-gold',
    'error-light': 'light-orange',
    'error': 'orange',
    'error-dark': 'dark-orange'
) !default;

$body-background-color: 'neutral-lightest';

// TABLES
$table-border-color: $global-border-color;
$table-border-width: $global-border-width;
$table-border-style: $global-border-style;
$table-head-background-color: 'neutral-lighter';
$table-head-font-color: 'primary';
$table-head-cell-font-weight: 'semi-bold';
$table-background-color: 'transparent';

// RESPONSIVE TABLE
$responsive-table-is-hover: false;
$responsive-table-padding: (
    'small': rem-calc(15 20)
);
$responsive-table-border-width: (
    'small': 0
);
$responsive-table-stripe: odd;
$responsive-table-head-background-color: 'neutral-black';
$responsive-table-head-font-color: 'neutral-white';

// Z-INDEXES
$z-indexes: (
    'header': 900,
    'header-main': 900,
    'modal-overlay': 1500,
    'modal-content': 1600,
    'pullout-menu-overlay': 1150,
    'pullout-menu-content': 1200,
    //'sticky-nav': 850,
    'footer-sticky': 820,
    'product-actions-fixed': 800,
    'above-sibling': 1,
    'neutral-sibling': 0,
    'below-sibling': -1,
    'below-all-siblings': -999999,
    //'skip-link': 999999999
);

// Heading styles
$heading-color: 'primary' !default;

// PRODUCT
$product-name-font-weight: 'normal';
$product-name-font-stack: $body-font-stack !default;
$product-name-font-size: 'product-name-small';
$product-name-color: 'neutral-dark';
$product-name-text-transform: none !default;
$product-name-margin-size: $product-name-font-size !default;

$price-font-weight: 'bold' !default;
$price-font-stack: 'primary';
$price-font-size: 'price-medium' !default;
$price-color: 'primary';
$price-text-transform: uppercase;
$sale-price-color: 'secondary';
$old-price-color: 'neutral-dark';
$old-price-font-weight: 'normal';
$old-price-text-decoration: line-through !default;

$product-attribute-item-spacing: rem-calc(10);
$product-attribute-line-height: 1;
$product-attribute-font-size: 'small-copy';

// BADGES
$product-badge-grid-positioning: (
    'bottom': rem-calc(10),
    'left': rem-calc(-10)
);
$product-badge-list-positioning: (
    'top': rem-calc(10),
    'right': rem-calc(-10)
);
$product-badge-letter-spacing: 0.5px;
$product-badge-size: (
    'small': rem-calc(50),
    'xxlarge': rem-calc(60)
);
$product-badge-text-transform: uppercase;

// STYLEGUIDE
$styleguide-price-sizes: ('large', 'medium', 'small');
$styleguide-product-name-sizes: ('large', 'medium', 'small');

// BUTTONS
$button-sizes: (
    'tiny': (
        'small': (
            'padding': rem-calc(8 15),
            'font-size': rem-calc(10),
            'line-height': rem-calc(11)
        )
    ),
    'small': (
        'small': (
            'padding': rem-calc(11 15),
            'font-size': rem-calc(12),
            'line-height': rem-calc(14)
        )
    ),
    'regular': (
        'small': (
            'padding': rem-calc(14 16),
            'font-size': rem-calc(14),
            'line-height': rem-calc(16)
        )
    ),
    'large': (
        'small': (
            'padding': rem-calc(17 20),
            'font-size': rem-calc(16),
            'line-height': rem-calc(18)
        )
    )
) !default;

$button-default-type: 'primary' !default;
$button-default-size: 'regular' !default;
$default-button-icon-spacing: rem-calc(4) !default;
$button-font-stack: 'primary' !default;
$button-font-weight: 'semi-bold' !default;
$button-text-transform: uppercase !default;
$button-border-color: 'primary' !default;

$primary-button: 'primary' !default;
$primary-button-hover: 'accent-3-dark' !default;
$primary-button-color: 'neutral-white' !default;
$primary-button-color-hover: $primary-button-color !default;

$secondary-button: 'secondary' !default;
$secondary-button-hover: 'secondary-dark' !default;
$secondary-button-color: 'neutral-white' !default;
$secondary-button-color-hover: $secondary-button-color !default;

$tertiary-button: $body-background-color !default;
$tertiary-button-hover: 'primary' !default;
$tertiary-button-color: 'primary' !default;
$tertiary-button-color-hover: 'neutral-white' !default;
$tertiary-button-border-width: (
    'small': 1px
) !default;

$all-button-border-width: 0 !default;
$primary-button-border-width: $all-button-border-width !default;
$secondary-button-border-width: $all-button-border-width !default;

$all-button-border-radius: 0 !default;
$primary-button-border-radius: $all-button-border-radius !default;
$secondary-button-border-radius: $all-button-border-radius !default;
$tertiary-button-border-radius: $all-button-border-radius !default;

$button-disabled-text-color-base: 'neutral-dark' !default;
$button-disabled-text-color-alpha: 1 !default;
$button-disabled-background-color: 'neutral-light' !default;
$button-disabled-border-color: 'neutral-light' !default;
$button-disabled-border-size: 0 !default;

// FORMS
$form-margin: (
    'small': rem-calc(20)
) !default;

$form-legend-font-weight: 'normal';
$legend-heading-text-transform: uppercase;
$legend-heading-type: 'h2';
$legend-heading-text-color: null;

$legend-description-text-color: null;

$sneaky-label-color: 'primary' !default;
$sneaky-label-proxy-color: 'primary' !default;
$sneaky-label-disabled-color: 'neutral-dark' !default;
$sneaky-label-text-transform: none !default;
$sneaky-label-proxy-text-transform: uppercase !default;

$form-checkbox-content-checked: $icon-checkbox-on;
$form-checkbox-content-unchecked: $icon-checkbox-off;
$form-radio-content-unchecked: $icon-radio-off;
$form-radio-content-checked: $icon-radio-on;
$form-radio-checkbox-margin: rem-calc(5);
$form-radio-icon-size: rem-calc(18);
$form-checkbox-icon-size: rem-calc(18);

$input-border-color: 'neutral-light' !default;
$input-border-color-hover: 'primary' !default;
$input-background-color: 'neutral-white' !default;
$input-background-disabled: 'neutral-lightest' !default;
$input-border-radius: 0 !default;
$input-border-width: (
    'small': 1px
) !default;
$input-border-width-hover: (
    'small': rem-calc(2)
);
$input-padding: rem-calc(14 12) !default;
$input-color: 'primary' !default;
$input-placeholder-color: 'primary' !default;
$input-border-color-disabled: 'neutral-light' !default;
$input-color-disabled: 'neutral-dark' !default;
$validation-error-margin-top: rem-calc(5);

$select-triangle-size: rem-calc(10) !default;
$select-triangle-color: 'primary' !default;
$triangle-shapes: (
    'triangle': '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32"><path d="M29.285 7.067l1.833 1.926-15.118 15.94-15.118-15.94 1.824-1.926 13.294 14.005z" fill="___COLOR___"></path></svg>'
) !default;
$label-hidden-before-initialized: true !default;

$radio-label-font-family: null !default;
$radio-label-color: 'primary' !default;
$radio-label-disabled-color: 'neutral-dark' !default;
$radio-icon-color: 'primary' !default;
$radio-icon-color-checked: $radio-label-color !default;

// Northern_Checkout variables
$checkbox-label-color: $radio-label-color !default;
$checkbox-label-disabled-color: $radio-label-disabled-color !default;
$checkbox-icon-color: $radio-icon-color !default;
$checkbox-icon-color-checked: $checkbox-label-color !default;
$checkbox-label-font-family: null !default;
$checkout-cart-back-button-type: 'tertiary' !default;
$checkout-cart-update-button-type: 'tertiary' !default;

// MODAL
$modal-overlay-background: transparentize(get-color('transparent'), 0);
$modal-width: rem-calc(700);

$theme-modal-spacing-size: 'gutter';
$theme-modal-copy-color: $body-font-color;
$theme-modal-close-button-size: rem-calc(50);

// OVERLAY
$overlay-z-index: 'modal-overlay';
$overlay-color: $modal-overlay-background;
$overlay-hidden-by-default: true;

// My Account
$icon-triangle-down: null !default;
$my-account-icon-arrow-down: $icon-triangle-down !default;

// Northern_MyAccount variables
$my-account-section-title-button-type: 'tertiary';
$my-account-toolbar-limiter-button-background-color: $tertiary-button !default;
$my-account-toolbar-limiter-button-background-color-hover: $tertiary-button-hover !default;
$my-account-toolbar-limiter-button-text-color: $tertiary-button-color !default;
$my-account-toolbar-limiter-button-text-color-hover: $tertiary-button-color-hover !default;
$customer-address-back-action-button-type: 'tertiary' !default;
$my-account-login-create-back-button-type: 'tertiary' !default;
$my-account-create-button-show-icon: false !default;

// Tooltips
$tooltip-background-color: 'primary' !default;
$tooltip-color: 'neutral-white' !default;
$tooltip-pip-width: rem-calc(5) !default;
$tooltip-padding: rem-calc(15) !default;
$tooltip-max-width: rem-calc(215) !default;

$custom-tooltip-button-size: rem-calc(16);
$custom-tooltip-button-side: 'right';
$custom-tooltip-button-side-offset: get-side($input-padding, 'right');
$custom-tooltip-button-icon: $icon-question-mark;
$custom-tooltip-button-active-icon: $icon-warning;
$custom-tooltip-button-icon-color: 'primary';
$custom-tooltip-button-active-icon-color: 'accent-3';
$custom-tooltip-button-icon-font-size: rem-calc(20);
$custom-tooltip-button-active-icon-font-size: $custom-tooltip-button-icon-font-size;
$custom-tooltip-button-transition-speed: 'fast' !default;
$custom-tooltip-button-transition-timing: 'ease-out' !default;
$custom-tooltip-button-background: 'transparent';
$custom-tooltip-button-active-background: 'transparent';
$custom-tooltip-button-larger-clickable-target-psuedo-element: 'after';
$custom-tooltip-button-larger-clickable-target-sizes: (
    'small': rem-calc(50),
    'large': rem-calc(30)
) !default;
$custom-tooltip-border-color: 'neutral-darkest' !default;
$custom-tooltip-border-size: null !default;
$custom-tooltip-side: 'left' !default;
$custom-tooltip-align: 'center' !default;
$custom-tooltip-button-position-top: 50% !default;
$custom-tooltip-apply-button-larger-clickable-target-psuedo-element-styles: true !default;
$custom-tooltip-apply-button-position-styles: true !default;

// MEGAMENU
$megamenu-bottom-border-width: rem-calc(2);

// ACCORDION
$accordion-color: 'primary';
$accordion-border-color: 'neutral-light';
$accordion-border-width: (
    'small': rem-calc(0)
);
$accordion-title-padding: (
    'small': rem-calc(18 30 18 0)
);
$accordion-padding: (
    'small': rem-calc(0 0 18)
);
$accordion-title-hover-color-scale: 0%;
$accordion-title-text-align: left;
$theme-accordion-border-width: (
    'small': 1px
);
$accordion-icon-font-stack: 'icon';
$accordion-expand-icon: $icon-plus;
$accordion-collapse-icon: $icon-minus;

// RESPONSIVE ACCORDION
$responsive-accordion-destroy-viewport: 'large';

// PAGE HEADER
$page-header-border-color: 'neutral-lighter';
$page-header-promo-desktop-breakpoint: 'large';
$page-header-promo-close-button-height: (
    'small': rem-calc(35),
    $page-header-promo-desktop-breakpoint: 100%
);

$page-header-tablet-breakpoint: 'large';
$page-header-desktop-breakpoint: 'xlarge';
$page-header-height: (
    'small': rem-calc(53),
    $page-header-tablet-breakpoint: rem-calc(73),
    $page-header-desktop-breakpoint: rem-calc(92)
);
$page-header-nav-height: rem-calc(54);
$fixed-header-scroll-offset: (
    'small': -#{map-get($page-header-height, 'small')},
    $page-header-tablet-breakpoint: -#{map-get($page-header-height, $page-header-tablet-breakpoint)},
    $page-header-desktop-breakpoint: -#{map-get($page-header-height, $page-header-desktop-breakpoint) + $page-header-nav-height}
);
$page-header-button-height: (
    'small': rem-calc(35),
    $page-header-tablet-breakpoint: rem-calc(55),
    $page-header-desktop-breakpoint: rem-calc(30)
);
$page-header-button-width: (
    'small': rem-calc(35),
    $page-header-tablet-breakpoint: rem-calc(40),
    $page-header-desktop-breakpoint: rem-calc(30)
);

$page-header-search-button-width: rem-calc(45);

// PAGE FOOTER
$page-footer-breakpoint-tablet: 'medium';
$page-footer-breakpoint-desktop: 'xlarge';

$page-footer-top-background-color: 'neutral-lighter';
$page-footer-top-border-color: 'neutral-light';
$page-footer-top-color: 'primary';

$page-footer-background-color: null;
$page-footer-text-color: null;

$page-footer-main-background-color: null;
$page-footer-main-text-color: null;

$page-footer-additional-background-color: null;
$page-footer-additional-text-color: null;

$page-footer-main-spacing: 'medium';

$page-footer-newsletter-form-desktop-breakpoint: 'medium';
$page-footer-newsletter-form-input-height: rem-calc(46);

$page-footer-contact-section-tablet-breakpoint: 'medium';
$page-footer-contact-section-desktop-breakpoint: 'xlarge';

// GLOBAL MESSAGES
$message-padding: rem-calc(15);
$message-icon-spacing: rem-calc(8);
$message-spacing: rem-calc(15);

// PULLOUT MENU
$pullout-menu-x-axis-origin: 'left';
$pullout-menu-top: rem-calc(0);
$pullout-menu-width: rem-calc(320);
$pullout-menu-z-index: 'pullout-menu-content';
$pullout-menu-overlay-z-index: 'pullout-menu-overlay';
$pullout-menu-open-styles-display-value: block;
$pullout-menu-open-styles-display-duration: 'fast';
$pullout-menu-open-styles-direction: 'left';
$pullout-menu-backdrop-display-duration: 'slow';

// SCROLL
$scroll-bar-background-color: 'neutral-lightest';
$scroll-bar-thumb-color: 'neutral-light';
$scroll-bar-thumb-thickness: rem-calc(7);
$scroll-bar-default-overflow: auto;
$scroll-bar-default-side: 'both';

// MINICART
$minicart-background-color: 'neutral-white';
$minicart-side: 'right';
$minicart-action-color: 'secondary';
$minicart-section-padding: (
    'small': rem-calc(20)
);
$minicart-border-width: ('small': 1px);

$minicart-header-section-padding: (
    'small': rem-calc(0 0 0 15)
) !default;

$minicart-open-button-icon-font-size: 'minicart-icon';
$minicart-open-button-icon-color: 'neutral-white';
$minicart-open-button-background-color: 'primary';

$minicart-close-button-icon-color: 'neutral-white';
$minicart-close-button-icon-size: 'minicart-icon-close';
$minicart-close-button-background-color: 'primary';

$minicart-secondary-background-color: 'neutral-lighter';
$minicart-header-background-color: 'neutral-lightest';
$minicart-header-title-color: 'neutral-black';

$minicart-hr-divider-color: 'neutral-white';
$minicart-hr-divider-width: 1px;
$minicart-hr-divider-height: rem-calc(25);
$minicart-hr-divider-margin: rem-calc(0 10);

$minicart-actions-background-color: 'neutral-lightest';

$minicart-item-column-spacing: rem-calc(8);

$minicart-total-section-border-color: $global-border-color;
$minicart-grand-total-background-color: 'neutral-lighter';

// CHECKOUT SUMMARY
$checkout-summary-product-img-width: rem-calc(81);
$checkout-summary-image-margin: rem-calc(15);

// MENU
$menu-header-close-icon-width: rem-calc(50);
$menu-header-height: rem-calc(50);
$menu-item-horizontal-spacing: rem-calc(20);

// SLIDER
$slider-dot-size: rem-calc(10);
$slider-dot-spacing: rem-calc(8);
$slider-dot-bottom: (
    'small': rem-calc(20),
    'medium': rem-calc(30)
);

$slider-arrow-size: (
    'small': rem-calc(30),
    'medium': rem-calc(42),
    'xxlarge': rem-calc(50)
);

$hero-slider-arrow-size: (
    'small': map-safe-get($slider-arrow-size, 'small'),
    'medium': map-safe-get($slider-arrow-size, 'medium'),
    'large': map-safe-get($slider-arrow-size, 'xxlarge')
);

// HOMEPAGE
$homepage-content-spacing: 'medium';

$featured-items-layout: (
    'small': column-width-percent(6),
    'medium': column-width-percent(4),
    'large': column-width-percent(2)
);

$featured-items-spacing: $global-margin;
$featured-items-size-map: (
    'small': rem-calc(120),
    'xlarge': rem-calc(160)
);

$homepage-content-spacing: 'medium';

$inline-banner-size: (
    'small': column-width-percent(12),
    'large': column-width-percent(6),
    'xlarge': column-width-percent(4)
);

$inline-banner-left-order: (
    'small': 0
);

$inline-banner-right-order: (
    'small': 0,
    'large': 1
);

$category-image-section-layout: (
    'small': column-width-percent(12),
    'medium': column-width-percent(10),
    'large': column-width-percent(12)
);
$category-image-list-layout: (
    'small': column-width-percent(12),
    'large': column-width-percent(4)
);

// REVIEW
$rating-icon: $icon-star-on;
$rating-icon-outline: $icon-star-off;
$rating-icon-font-family: $font-family-icon;
$rating-hover-color: 'accent-1';
$rating-font-size: 'h2';
$rating-background-rating-color: 'neutral-light';

$responsive-review-content-sizes: (
    'small': 100%,
    'large': column-width-percent(8),
    'xlarge': column-width-percent(9)
);
$responsive-review-details-sizes: (
    'small': 100%,
    'large': column-width-percent(4),
    'xlarge': column-width-percent(3)
);

// PRODUCT CARDS
$product-grid-item-hover-box-shadow: none;
$product-grid-item-hover-product-name-color: 'primary';

$product-grid-layout: (
    'small': column-width-percent(6),
    'large': column-width-percent(4),
    'xlarge': column-width-percent(3)
);
$product-grid-gutters: (
    'small': rem-calc(0),
    'medium': rem-calc(30)
);

$product-grid-item-section-border-sides: null;
$product-grid-item-section-padding-sides: ('all');
$product-grid-item-section-spacing-size: 'xsmall';
$product-grid-item-section-background-color: null;

$product-grid-item-section-divider: true;

// TOTALS
$subtotals-background-color: 'neutral-lighter';
$subtotal-label-font-size: 'price-medium';
$subtotal-text-color: 'neutral-black';
$grand-total-background-color: 'neutral-dark';
$grand-total-text-color: 'primary-dark';

// DROPDOWN
$dropdown-button-icon-spacing: rem-calc(5);
$dropdown-box-shadow-opacity: 0.2;
$dropdown-box-shadow-color: rgba(get-color('neutral-black'), $dropdown-box-shadow-opacity);

// CMS REFERENCE
$cms-responsive-video-padding: ((9 / 16) * 100%);
$cms-caption-font-size: 'small-copy';
$cms-caption-font-color: 'primary';
$cms-split-section-spacing: rem-calc(30);

// LAYERED NAV
$layered-navigation-mobile-desktop-breakpoint: 'large';
$layered-navigation-mobile-button-gutter-width: map-safe-get(get-spacing('gutter'), 'small');

// PDP PAGE
$pdp-add-to-cart-height: rem-calc(46);
$pdp-quantity-mobile-breakpoint: 'medium';
$pdp-layout-desktop-breakpoint: 'large';

// TRUST ELEMENTS
$trust-icon-payment-icon-width: rem-calc(50);

// BOX
$box-padding: get-spacing('box');
$box-body-background-color: 'neutral-white';
$box-body-text-color: 'neutral-dark';
$box-header-background-color: 'neutral-white';
$box-header-text-color: 'primary';
$box-footer-background-color: 'neutral-lightest';
$box-footer-text-color: 'primary';

// BRANDS PAGE
$brand-grid-item-flex-size-map: (
    'small': column-width-percent(6),
    'medium': column-width-percent(4),
    'xlarge': column-width-percent(2)
);

// SWATCH PICKER
$swatch-picker-desktop-breakpoint: 'large';
$swatch-picker-widths: (
    'small': 80%,
    $swatch-picker-desktop-breakpoint: 100%
);
$swatch-picker-preview-size: (
    'small': rem-calc(350),
    'xlarge': rem-calc(500)
);
$swatch-picker-preview-gutter: rem-calc(20);
