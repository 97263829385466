@mixin apply-contact-form-styles() {
    .contact-form {
        @include spacing('large', 'bottom');

        .action.submit {
            width: 100%;

            @include breakpoint('large') {
                width: auto;
                padding-right: rem-calc(30);
                padding-left: rem-calc(30);
            }
        }

        .contact-form-row {
            @include grid-row($form-margin);

            .field {
                $responsive-sizes: (
                    'small': 100%,
                    'medium': 50%
                );
                @include grid-column($responsive-sizes);
            }
        }
    }
}
