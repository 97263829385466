@mixin typography-copy(
    $color,
    $font-size: $body-font-size,
    $line-height: null,
    $font-stack: null
) {
    @include font($font-stack);
    @include font-size($font-size);
    color: get-color($color);

    @if $line-height {
        @each $breakpoint, $line-height in get-line-height($line-height) {
            @include breakpoint($breakpoint) {
                line-height: $line-height;
            }
        }
    }
}

@mixin small-copy() {
    @include typography-copy(
        $color: $small-copy-color,
        $font-size: 'small-copy',
        $line-height: 'small-copy'
    );
}

@mixin tiny-copy() {
    @include typography-copy(
        $color: $tiny-copy-color,
        $font-size: 'tiny-copy',
        $line-height: 'tiny-copy'
    );
}

@mixin blockquote() {
    @include typography-copy(
        $color: 'primary',
        $font-size: 'blockquote', //@TODO Use context?
        $line-height: 'blockquote', //@TODO Use context?
        $font-stack: 'secondary'
    );
    position: relative;
    border: 0;
    padding-left: rem-calc(60);
    font-style: italic;

    &::before {
        position: absolute;
        top: rem-calc(16);
        left: 0;
        content: url('../images/blockquotequote-icon.svg');
    }

    .author {
        margin-top: rem-calc(10);
    }

    .author,
    .author a {
        @include typography-copy(
            $color: 'neutral-dark',
            $font-size: 'h4', //@TODO Use context?
            $line-height: 'h4', //@TODO Use context?
            $font-stack: 'primary'
        );
        font-style: normal;
    }
}
