@mixin theme-modal-global-apply() {
    @at-root {
        body {
            @include theme-modal-body-modal-open();
        }

        .modal {
            @include theme-modal();
            @include theme-modal-magento-styles-apply();
        }

        .modal-overlay {
            @include theme-modal-overlay();
        }

        .modal-heading-container {
            @include theme-modal-heading-container();
        }

        .modal-heading {
            @include theme-modal-heading();
        }

        .modal-subheading {
            @include theme-modal-subheading();
        }

        .modal-close-button {
            @include theme-modal-close-button();
        }
    }
}

@mixin theme-modal(
    $background-color: 'neutral-white',
    $font-color: $theme-modal-copy-color,
    $width: $modal-width,
    $fullscreen: false,
    $max-width: $modal-max-width,
    $spacing-name: $theme-modal-spacing-size
) {
    @include modal(
        $width: $width,
        $fullscreen: $fullscreen
    );
    @if $fullscreen {
        padding: 0;
    } @else {
        @include spacing(
            $spacing-name: $spacing-name,
            $padding-sides: ('all')
        );
        @include dropdown-shadow();
    }
    position: relative;
    background-color: get-color($background-color);
    color: get-color($font-color);

    @if $fullscreen {
        &.is-open {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@mixin apply-theme-fullscreen-modal-styles($background-color: 'neutral-white') {
    @include theme-modal(
        $background-color: $background-color,
        $fullscreen: true
    );

    .modal-close-button {
        @include theme-modal-close-button();
        background-color: get-color($background-color);
    }
}

@mixin theme-modal-overlay() {
    @include modal-overlay();
    -webkit-overflow-scrolling: touch;

    @at-root {
        body.is-body-modal-open & {
            overflow: hidden;
        }
    }
}

@mixin theme-modal-heading-container() {
    @include typography-margin('p', 0.5);

    // prevent button and text from overlapping
    @each $breakpoint, $size in get-spacing($theme-modal-spacing-size) {
        @include breakpoint($breakpoint) {
            padding-right: ($theme-modal-close-button-size - $size) + rem-calc(10);
        }
    }
}

@mixin theme-modal-heading() {
    @include typography-heading('h1');
}

@mixin theme-modal-subheading(
    $color: $theme-modal-copy-color
) {
    @include subheading-medium();
}

@mixin theme-modal-close-button(
    $button-size: $theme-modal-close-button-size
) {
    @include reset-default-button-styles();
    @include icon($icon: $icon-close);
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 0;
    width: $button-size;
    height: $button-size;
    text-align: center;
    line-height: 1;
    color: get-color('primary');
    font-size: rem-calc(20);

    &:hover,
    &:focus {
        color: get-color('accent-3');
    }

    span {
        @include visually-hidden();
    }
}

@mixin theme-modal-body-modal-open() {
    @include body-modal-open();
}

@mixin theme-modal-magento-styles-apply() {
    .action-primary {
        @include primary-button();
    }

    .action-secondary {
        @include secondary-button();
    }

    .action-close {
        @include theme-modal-close-button();
    }

    .modal-footer {
        @include spacing(
            $spacing-name: 'gutter',
            $margin-sides: ('top'),
            $ratio: 0.5
        );
    }
}
