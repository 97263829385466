$styleguide-base-color-range: ('', 'dark');
$styleguide-full-color-range: ('', 'light', 'dark');

@mixin styleguide-color-box($context, $range-map: $styleguide-base-color-range) {
    @each $range in $range-map {
        @if ($range == '') {
            .box {
                background-color: get-color('#{$context}');
            }
        } @else {
            &.#{$range} {
                .box {
                    background-color: get-color('#{$context}-#{$range}');
                }
            }
        }
    }
}

@mixin styleguide-color-box-neutral($context) {
    &.neutral-black {
        .box {
            background-color: get-color('#{$context}-black');
        }
    }

    &.neutral-grey-darkest {
        .box {
            background-color: get-color('#{$context}-darkest');
        }
    }

    &.neutral-grey-darker {
        .box {
            background-color: get-color('#{$context}-darker');
        }
    }

    &.neutral-grey-dark {
        .box {
            background-color: get-color('#{$context}-dark');
        }
    }

    &.neutral-grey-light {
        .box {
            background-color: get-color('#{$context}-light');
        }
    }

    &.neutral-grey-lighter {
        .box {
            background-color: get-color('#{$context}-lighter');
        }
    }

    &.neutral-grey-lightest {
        .box {
            background-color: get-color('#{$context}-lightest');
        }
    }

    &.neutral-white {
        .box {
            background-color: get-color('#{$context}-white');
        }
    }
}

@mixin styleguide-color-box-global-message($context, $color-range) {
    @include styleguide-color-box($context, $color-range);
}

@mixin styleguide-colors() {
    .color-section {
        &.primary {
            .color-box {
                @include styleguide-color-box('primary');
            }
        }

        &.secondary {
            .color-box {
                @include styleguide-color-box('secondary');
            }
        }

        &.accent-1 {
            .color-box {
                @include styleguide-color-box('accent-1');
            }
        }

        &.accent-2 {
            .color-box {
                @include styleguide-color-box('accent-2');
            }
        }

        &.accent-3 {
            .color-box {
                @include styleguide-color-box('accent-3');
            }
        }

        &.neutral {
            .color-box {
                @include styleguide-color-box-neutral('neutral');
            }
        }

        &.success {
            .color-box {
                @include styleguide-color-box-global-message('success', $styleguide-full-color-range);
            }
        }

        &.information {
            .color-box {
                @include styleguide-color-box-global-message('information', $styleguide-full-color-range);
            }
        }

        &.warning {
            .color-box {
                @include styleguide-color-box-global-message('warning',  $styleguide-full-color-range);
            }
        }

        &.error {
            .color-box {
                @include styleguide-color-box-global-message('error',  $styleguide-full-color-range);
            }
        }

        .box {
            display: block;
            max-width: rem-calc(230);
            height: rem-calc(125);
        }
    }
}
