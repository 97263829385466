@mixin apply-base-dropdown-content-styles() {
    .dropdown-content {
        @include spacing('gutter', $padding-sides: ('all'));
    }
}

@mixin apply-base-dropdown-list-styles() {
    @include unstyled-list();
    margin: -(map-safe-get(get-typography-margin('dropdown-li'), 'small') / 2) 0;

    li {
        @include font-size('small-copy');
        margin: 0;
    }

    a {
        display: block;
        padding: (map-safe-get(get-typography-margin('dropdown-li'), 'small') / 2) 0;
    }
}

@mixin apply-base-dropdown-styles() {
    @include dropdown($include-triangle: false, $include-padding: false);
    @include apply-base-dropdown-content-styles();
    @include dropdown-shadow();
    z-index: get-z-index('above-sibling');
}

@mixin dropdown-shadow() {
    box-shadow: 0 rem-calc(5) rem-calc(15) 0 $dropdown-box-shadow-color;
}

@mixin dropdown-link-arrow-icon-styles($use-before: true) {
    @include icon($icon: $icon-chevron-down, $use-before: $use-before) {
        margin-left: $dropdown-button-icon-spacing;
        vertical-align: 0;
        font-size: rem-calc(11);
        @content;
    }
    text-decoration: none;
}

@mixin dropdown-link-arrow-active-icon-styles($use-before: true) {
    @include icon($icon: $icon-chevron-up, $use-before: $use-before) {
        vertical-align: 0;
        @content;
    }
}

@mixin apply-base-dropdown-anchor-styles(
    $reset-button: true,
    $arrow-inside: true,
    $font-size: 'small-copy',
    $display-mode: inline-block,
    $color: 'primary',
    $icon-spacing: $dropdown-button-icon-spacing
) {
    .dropdown-button {
        @if $reset-button {
            @include reset-default-button-styles();
        }
        @include font-size($font-size);
        display: $display-mode;
        color: get-color($color);

        .icon-image {
            @include icon-selector() {
                @include font-size($font-size);
                margin-right: $icon-spacing;
            }
        }

        @if $arrow-inside {
            .icon-indicator {
                @include dropdown-link-arrow-icon-styles();
            }
        }

        &[aria-expanded='true'] {
            .icon-indicator {
                @include dropdown-link-arrow-active-icon-styles();
            }

            @if $arrow-inside == false {
                + .icon-indicator {
                    @include dropdown-link-arrow-icon-styles();
                }
            }
        }
    }

    @if $arrow-inside == false {
        .icon-indicator {
            @include dropdown-link-arrow-icon-styles();
        }
    }
}
