@mixin styleguide-typography() {
    .typography-row {
        @include styleguide-typography-headings();
        @include styleguide-typography-copy();
        @include styleguide-typography-links();
        @include styleguide-typography-lists();
    }

    .product-row {
        @include styleguide-typography-product-name();
    }

    .price-row {
        @include styleguide-typography-price();
    }

    blockquote {
        @include styleguide-blockquote();
    }
}

@mixin styleguide-typography-headings() {
    h1 {
        @include theme-typography-heading('h1');
    }

    h2 {
        @include theme-typography-heading('h2');
    }

    h3 {
        @include theme-typography-heading('h3');
    }

    h4 {
        @include theme-typography-heading('h4');
    }

    h5 {
        @include theme-typography-heading('h5');
    }

    h6 {
        @include theme-typography-heading('h6');
    }

    .subheading-large {
        @include subheading-large();
    }

    .subheading-medium {
        @include subheading-medium();
    }

    .subheading-small {
        @include subheading-small();
    }
}

@mixin styleguide-typography-links() {
    a {
        @include typography-anchor();
    }

    .hyperlink-small {
        @include typography-anchor($font-size: 'hyperlink-small');
    }
}

@mixin styleguide-typography-lists() {
    ul {
        @include unordered-list();
    }

    ol {
        @include ordered-list();
    }
}

@mixin styleguide-typography-copy() {
    .small-copy {
        @include small-copy();
    }

    .tiny-copy {
        @include tiny-copy();
    }
}

@mixin styleguide-typography-product-name() {
    @each $size in $styleguide-product-name-sizes {
        .product-name-#{$size} {
            .product-item-name {
                @include product-name('product-name-#{$size}');
            }
        }
    }
}

@mixin styleguide-typography-price() {
    @each $size in $styleguide-price-sizes {
        .price-#{$size} {
            &.price-box {
                @include price('price-#{$size}');
            }
        }

        .special-price-#{$size} {
            &.price-box {
                @include price('price-#{$size}');
            }

            .special-price {
                @include special-price('price-#{$size}');
            }

            .old-price {
                @include old-price('price-#{$size}');
            }
        }
    }
}

@mixin styleguide-blockquote() {
    @include blockquote();
}
