@mixin apply-megamenu-dropdown-list-styles($negate-parent-menu-padding: true) {
    @include border($sides: ('top'));
    margin-top: rem-calc(20);
    padding-top: rem-calc(10);

    @if $negate-parent-menu-padding {
        margin-bottom: rem-calc(-20);
    }

    a {
        @include icon($icon: $icon-chevron-right, $use-before: false) {
            @include font-size('normal');
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: get-font-weight('semi-bold');
    }
}

@mixin apply-megamenu-special-link-styles() {
    .special-link {
        a {
            color: get-color('secondary');

            &:hover,
            &:focus {
                color: get-color('secondary-dark');
            }
        }
    }
}

@mixin apply-megamenu-styles() {
    @include apply-base-dropdown-anchor-styles($arrow-inside: false);
    @include apply-megamenu-special-link-styles();
    margin-bottom: $megamenu-bottom-border-width * -1;
    box-sizing: content-box;

    .megamenu-link-container {
        position: relative;

        &:hover {
            .megamenu-expand-button {
                @include border($width: ('small': $megamenu-bottom-border-width), $color: 'primary', $sides: ('bottom'));
            }
        }
    }

    .megamenu-level-0-link,
    .megamenu-level-0-text {
        @include font-size('megamenu-anchor');
        font-weight: get-font-weight('semi-bold');

        &.no-dropdown {
            @include spacing('xsmall', $padding-sides: ('all'));
            @include border($width: ('small': $megamenu-bottom-border-width), $color: 'transparent', $sides: ('bottom'));

            &:hover {
                @include border($width: ('small': $megamenu-bottom-border-width), $color: 'primary', $sides: ('bottom'));
                color: get-color('primary');
            }
        }
    }

    .megamenu-level-0-text {
        display: inline-block;
        color: get-color('primary');
    }

    .megamenu-expand-button {
        @include reset-default-button-styles();
        @include border($width: ('small': $megamenu-bottom-border-width), $color: 'transparent', $sides: ('bottom'));
        z-index: get-z-index('neutral-sibling');

        .megamenu-text {
            @include spacing('xsmall', $margin-sides: ('top', 'bottom', 'left'));
            position: relative;
            z-index: get-z-index('above-sibling');
        }

        .icon-indicator {
            @include spacing('xsmall', $padding-sides: ('right'));
        }

        &[aria-expanded='true'] {
            @include border($width: ('small': $megamenu-bottom-border-width), $color: 'primary', $sides: ('bottom'));

            .icon-indicator {
                @include dropdown-link-arrow-active-icon-styles();
            }
        }
    }

    > ul {
        @include unstyled-list();
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }

    .megamenu-level-1-list {
        @include apply-base-dropdown-styles();

        @include breakpoint($page-header-desktop-breakpoint) {
            max-height: calc(100vh - (#{map-safe-get($page-header-height, $page-header-desktop-breakpoint)} + #{rem-calc(55)}));
            overflow: auto;
        }

        ul {
            @include apply-base-dropdown-list-styles();

            .shop-all-category-name {
                @include visually-hidden();
            }

            &.megamenu-additional-links {
                @include apply-megamenu-dropdown-list-styles();
            }
        }
    }
}

@mixin apply-theme-megamenu-styles() {
    .page-header-menu {
        @include border($sides: ('top'));
        display: none;
        position: relative;
        z-index: get-z-index('below-sibling');

        @include breakpoint('xlarge') {
            display: block;
        }
    }

    .page-header-menu-content {
        @include padded-max-width-container();
    }

    .barstools-megamenu {
        @include apply-megamenu-styles();
    }
}
