@mixin apply-cart-image-zoom-styles() {
    .cart-product-photo-modal-open-button {
        @include reset-default-button-styles();
        @include button-icon($icon: $icon-zoom-in) {
            font-size: rem-calc(13);

            @include breakpoint($cart-image-zoom-breakpoint) {
                font-size: rem-calc(22);
            }
        }
        margin-top: rem-calc(10);
        width: 100%;
        text-align: center;
        color: get-color('primary');
        line-height: 1;
        white-space: nowrap;

        @include breakpoint($cart-image-zoom-breakpoint) {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 0;
            background-color: get-color('neutral-white');
            padding: rem-calc(5);
            width: auto;
        }

        &:hover,
        &:focus {
            color: get-color('secondary');
        }
    }

    .cart-product-photo-modal-open-button-text {
        @include font-size('small-copy');

        @include breakpoint($cart-image-zoom-breakpoint) {
            @include visually-hidden();
        }
    }

    @at-root {
        .cart-product-photo-modal {
            @include apply-theme-fullscreen-modal-styles();
        }
    }
}
