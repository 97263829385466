$icon-account: '\e900';
$icon-alert: '\e901';
$icon-bookmark: '\e902';
$icon-calendar: '\e903';
$icon-cart: '\e904';
$icon-checkbox-off: '\e905';
$icon-checkbox-on: '\e906';
$icon-checkmark-strong: '\e907';
$icon-chevron-down: '\e908';
$icon-chevron-left: '\e909';
$icon-chevron-right: '\e90a';
$icon-chevron-up: '\e90b';
$icon-clock: '\e90c';
$icon-close: '\e90d';
$icon-edit: '\e90e';
$icon-email: '\e90f';
$icon-expand: '\e910';
$icon-facebook: '\e911';
$icon-gift: '\e912';
$icon-google-plus: '\e913';
$icon-heart-off: '\e914';
$icon-heart-on: '\e915';
$icon-hours: '\e916';
$icon-in-stock: '\e917';
$icon-info: '\e918';
$icon-instagram: '\e919';
$icon-linked-in: '\e91a';
$icon-location-pin: '\e91b';
$icon-lock: '\e91c';
$icon-loop: '\e91d';
$icon-menu: '\e91e';
$icon-minus: '\e91f';
$icon-next: '\e920';
$icon-out-of-stock: '\e921';
$icon-pause: '\e922';
$icon-phone: '\e923';
$icon-pinterest: '\e924';
$icon-play: '\e925';
$icon-plus: '\e926';
$icon-previous: '\e927';
$icon-print: '\e928';
$icon-question-mark: '\e929';
$icon-quote: '\e92a';
$icon-radio-off: '\e92b';
$icon-radio-on: '\e92c';
$icon-refresh: '\e92d';
$icon-return: '\e92e';
$icon-search: '\e92f';
$icon-share: '\e930';
$icon-star-off: '\e931';
$icon-star-on: '\e932';
$icon-stop: '\e933';
$icon-success: '\e934';
$icon-tag: '\e935';
$icon-trash: '\e936';
$icon-triangle-down: '\e937';
$icon-triangle-left: '\e938';
$icon-triangle-right: '\e939';
$icon-triangle-up: '\e93a';
$icon-truck: '\e93b';
$icon-tumblr: '\e93c';
$icon-twitter: '\e93d';
$icon-vimeo: '\e93e';
$icon-warning: '\e93f';
$icon-wishlist-private: '\e940';
$icon-wishlist-public: '\e941';
$icon-youtube: '\e942';
$icon-zoom-in: '\e943';
$icon-zoom-out: '\e944';
$icon-box: '\e945';
$icon-ribbon: '\e946';
$icon-shield: '\e947';
$icon-truck1: '\e948';
