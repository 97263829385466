@mixin apply-page-content-padding(
    $sides: ('top', 'bottom'),
    $min-size: null,
    $max-size: null,
    $ratio: 1
) {
    @include spacing(
        $spacing-name: 'medium',
        $padding-sides: $sides,
        $min-size: $min-size,
        $max-size: $max-size,
        $ratio: $ratio
    );
}

@mixin reset-page-content-padding($sides: ('all')) {
    @each $side in $sides {
        @if $side == 'all' {
            padding-top: 0;
            padding-bottom: 0;
        } @else {
            padding-#{$side}: 0;
        }
    }
}

@mixin apply-global-spacing() {
    body {
        &:not(.page-layout-empty-full-width) {
            .content-main {
                @include padded-max-width-container();
            }
        }
    }

    .content-columns {
        @include grid-row();
    }

    .main-content-panel {
        @include content-grid-main-content-panel-styles();
    }

    .left-content-panel {
        @include content-grid-left-content-panel-styles();
    }

    .right-content-panel {
        @include content-grid-right-content-panel-styles();
    }
}
