@mixin apply-form-styles() {
    .form {
        @include form-styles();
    }
}

@mixin form-styles() {
    @include kindling-form();
    @include mage-error();

    :disabled {
        .form-label-text {
            color: get-color($input-color-disabled);
        }
    }

    .password-field {
        @include password-input-styles();
    }

    .field {
        @include form-field();

        &.year {
            width: rem-calc(150);
        }
    }

    .form-label {
        @include kindling-sneaky-labels();
        z-index: get-z-index('neutral-sibling'); // prevent z-index issues by create new stacking context for sneaky labels

        &.is-empty {
            .form-label-text {
                text-transform: none;
            }
        }
    }

    .legend {
        @include form-legend();
    }

    .heading {
        @include legend-heading();
    }

    .description {
        @include legend-description();
    }

    [type='submit'] {
        @include button('primary');
    }
}

@mixin reset-last-form-field-margin() {
    .field {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
