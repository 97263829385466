@mixin apply-customer-account-boxes {
    .box {
        @include theme-box-container();
    }

    .box-content {
        @include theme-box-body();
    }

    .box-title {
        @include theme-box-header();
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        + .box-content {
            padding-top: 0;
        }
    }

    .box-footer {
        @include theme-box-footer();
    }
}
