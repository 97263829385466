@mixin apply-northern-checkout-loading-overlay-styles() {
    .checkout-loading-overlay {
        @include overlay($z-index: $checkout-loader-overlay-z-index);
        display: none;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: $checkout-loader-overlay-spinner-size;

        &.checkout-loader-active {
            display: block;
        }
    }
}
