@mixin styleguide() {
    .styleguide-container {
        @include styleguide-colors();
        @include styleguide-buttons();
        @include styleguide-table();
        @include styleguide-table-alternate();
        @include styleguide-responsive-table-styles();
        @include styleguide-typography();
        @include styleguide-accordion-apply();
        @include styleguide-responsive-accordion-apply();
        @include styleguide-slider-apply();
        @include styleguide-dropdown-apply();
        @include styleguide-box-apply();
        @include styleguide-swatch-picker-styles();
        @include styleguide-tooltip-styles();

        .styleguide-grid-row {
            margin: rem-calc(50 0);
        }

        .products-grid {
            @include product-grid();
            @include product-cards();
        }
    }
}
