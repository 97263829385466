@mixin apply-order-success-sidebar-styles() {
    .order-success-sidebar-heading {
        @include heading-styles-h2();
    }

    .order-success-sidebar-content {
        @include spacing($checkout-success-sidebar-title-spacing, $margin-sides: ('top'));
        @include theme-accordion-container();

        .order-success-sidebar-item {
            @include theme-accordion-item();
            @include border($theme-accordion-border-width, $accordion-border-style, $accordion-border-color, ('all'), true);

            .payment-method {
                .content {
                    .table-caption {
                        display: none;
                    }
                }
            }
        }

        button {
            @include theme-accordion-item-title(
                $text-color: $checkout-success-sidebar-title-color,
                $padding: $checkout-success-sidebar-accordion-title-padding
            );

            &::before,
            &[aria-expanded='true']::before {
                @include property('right', get-spacing('checkout-sidebar'));
            }
        }

        dt {
            font-weight: get-font-weight('normal');
        }

        .order-success-sidebar-body {
            @include theme-accordion-item-body(
                $padding: $checkout-success-sidebar-accordion-body-padding,
                $text-color: $checkout-success-sidebar-body-color
            );
            @include font-size($checkout-success-sidebar-body-font-size);

            .payment-method {
                margin-left: 0;
                text-align: left;
            }
        }
    }

    @include apply-checkout-sidebar-totals-styles();

    .totals-table-wrapper {
        @include border($sides: ('left', 'bottom', 'right'));
    }
}
