$image-404-sizes: (
    'small': rem-calc(140),
    'large': rem-calc(380)
);
$content-404-sizes: (
    'small': column-width-percent(12),
    'large': column-width-percent(6)
);

@mixin apply-404-page-styles() {
    .error-page-container {
        @include spacing('large', $padding-sides: ('top', 'bottom'));
        @include max-width-container-columns-width(('small': 12, 'medium': 8, 'large': 12, 'xlarge': 10));
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .error-page-image-wrapper {
        flex: 1 1 100%;
        margin-bottom: map-safe-get(get-spacing('gutter'), 'large');
        text-align: center;

        @include breakpoint('large') {
            flex: 0 0 auto;
            margin-bottom: 0;
            width: map-safe-get($image-404-sizes, 'large');
        }

        @include breakpoint('xlarge') {
            flex: 0 0 auto;
            margin-bottom: 0;
            width: map-safe-get($content-404-sizes, 'large');
        }
    }

    .error-page-image {
        @include property('width', $image-404-sizes);
    }

    .error-page-content {
        @include grid-column($content-404-sizes, 0, 0);
    }

    .error-page-subtitle {
        @include subheading-large();
        display: block;
        margin-bottom: rem-calc(5);
    }

    .error-page-title {
        @include theme-typography-heading('h1');
        margin-bottom: map-safe-get(get-spacing('gutter'), 'small');
    }

    .error-page-text {
        margin-bottom: map-safe-get(get-spacing('gutter'), 'large');
    }

    .error-page-anchor {
        @include button('primary');
        width: 100%;

        @include breakpoint('large') {
            width: auto;
        }
    }
}
