@mixin search-autocomplete() {
    .search-autocomplete {
        @include dropdown-shadow();
        z-index: get-z-index('above-sibling');
        background: get-color('neutral-white');

        ul {
            @include apply-base-dropdown-list-styles();
            margin: 0;

            li {
                &.selected {
                    a {
                        color: get-color('secondary');
                    }
                }
            }

            a {
                padding-right: (map-safe-get(get-typography-margin('dropdown-li'), 'small') / 2);
                padding-left: (map-safe-get(get-typography-margin('dropdown-li'), 'small') / 2);
            }
        }
    }
}
