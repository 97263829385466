@mixin sales-order-view-header-actions {
    @include spacing($spacing-name: 'xsmall', $margin-sides: ('top', 'bottom'), $ratio: .5);
    flex-grow: 1;

    .actions {
        width: 100%;

        @include breakpoint('large') {
            text-align: right;
        }

        .action.print {
            @include button-with-icon($icon: $icon-print, $type: 'tertiary', $size: 'small');
            display: inline-block;
        }

        .action.order {
            @include button-with-icon($icon: $icon-refresh, $type: 'tertiary', $size: 'small');
            display: inline-block;

            @include breakpoint('large') {
                @include spacing($spacing-name: 'gutter', $margin-sides: ('left'), $ratio: 0.5);
            }
        }
    }
}

@mixin apply-sales-order-view-page-title-content-styles() {
    .page-title-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .order-actions-toolbar {
            @include sales-order-view-header-actions();
        }

        .order-date {
            width: 100%;
        }

        .order-status {
            @include set-background-color('primary');
            @include font-size('tiny-copy');
            padding: rem-calc(4);
            text-transform: uppercase;
        }
    }
}
