@mixin font(
    $font-stack: $font-primary-stack,
    $font-weight: $font-primary-weight
) {
    font-family: get-font-stack($font-stack);
    font-weight: get-font-weight($font-weight);
}

@mixin font-primary($font-weight: $font-primary-weight) {
    @include font(
        $font-stack: $font-primary-stack,
        $font-weight: $font-weight
    );
}

@mixin font-secondary($font-weight: $font-secondary-weight) {
    @include font(
        $font-stack: 'secondary',
        $font-weight: $font-weight
    );
}
