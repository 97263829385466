$content-grid-main-content-panel-responsive-sizes: (
    'small': 100%,
    'large': 40%
) !default;
$content-grid-left-content-panel-responsive-sizes: (
    'small': 100%,
    'large': 33.3%,
) !default;
$content-grid-right-content-panel-responsive-sizes: (
    'small': 100%,
    'large': 33.3%,
) !default;
$max-width-container-column-count: (
    'small': 12
) !default;
$main-content-max-width-container-column-count: (
    'small': 12,
    'large': 10
) !default;
$current-grid-id: 0;

@mixin content-grid-main-content-panel-styles($breakpoint: $content-grid-main-content-panel-responsive-sizes) {
    @include grid-column($breakpoint);
}

@mixin content-grid-left-content-panel-styles($breakpoint: $content-grid-left-content-panel-responsive-sizes) {
    @include grid-column($breakpoint, $flex-grow: 0);
}

@mixin content-grid-right-content-panel-styles($breakpoint: $content-grid-right-content-panel-responsive-sizes) {
    @include grid-column($breakpoint, $flex-grow: 0);
}

@mixin max-width-container-columns-width(
    $column-count: $max-width-container-column-count,
    $center-element: true
) {
    @if $center-element {
        margin-right: auto;
        margin-left: auto;
    }
    @each $breakpoint, $column in $column-count {
        @include breakpoint($breakpoint) {
            width: calc(100% * (#{$column} / 12));
        }
    }
}

@mixin max-width-container(
    $max-width: $global-max-width,
    $column-count: $max-width-container-column-count
) {
    @include max-width-container-columns-width($column-count);
    $keys: map-keys($column-count);
    $last-key-name: nth($keys, if(length($keys) < 1, 1, length($keys)));
    max-width: calc(#{map-safe-get($column-count, $last-key-name)} * (#{$max-width} / 12));
}

@mixin width-container-column-padding(
    $padding-sides: ('left', 'right')
) {
    @include spacing(
        $spacing-name: 'gutter',
        $padding-sides: $padding-sides,
        $max-size: 'small'
    );
    @include spacing(
        $spacing-name: 'gutter',
        $padding-sides: $padding-sides,
        $min-size: 'large',
        $ratio: 2
    );
}

@mixin padded-max-width-container(
    $max-width: $global-max-width,
    $column-count: $max-width-container-column-count
) {
    @include max-width-container($max-width, $column-count);
    @include width-container-column-padding();
}

@mixin main-content-padded-max-width-container(
    $max-width: $global-max-width,
    $column-count: $main-content-max-width-container-column-count
) {
    @include padded-max-width-container($max-width, $column-count);
}

@mixin main-content-max-width-container(
    $max-width: $global-max-width,
    $column-count: $main-content-max-width-container-column-count
) {
    @include max-width-container($max-width, $column-count);
}

// This mixin fixes the issues where images incorrectly grow when the child of an element with flex-direction: column. Apply to the element with flex-direction to fix the issue.
@mixin apply-flex-direction-column-ie-fix() {
    > * {
        &:not(:empty) {
            min-height: 1px; // sass-lint:disable-line property-units
        }
    }
}

// Create unique grid area name so autoprefixer can create styles which work in IE 11
//
// The returned value will have to be stored in a local variable within
// the current mixin since this never returns the same value
@function grid-area-name($name) {
    $current-grid-id: $current-grid-id + 1 !global;
    @return unquote('#{$name}-#{$current-grid-id}');
}
