@mixin apply-minicart-styles() {
    .minicart-wrapper {
        position: relative;
    }

    .minicart {
        @include pullout-menu-styles($side: $minicart-side);
        background-color: get-color($minicart-background-color);
    }

    .minicart-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
    }

    .minicart-backdrop {
        @include pullout-menu-backdrop();
        display: block;
    }

    .minicart-open-button {
        @include header-main-button();
        margin-right: 0;
    }

    .minicart-open-button-icon {
        @include icon($icon: $icon-cart) {
            line-height: 1;
            font-size: rem-calc(22);

            @include breakpoint($page-header-tablet-breakpoint) {
                font-size: rem-calc(26);
            }
        }
        display: inline-block; // fix IE 11 bug: unexpected count indicator position
        position: relative;
    }

    .minicart-open-button-count {
        display: block;
        position: absolute;
        top: rem-calc(0);
        right: rem-calc(-14);
        border-radius: 50%;
        background-color: get-color('secondary');
        padding: rem-calc(0 5);
        min-width: rem-calc(18);
        line-height: rem-calc(18);
        color: get-color('white');
        font-size: rem-calc(10);

        @include breakpoint($page-header-tablet-breakpoint) {
            top: rem-calc(-2);
        }
    }

    .minicart-tooltip {
        @include custom-tooltip-styles(
            $side: 'bottom',
            $align: 'right'
        );
        @include fade-in-out-hidden-state($duration: 'slow');
        top: calc(100% + #{rem-calc(10)});
        right: rem-calc(-13);
        width: rem-calc(220);

        &.is-active {
            @include fade-in-out-active-state($duration: 'slow');
        }


        .tooltip-text {
            font-size: inherit;
        }
    }

    .minicart-header,
    .minicart-actions,
    .minicart-summary {
        display: flex;
        flex: 0 0 auto;
    }

    .minicart-header {
        @include menu-header();
    }

    .minicart-header-title {
        @include menu-header-title();
        text-transform: none;
        font-weight: get-font-weight('normal');
    }

    .minicart-header-item-count {
        margin-left: rem-calc(5);
        font-size: rem-calc(13);

        &::before {
            content: '-';
        }
    }

    .minicart-close-button {
        @include menu-header-close-button();
    }

    .minicart-actions {
        @include checkout-sidebar-section-padding($padding-size: 'minicart');
        @include border($sides: ('bottom'));
        background-color: get-color($minicart-actions-background-color);
        justify-content: space-between;
    }

    .minicart-action-cart,
    .minicart-action-checkout {
        @each $breakpoint, $padding in $minicart-section-padding {
            @include breakpoint($breakpoint) {
                width: calc(50% - (#{get-side($padding, 'left')} / 4));
            }
        }
    }

    .minicart-action-cart {
        @include button('primary', 'small');
        @include icon($icon: $icon-cart);
    }

    .minicart-action-checkout {
        @include button('secondary', 'small');
        @include icon($icon: $icon-chevron-right, $use-before: false);
    }

    .minicart-main {
        @include checkout-sidebar-section-padding($padding-size: 'minicart', $sides: ('left', 'right'));
        @include custom-scroll-bar();
        @include unstyled-list();
        flex: 1 1 auto;
    }

    .minicart-item {
        @include border($sides: ('top'));
        @include checkout-sidebar-section-padding($padding-size: 'minicart', $sides: ('top', 'bottom'));

        &:first-child {
            border-top: 0;
        }
    }

    .minicart-item-img-link {
        @include checkout-product-list-img-styles();

        .product-image-wrapper {
            padding-bottom: 0 !important; //sass-lint:disable-line no-important
        }
    }

    .minicart-item-content {
        display: flex;
        overflow: hidden;
        width: 100%;
    }

    .minicart-item-title {
        @include checkout-product-list-item-name-styles($font-size: 'product-name-small');
        color: get-color($anchor-color);

        .minicart-item-title-link {
            font-weight: get-font-weight('normal');

            &:hover,
            &:focus {
                color: get-color($anchor-color-hover);
            }
        }
    }

    .minicart-item-prices {
        @include checkout-product-list-item-price-styles();
    }

    .minicart-item-attributes {
        @include product-attribute-list-styles();
    }

    .minicart-item-attribute-row {
        @include product-attribute-row-styles();
    }

    .minicart-item-attribute-label {
        @include product-attribute-label-styles();
    }

    .minicart-item-attribute-value {
        @include product-attribute-value-styles();
    }

    .minicart-item-actions {
        @include font-size('small-copy');
    }

    .minicart-item-action-remove,
    .minicart-item-action-edit {
        @include icon($icon: $icon-warning) {
            margin-right: rem-calc(5);
            vertical-align: 0;
            line-height: 1;
            font-size: rem-calc(18);
        }

        display: inline-flex;
        align-items: center;
        flex-grow: 0;
        margin-right: $minicart-item-column-spacing;
        text-decoration: none;
    }

    .minicart-item-action-edit {
        &::before {
            content: $icon-edit;
        }
    }

    .minicart-summary {
        color: get-color($minicart-header-title-color);
    }

    .minicart-totals {
        @include grand-total-styles($background-color: $minicart-grand-total-background-color);
    }
}
