@mixin apply-customer-account-login-styles() {
    .customer-account-login {
        .customer-account-forgot-password-link {
            @include font-size('small-copy');
            @include spacing($spacing-name: 'xsmall', $margin-sides: ('top', 'bottom'));
        }

        .customer-create-account-value-add-list {
            @include unordered-list();
            @include spacing($spacing-name: 'small', $margin-sides: ('top', 'bottom'));
        }

        .actions-toolbar {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;

            .action.primary {
                @include button();
                @include spacing($spacing-name: 'xsmall', $margin-sides: ('right'));
            }
        }
    }
}
