@mixin center-icon-in-span($icon, $span-selector) {
    display: flex;
    align-items: center;
    justify-content: center;

    #{$span-selector} {
        @include icon($icon: $icon) {
            line-height: 1;
            @content;
        }
        line-height: 0;
    }
}

@mixin checkout-icon($icon, $use-before: true) {
    @include icon($icon: $icon, $use-before: $use-before) {
        @content;
    }
}

@mixin catalog-product-view-icon($icon, $use-before: true) {
    @include icon($icon: $icon, $use-before: $use-before) {
        @content;
    }
}

@mixin my-account-icon($icon, $use-before: true) {
    @include icon($icon: $icon, $use-before: $use-before) {
        @content;
    }
}
