@mixin apply-table-responsive-styles($desktop-table-selector: '.responsive-table-desktop-table') {
    @include apply-table-responsive-accordion-styles();

    > #{$desktop-table-selector} {
        @include table-responsive-table-desktop-styles();
    }
}

@mixin apply-table-responsive-accordion-styles() {
    .responsive-table-accordion {
        @include theme-accordion-container();
    }

    .responsive-table-accordion-item {
        @include theme-accordion-item();
        margin-bottom: rem-calc(8);
        border: 0;

        &:first-child {
            border-top: 0;
        }
    }

    .responsive-table-accordion-title {
        @include theme-accordion-item-title($background-color: 'neutral-lighter');
        @include border();
        padding-right: rem-calc(45);
        padding-left: rem-calc(20);

        &::before,
        &[aria-expanded='true']::before { // sass-lint:disable-line force-pseudo-nesting
            right: rem-calc(15);
        }
    }

    :last-child:not(.is-active) > & .responsive-table-accordion-title { // sass-lint:disable-line force-pseudo-nesting
        @include border($sides: ('bottom'), $additive: true);
    }

    .responsive-table-accordion-body {
        @include theme-accordion-item-body();
        padding-bottom: 0;

        .responsive-table-mobile-table {
            @include table-responsive-table-mobile-styles();
        }
    }
}

@mixin table-responsive-table-desktop-styles() {
    @include table-styles();
}

@mixin table-responsive-table-mobile-styles() {
    @include table(
        $is-hover: false,
        $padding: (
            'small': rem-calc(5 20)
        ),
        $border-color: 'transparent',
        $is-striped: false,
        $background-color: 'neutral-white',
        $border-collapse: separate
    );
    @include border();
    border-spacing: 0;

    > tbody {
        > tr {
            color: get-color($body-font-color);

            &:hover {
                color: get-color($body-font-color);
            }

            &:first-child {
                > * {
                    padding-top: rem-calc(25);
                }
            }

            &:last-child {
                > * {
                    padding-bottom: rem-calc(25);
                }
            }

            > th {
                text-align: left;
                font-weight: get-font-weight('semi-bold');
            }

            > td {
                width: 100%; // fill as much space as possible (will not actually be 100% because of other column)
            }
        }
    }
}
