@mixin styleguide-box-apply() {
    .example-box {
        @include theme-box-container();
        @include spacing('small', $margin-sides: ('bottom'));

        .example-box-header {
            @include theme-box-header();
        }

        .example-box-body {
            @include theme-box-body();
        }

        .example-box-footer {
            @include theme-box-footer();
        }
    }
}
