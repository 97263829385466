@import 'variables.scss';
@import 'layout/customer-account.scss';
@import 'layout/customer-account-entry.scss';
@import 'components/customer-account-forms.scss';
@import 'components/customer-account-boxes.scss';
@import 'components/customer-account-sidebar.scss';
@import 'components/customer-account-titles.scss';
@import 'components/customer-address-book.scss';
@import 'components/customer-account-order-tables.scss';
@import 'pages/customer-account-dashboard.scss';
@import 'pages/customer-account-entry.scss';
@import 'pages/customer-account-login.scss';

address { //get this added to match
    font-style: normal;
}

@mixin apply-customer-account-styles() {
    @include apply-customer-account-dashboard-styles();
    @include apply-customer-account-entry-page-styles();
    @include apply-customer-account-login-styles();
}
