@mixin inline-banners-apply(
    $banner-sizes: $inline-banner-size,
    $banner-order: $inline-banner-left-order
) {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    .inline-banner-wrapper {
        @include property('width', $banner-sizes);
        @include property('order', $banner-order);
        position: relative;
        flex: 0 0 auto;
        overflow: hidden;

        .inline-banner {
            @include image-fit();
            width: 100%;

            picture {
                display: flex;
                width: 100%;
                height: 100%;
            }
        }
    }

    .product-grid-wrapper {
        flex: 1 0 auto;

        @include breakpoint('large') {
            flex-shrink: 1;
        }

        @each $breakpoint, $width in $banner-sizes {
            @include breakpoint($breakpoint) {
                @if ($width == 100%) {
                    width: $width;
                } @else {
                    width: calc(100% - #{$width});
                }
            }
        }

        .products-grid,
        .products-grid .items {
            height: 100%;
        }
    }
}
