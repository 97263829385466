//@mixin sidebar-total(
//    $even-background-color: 'neutral-lightest',
//    $subtotal-font-weight: 'black',
//    $label-font-size: $checkout-totals-subtotal-label-font-size,
//    $label-color: $checkout-totals-primary-color,
//    $value-font-size: $checkout-totals-subtotal-label-font-size,
//    $value-color: $checkout-totals-primary-color,
//    $label-font: 'primary',
//    $value-font: 'primary'
//) {
//    display: flex;
//    padding: rem-calc(10);
//
//    &:nth-child(even) {
//        background-color: get-color($even-background-color);
//    }
//
//    &.subtotal {
//        .total-label,
//        .total-value,
//        .mark,
//        .amount {
//            font-weight: get-font-weight($subtotal-font-weight);
//        }
//    }
//
//    .total-label,
//    .mark {
//        @include font($label-font, 'normal');
//        @include font-size($checkout-totals-subtotal-label-font-size);
//        color: get-color($checkout-totals-primary-color);
//        font-style: normal;
//
//        .action {
//            display: inline-block;
//            padding-right: rem-calc(5);
//            line-height: rem-calc(10);
//        }
//
//        .remove-icon {
//            @include icon($icon: $icon-close) {
//                font-weight: get-font-weight('bold');
//            }
//        }
//    }
//
//    .total-value,
//    .amount {
//        @include font($value-font, 'normal');
//        @include font-size($checkout-totals-subtotal-label-font-size);
//        margin-left: auto;
//        color: get-color($checkout-totals-primary-color);
//        font-style: normal;
//    }
//}

//@mixin sidebar-totals(
//    $mobile-top-border: true
//) {
//    @include font-size($checkout-totals-subtotal-label-font-size);
//    padding: 0;
//    color: get-color($checkout-totals-primary-color);
//
//    @if $mobile-top-border == true {
//        @include border($sides: ('top'));
//    } @else {
//        @include breakpoint(large) {
//            @include border($sides: ('top'));
//        }
//    }
//
//    .price {
//        float: right;
//    }
//}
//
//@mixin sidebar-grand-total() {
//    @include sidebar-grand-total-typography-base();
//
//    @include breakpoint(large) {
//        padding-left: 0;
//        padding-right: 0;
//    }
//}
//
//@mixin sidebar-grand-total-typography-base() {
//    @include spacing('medium', $padding-sides: ('top', 'bottom'));
//    @include font-secondary('black');
//    @include font-size($checkout-totals-grandtotal-label-font-size);
//    font-style: italic;
//    text-transform: uppercase;
//}

@mixin total-container-base-styles() {
    @include checkout-sidebar-section-padding($padding-size: 'minicart');
    flex: 0 1 100%;
    color: get-color($subtotal-text-color);
}

@mixin subtotal-container-styles($background-color: $subtotals-background-color) {
    @include total-container-base-styles();
    background-color: get-color($background-color);
}

@mixin total-item-styles-base($text-color) {
    @include font-size('price-medium');
    display: flex;
    justify-content: space-between;
    margin: rem-calc(10 0);
    color: get-color($text-color);
    text-transform: uppercase;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

@mixin subtotal-item-styles($text-color: $subtotal-text-color) {
    @include total-item-styles-base($text-color: $subtotal-text-color);
}

@mixin subtotal-item-label-styles($font-size-name: $subtotal-label-font-size) {
    @include font-size($font-size-name);
    margin-bottom: 0;
    font-weight: get-font-weight('normal');
    font-style: italic;
}

@mixin subtotal-item-value-styles() {
    margin-left: rem-calc(10);
    text-align: right;
    font-weight: get-font-weight('bold');
}

@mixin subtotal-styles() {
    @include subtotal-container-styles();

    .total {
        @include subtotal-item-styles();
    }

    .total-label {
        @include subtotal-item-label-styles();
    }

    .total-value {
        @include subtotal-item-value-styles();
    }
}

@mixin grand-total-styles(
    $background-color: $grand-total-background-color,
    $text-color: $grand-total-text-color
) {
    @include grand-total-container-styles($background-color: $background-color);

    .total {
        @include grand-total-item-styles();
    }

    .total-label {
        @include grand-total-item-grand-total-label-styles();
    }

    .amount {
        @include grand-total-item-grand-total-value-styles();
    }
}

@mixin grand-total-container-styles($background-color: $subtotals-background-color) {
    @include total-container-base-styles();
    background-color: get-color($background-color);
    border-bottom: rem-calc(2) $global-border-style get-color($minicart-total-section-border-color);
}

@mixin grand-total-item-styles($text-color: $grand-total-text-color) {
    @include total-item-styles-base($text-color: $text-color);
    align-items: center;

    &:last-child {
        margin-bottom: rem-calc(-5);
    }
}

@mixin grand-total-item-grand-total-label-styles() {
    @include typography-heading('h4');
}

@mixin grand-total-item-grand-total-value-styles() {
    @include typography-heading('h4');
}
