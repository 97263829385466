@mixin apply-northern-sociallogin-customer-account-login-styles() {
    .customer-account-login {
        .social-login-container {
            @include social-login-button-styles();
            @include spacing($spacing-name: 'small', $margin-sides: ('top'));
            @include spacing($spacing-name: 'small', $padding-sides: ('top'));
            @include border($sides: ('top'));
            @include grid-column(
                $responsive-sizes: (
                    'small': column-width-percent(12),
                    'medium': column-width-percent(10)
                )
            );
        }
    }
}
