// Button wrapper mixin. Use this in modules or cases where button should be configured via variables.
@mixin button($type: $button-default-type, $size: $button-default-size) {
    @if ($type == 'primary') {
        @include primary-button($size);
    } @else if ($type == 'secondary') {
        @include secondary-button($size);
    } @else if ($type == 'tertiary') {
        @include tertiary-button($size);
    } @else {
        @include primary-button($size);
        @warn 'Button type `#{$type}` requested but no button mixin found. Using default of `primary`.';
    }
    @include letter-spacing('button-#{$size}');
}

@mixin button-disabled($color) {
    border: $button-disabled-border-size solid get-color($button-disabled-border-color);
    background-color: get-color($button-disabled-background-color);
    cursor: not-allowed;
    color: rgba(get-color($button-disabled-text-color-base), $button-disabled-text-color-alpha);
}

// prevent text alignment issues on ios9 & safari 9
// https://git.northern.co/magento2/feltstore/issues/13
@mixin safari-9-text-alignment-bug-fix() {
    display: inline-block;
}

@mixin primary-button($size: $button-default-size) {
    @include button-size($size);
    @include button-style(
        $background-color: $primary-button,
        $background-color-hover: $primary-button-hover,
        $text-color: $primary-button-color,
        $text-color-hover: $primary-button-color-hover,
        $border-width: $primary-button-border-width,
        $border-width-hover: $primary-button-border-width,
        $border-radius: $primary-button-border-radius
    );
    @include safari-9-text-alignment-bug-fix();
}

@mixin secondary-button($size: $button-default-size) {
    @include button-size($size);
    @include button-style(
        $background-color: $secondary-button,
        $background-color-hover: $secondary-button-hover,
        $text-color: $secondary-button-color,
        $text-color-hover: $secondary-button-color-hover,
        $border-width: $secondary-button-border-width,
        $border-width-hover: $secondary-button-border-width,
        $border-radius: $secondary-button-border-radius
    );
    @include safari-9-text-alignment-bug-fix();
}

@mixin tertiary-button($size: $button-default-size) {
    @include button-size($size);
    @include button-style(
        $background-color: $tertiary-button,
        $background-color-hover: $tertiary-button-hover,
        $text-color: $tertiary-button-color,
        $text-color-hover: $tertiary-button-color-hover,
        $border-width: $tertiary-button-border-width,
        $border-width-hover: $tertiary-button-border-width,
        $border-radius: $tertiary-button-border-radius
    );
    @include safari-9-text-alignment-bug-fix();

    &[disabled] {
        @each $breakpoint, $width in $tertiary-button-border-width {
            @include breakpoint($breakpoint) {
                @include button-border($width, $button-disabled-border-color);
            }
        }
        background-color: get-color($tertiary-button);
    }
}

@mixin button-icon($icon, $use-before: true, $spacing: $default-button-icon-spacing) {
    @include icon($icon: $icon, $use-before: $use-before) {
        @if $use-before != true {
            margin-left: $spacing;
        } @else {
            margin-right: $spacing;
        }
        @content;
    }
}

@mixin button-with-icon($icon, $type: $button-default-type, $size: $button-default-size, $use-before: true, $icon-spacing: $default-button-icon-spacing) {
    @include button($type, $size);
    @include button-icon($icon, $use-before, $icon-spacing) {
        @content;
    }
    display: flex; // eliminate inline spacing between icon and text
}

@mixin reset-default-button-styles($remove-outline: false) {
    @if $remove-outline != false {
        outline: none;
    }
    background: get-color('transparent');
    cursor: pointer;
    padding: 0;
    text-align: left;
}

@mixin link-with-icon($icon, $use-before: true, $icon-spacing: $default-button-icon-spacing) {
    @include button-icon($icon, $use-before, $icon-spacing) {
        @content;
    }
    @include font-size('icon-link-icon');
    @include line-height('small-copy');

    span {
        @include font-size('icon-link-anchor');
    }
}
