@mixin apply-sales-order-history-styles() {
    .sales-order-history {
        @include apply-pager-styles();
    }

    .content-main {
        .message.empty {
            @include spacing($spacing-name: 'xsmall', $margin-sides: ('bottom'));
        }
    }
}
