@mixin theme-typography-heading(
    $heading-type,
    $color: $heading-color,
    $font-weight: $heading-font-weight,
    $font-style: $heading-font-style,
    $font-stack: $heading-font-stack,
    $small-font-color: $heading-small-font-color,
    $include-spacing: false,
    $last-child-no-margin: true
) {
    $text-transform: map-safe-get($theme-heading-text-transform, $heading-type);
    @include typography-heading(
        $heading-type: $heading-type,
        $color: $color,
        $font-weight: $font-weight,
        $font-style: $font-style,
        $text-transform: $text-transform,
        $font-stack: $font-stack,
        $small-font-color: $small-font-color
    );
    @include letter-spacing($heading-type);

    @if $include-spacing {
        @include typography-heading-margin($heading-type, $last-child-no-margin);
    }
}

@mixin subheading(
    $heading-type,
    $color: $subheading-color,
    $font-weight: $subheading-font-weight,
    $font-style: $subheading-font-style,
    $text-transform: $subheading-text-transform,
    $font-stack: $subheading-font-stack,
    $small-font-color: $subheading-small-font-color
) {
    @include typography-heading(
        $heading-type: $heading-type,
        $color: $color,
        $font-weight: $font-weight,
        $font-style: $font-style,
        $text-transform: $text-transform,
        $font-stack: $font-stack,
        $small-font-color: $small-font-color
    );
}

@mixin heading-offset-underline(
    $color: 'accent-3',
    $opacity: 0.4,
    $size: rem-calc(2),
    $offset: rem-calc(6)
) {
    position: relative;

    &::after {
        position: absolute;
        bottom: -1 * $offset;
        left: 0;
        border-bottom: $size $global-border-style rgba(get-color($color), $opacity);
        width: 100%;
        content: '';
    }
}

@mixin heading-styles-h1() {
    @include theme-typography-heading('h1');
}

@mixin heading-styles-h2() {
    @include theme-typography-heading('h2');
}

@mixin heading-styles-h3() {
    @include theme-typography-heading('h3');
}

@mixin heading-styles-h4() {
    @include theme-typography-heading('h4');
}

@mixin heading-styles-h5() {
    @include theme-typography-heading('h5');
}

@mixin heading-styles-h6() {
    @include theme-typography-heading('h6');
}

@mixin subheading-large() {
    @include subheading('subheading-large');
}

@mixin subheading-medium() {
    @include subheading('subheading-medium');
}

@mixin subheading-small() {
    @include subheading('subheading-small');
}

@mixin theme-page-title() {
    @include spacing($spacing-name: 'small', $margin-sides: ('bottom'), $padding-sides: ('bottom'));
    @include border($sides: ('bottom'));
}
