@mixin apply-checkout-step-customer-info-styles() {
    .checkout-step-customer-info {
        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            > :first-child {
                @include spacing($spacing-name: 'xsmall', $margin-sides: ('right'));
            }

            .login-text {
                @include font-size('small-copy');
            }

            .login-button {
                @include button($type: $checkout-section-login-button-type, $size: $checkout-section-login-button-size);
            }
        }

        .checkout-customer-info-welcome {
            .legend {
                @include form-legend();
            }

            .heading {
                @include legend-heading();
            }

            .description {
                @include legend-description();
            }
        }

        .inline-fields {
            @include grid-row();

            .field {
                @include grid-column(
                    $responsive-sizes: (
                        'small': column-width-percent(12),
                        'medium': column-width-percent(6)
                    )
                );
            }
        }
    }

    .checkout-login-error,
    .checkout-login-info {
        @include small-copy();
    }

    .checkout-login-error {
        .checkout-login-error-message {
            @include icon($icon: $checkout-customer-info-email-error-icon) {
                margin-right: rem-calc(5);
                font-size: rem-calc(18);
            }

            color: get-color('error');
        }

        .checkout-login-error-action {
            @include typography-anchor();
            background: none;
            display: inline;
            border: none;
            outline: none;
        }
    }

    .checkout-login-info {
        .checkout-login-info-message {
            @include icon($icon: $checkout-customer-info-email-info-icon) {
                margin-right: rem-calc(5);
                font-size: rem-calc(18);
            }

            color: get-color('information');
        }
    }
}

@mixin apply-checkout-step-shipping-method-styles() {
    .checkout-shipping-address-snapshot {
        @include theme-box-container();
        @include spacing($spacing-name: $checkout-section-vertical-spacing, $margin-sides: ('bottom'));

        .checkout-shipping-address-snapshot-header {
            @include theme-box-header();
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .checkout-shipping-address-step-edit {
                @include link-with-icon($checkout-step-edit-icon);
            }

            p {
                @include typography-heading('h4');
            }
        }

        .checkout-shipping-address-snapshot-box-body {
            @include theme-box-body();
        }
    }

    .checkout-shipping-field-use {
        @include apply-reduced-width-form-styles();

        .shipping-method-rate-name {
            flex-grow: 1;
        }

        .price {
            font-weight: get-font-weight($checkout-shipping-method-option-price-font-weight);
        }
    }
}

@mixin apply-checkout-step-payment-method-cc-form-styles() {
    .checkout-option-set-body {
        .fieldset {
            @include grid-row();

            .field {
                &.number {
                    @include grid-column(
                        $responsive-sizes: (
                            'small': column-width-percent(12)
                        ),
                        $flex-shrink: 0
                    )
                }

                &.date-month,
                &.date-year,
                &.cvv {
                    @include grid-column(
                        $responsive-sizes: (
                            'small': column-width-percent(12),
                            'medium': column-width-percent(4)
                        )
                    )
                }

                .checkout-tooltip-button {
                    @include custom-tooltip-button-styles();
                }
            }
        }
    }

    .checkout-option-set-header {
        display: flex;
        flex-wrap: no-wrap;
        justify-content: space-between;

        label {
            flex-shrink: 0;
        }

        .cc-icons {
            flex-shrink: 1;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                max-width: rem-calc(50);
            }
        }
    }
}

@mixin apply-checkout-step-payment-method-styles() {
    .checkout-step-payment-method {
        @include apply-checkout-step-payment-method-cc-form-styles();
    }
}
