@mixin checkout-sales-order-item(
    $image-responsive-sizes: $checkout-sales-order-item-image-sizes,
    $content-responsive-sizes: $checkout-sales-order-item-content-sizes,
    $product-name-font-size: 'product-name-small',
    $product-info-font-size: 'small-copy'
) {
    .product-photo {
        @include grid-column(
            $responsive-sizes: $image-responsive-sizes
        );
    }

    .item-product-info {
        @include grid-column(
            $responsive-sizes: $content-responsive-sizes
        );
        @include font-size($product-info-font-size);
        display: flex;
        flex-direction: column;

        .product-item-name {
            @include checkout-product-list-item-name-styles($font-size: $product-name-font-size);
            @include line-height('product-name-small');
            @include spacing($checkout-sales-order-item-info-spacing, $margin-sides: ('bottom'));
            color: get-color($anchor-color);
        }

        .item-options {
            .product-info-option-group {
                @include spacing($checkout-sales-order-item-info-spacing, $margin-sides: ('bottom'));
            }

            .product-info-option-label {
                font-weight: get-font-weight('semi-bold');
            }
            
            .product-info-option-value {
                a,
                span {
                    &::before {
                        content: ', ';
                        display: inline;
                    }
                }
            }
        }

        .item-qty {
            .item {
                @include spacing($checkout-sales-order-item-info-spacing, $margin-sides: ('bottom'));
            }

            .title {
                font-weight: get-font-weight('semi-bold');
            }
        }

        .item-price {
            @include spacing($checkout-sales-order-item-info-spacing, $margin-sides: ('bottom'));
            @include price('price-medium');
        }
    }
}

@mixin apply-northern-checkout-success-order-item-styles() {
    .order-items {
        @include spacing($checkout-sales-order-item-outer-spacing, $margin-sides: ('top'));
        @include border($color: $checkout-sales-order-item-border-color);
        background-color: get-color($checkout-sales-order-item-background-color);
    }

    .order-item-row {
        @include spacing($checkout-sales-order-item-inner-spacing, $padding-sides: ('all'));
        @include grid-row($vertical-gutters: true);
        @include checkout-sales-order-item($product-name-font-size: 'product-name-medium');

        .item-options {
            order: 1;
            text-align: left;
        }

        .item-qty {
            order: 2;
        }

        .item-price {
            order: 3;
        }
    }
}
