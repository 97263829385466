@mixin apply-order-item-details-styles() {
    @include spacing($spacing-name: 'box', $padding-sides: ('all'));
    @include border($sides: ('all'));

    .order-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;

        strong {
            @include heading-styles-h4();
            @include typography-margin('h4');
            flex-grow: 1;
            width: 100%;

            @include breakpoint('medium') {
                width: auto;
            }
        }

        .action.print {
            @include button-with-icon($icon: $icon-print, $type: 'tertiary', $size: 'tiny');
        }

        .action.track {
            @include button-with-icon($icon: $icon-truck, $type: 'tertiary', $size: 'tiny');

            @include breakpoint('large') {
                @include spacing($spacing-name: 'gutter', $margin-sides: ('left'), $ratio: 0.5);
            }
        }
    }

    .table-wrapper {
        overflow-x: auto;
    }

    .table-order-items {
        width: 100%;
        border-collapse: collapse;

        .table-caption {
            @include visually-hidden();
        }

        tfoot {
            @include border($sides: ('right', 'bottom', 'left'));
            display: table;
            width: 100%;
            background-color: get-color('neutral-lighter');
            text-align: left;

            tr:first-of-type {
                th {
                    @include spacing($spacing-name: 'gutter', $padding-sides: ('top'), $ratio: 0.5);
                }
            }

            tr:last-of-type {
                th {
                    @include spacing($spacing-name: 'gutter', $padding-sides: ('bottom'), $ratio: 0.5);
                }
            }

            th {
                @include spacing($spacing-name: 'box', $padding-sides: ('left', 'right'));
                @include spacing($spacing-name: 'gutter', $padding-sides: ('top', 'bottom'), $ratio: 0.25);
                text-align: left;
                width: 1px;
                white-space: nowrap;
            }
        }
    }

    .item-renderer,
    .item-renderer-items {
        @include theme-box-container();

        table {
            @include font-size('small-copy');
        }

        .cart-price {
            @include apply-price();
        }
    }

    .item-renderer-items {
        @include border($sides: ('left', 'right'), $additive: true);
        background: get-color('neutral-white');

        @include breakpoint('large') {
            display: flex;
            flex-direction: row;
        }
    }

    .item-renderer-inner {
        @include theme-box-body();
        padding-top: 0;

        table {
            tr {
                td {
                    a,
                    span {
                        display: block;
                    }
                }
            }
        }
    }

    .product-item-image {
        padding: rem-calc(22 26 0);

        @include breakpoint('large') {
            padding: rem-calc(22 0 22 26);
            width: rem-calc(150);
        }
    }

    .item-renderer-content {
        padding: rem-calc(22 26);

        @include breakpoint('large') {
            padding: rem-calc(22 22 26);
        }

        .name {
            margin-bottom: rem-calc(10);
        }

        tbody {
            tr {
                vertical-align: top;
            }
            
            td {
                a {
                    display: block;
                }
            }
        }
    }

    .product-item-name {
        @include theme-box-header();
    }

    .actions-toolbar {
        display: none;
    }
}

@mixin apply-order-details-styles() {
    @include spacing($spacing-name: 'small', $padding-sides: ('top'));

    .block-content {
        @include apply-customer-account-boxes();
        @include grid-row(
            $column-gutters: (
                'small': 0
            ),
            $vertical-gutters: true
        );

        .box {
            @include grid-column(
                $responsive-sizes: (
                    'small': column-width-percent(12),
                    'medium': column-width-percent(6),
                    'large': column-width-percent(3)
                )
            );

            .box-content {
                flex-grow: 1;
            }
        }
    }
}
