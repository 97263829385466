@mixin apply-trust-elements-styles(
    $payment-icon-width: $trust-icon-payment-icon-width
) {
    @include apply-payment-method-icons-styles($payment-icon-width);
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .payment-method-icon-list {
        margin-left: rem-calc(10);
    }

    .trust-element-icon {
        width: 100%;
        max-width: rem-calc(70);

        &:not(:last-of-type) {
            margin-right: rem-calc(10);
        }
    }
}

@mixin apply-payment-method-icons-styles(
    $width: $trust-icon-payment-icon-width
) {
    .payment-method-icon-list {
        @include unstyled-list();
        display: flex;
    }

    .payment-method-icon-item {
        margin-left: rem-calc(10);
        width: $width;

        &:first-child {
            margin-left: 0;
        }
    }
}
