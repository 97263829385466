@mixin form-input-base(
    $border-width: $input-border-width,
    $border-style: $input-border-style,
    $border-color: $input-border-color,
    $border-color-hover: $input-border-color-hover,
    $border-radius: $input-border-radius,
    $background-color: $input-background-color,
    $background-color-hover: $input-background-color-hover-scale,
    $background-color-disabled: $input-background-disabled,
    $border-color-disabled: $input-border-color-disabled,
    $color-disabled: $input-color-disabled,
    $color: $input-color,
    $font-size: 'input',
    $include-outline: false,
    $padding: $input-padding,
    $transition-properties: (border, background-color, color, box-shadow, padding),
    $width: 100%,
    $appearance: none
) {
    $border-color-hover: get-color($border-color-hover);
    $background-color-hover: normalize-color-scale($background-color, $background-color-hover);
    border: 0;
    border-radius: $border-radius;
    background-color: get-color($background-color);
    padding: $padding;
    width: $width;
    color: get-color($color);
    appearance: $appearance;

    @if null != $transition-properties {
        @include transition($transition-properties);
    }

    &:disabled {
        opacity: 1; // reset safari mobile styles
        background-color: get-color($background-color-disabled);
        color: get-color($color-disabled);

        @each $breakpoint, $value in $border-width {
            @include breakpoint($breakpoint) {
                @include button-border($value, $border-color-disabled);
            }
        }

        &::placeholder {
            color: get-color($color-disabled);
        }
    }

    &:not(:disabled) {
        @include input-box-shadow-border($border-width, $border-color, $border-color-hover);

        &:hover,
        &:focus {
            @each $breakpoint, $value in $input-border-width-hover {
                @include breakpoint($breakpoint) {
                    @include button-border($value, $border-color-hover);
                }
            }
            background-color: $background-color-hover;
        }
    }

    @each $breakpoint, $responsive-font-size in get-font-size($font-size) {
        @include breakpoint($breakpoint) {
            font-size: $responsive-font-size;
        }
    }

    @if ($include-outline == false) {
        outline: 0;
    }
}

@mixin input-box-shadow-border($border-width, $border-color, $border-color-hover: $input-border-color-hover, $border-bottom-size-ratio: -1) {
    @each $breakpoint, $value in $border-width {
        @include breakpoint($breakpoint) {
            box-shadow: inset 0 ($border-bottom-size-ratio * $value) 0 0 get-color('primary'), inset 0 0 0 $value get-color($border-color);
        }
    }

    &:hover,
    &:focus {
        @each $breakpoint, $value in $input-border-width-hover {
            @include breakpoint($breakpoint) {
                @include button-border($value, $border-color-hover);
            }
        }
    }
}

@mixin form-radio-unchecked(
    $radio-check-margin: $form-radio-checkbox-margin
) {
    @include icon($icon: $form-radio-content-unchecked) {
        margin-right: $radio-check-margin;
        font-size: $form-radio-icon-size;
    }
}

@mixin form-radio-checked() {
    @include icon($icon: $form-radio-content-checked);
}

@mixin form-radio() {
    @include visually-hidden();

    @each $breakpoint, $font-size in get-font-size('radio') {
        @include breakpoint($breakpoint) {
            font-size: $font-size;
        }
    }

    + * {
        @include form-radio-unchecked();

        @each $breakpoint, $font-size in get-font-size('normal') {
            @include breakpoint($breakpoint) {
                font-size: $font-size;
            }
        }
    }

    ~ .radio-proxy {
        color: get-color($radio-icon-color);
    }

    ~ :not(.radio-proxy) {
        &:not(.mage-error) {
            color: get-color($radio-label-color);
            font-family: get-font-stack($radio-label-font-family);

            @each $breakpoint, $font-size in get-font-size('radio') {
                @include breakpoint($breakpoint) {
                    font-size: $font-size;
                }
            }
        }

        &.mage-error {
            @include error-border-styles();
        }
    }

    &:checked {
        + * {
            @include form-radio-checked();
        }
    }

    &:disabled {
        ~ :not(.radio-proxy) {
            color: get-color($radio-label-disabled-color);
        }

        ~ * {
            color: get-color($radio-label-disabled-color);
        }
    }
}

@mixin form-checkbox-unchecked(
    $radio-check-margin: $form-radio-checkbox-margin
) {
    @include icon($icon: $form-checkbox-content-unchecked) {
        margin-right: $radio-check-margin;
        font-size: $form-checkbox-icon-size;
    }
}

@mixin form-checkbox-checked() {
    @include icon($icon: $form-checkbox-content-checked);
}

@mixin form-checkbox-base-styles() {
    @include form-checkbox-unchecked();

    @each $breakpoint, $font-size in get-font-size('checkbox') {
        @include breakpoint($breakpoint) {
            font-size: $font-size;
        }
    }
}

@mixin form-checkbox() {
    @include visually-hidden();

    + * {
        @include form-checkbox-base-styles();
    }

    ~ .checkbox-proxy {
        color: get-color($checkbox-icon-color);
    }

    ~ :not(.checkbox-proxy) {
        &:not(.mage-error) {
            color: get-color($checkbox-label-color);
            font-family: get-font-stack($checkbox-label-font-family);

            @each $breakpoint, $font-size in get-font-size('checkbox') {
                @include breakpoint($breakpoint) {
                    font-size: $font-size;
                }
            }
        }

        &.mage-error {
            @include error-border-styles();
        }
    }

    &:checked {
        + * {
            @include form-checkbox-checked();
        }
    }

    &:disabled {
        ~ * {
            color: get-color($checkbox-label-disabled-color);
        }

        ~ :not(.checkbox-proxy) {
            color: get-color($checkbox-label-disabled-color);
        }
    }
}

@mixin mage-error() {
    .mage-error {
        &:not(:disabled) {
            &:not(div) {
                @each $breakpoint, $value in $input-border-width {
                    @include breakpoint($breakpoint) {
                        @include button-border($value, 'error');
                    }
                }
            }
        }
    }

    div.mage-error {
        @include validation-error();
        @content;
    }
}

@mixin validation-error($color: 'error') {
    @include font-size('form-error');
    margin-top: $validation-error-margin-top;
    color: get-color($color);
}

@mixin error-border-styles($color: 'error', $border-sides: ('top')) {
    @include border($width: $input-border-width, $color: get-color('error'), $sides: $border-sides);
    @each $side in $border-sides {
        padding-#{$side}: rem-calc(5);
    }
}

@mixin password-input-styles() {
    position: relative;

    [type='password'] {
        &:not(:disabled) {
            @include input-box-shadow-border($input-border-width, $input-border-color, $input-border-color-hover, -2);
        }
    }

    .password-icon {
        @include icon($icon: $icon-lock) {
            color: get-color($input-color);
        }
        position: absolute;
        top: rem-calc(8);
        right: rem-calc(10);
        font-size: rem-calc(20);
        pointer-events: none;
    }

    [data-role='password-strength-meter'] {
        .password-strength-meter {
            @include property('font-size', get-font-size('tiny-copy'));
            position: relative;
            padding-top: rem-calc(2);
            color: get-color($input-color);

            &::before {
                position: absolute;
                top: rem-calc(-2);
                left: 0;
                width: 0;
                height: rem-calc(2);
                content: '';
            }
        }

        &.password-weak {
            .password-strength-meter {
                &::before {
                    background-color: get-color('error');
                    width: 33%;
                }
            }
        }

        &.password-medium {
            .password-strength-meter {
                &::before {
                    background-color: get-color('warning');
                    width: 66%;
                }
            }
        }

        &.password-strong,
        &.password-very-strong {
            .password-strength-meter {
                &::before {
                    background-color: get-color('success');
                    width: 100%;
                }
            }
        }
    }
}
