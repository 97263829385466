@mixin contact-icon($icon) {
    @include icon($icon: $icon) {
        color: get-color('primary');
        margin-right: rem-calc(8);
        margin-left: rem-calc(-23);
    }
    display: block;
    margin-bottom: rem-calc(5);
}

@mixin contact-hours-table-styles() {
    @include spacing('gutter', $margin-sides: ('bottom'));
    width: 100%;

    td {
        display: block;
        width: 100%;

        @include breakpoint('xlarge') {
            display: table-cell;
            width: 45%;

            & + td {
                width: 55%;
                text-align: right;
            }
        }

        @include breakpoint('xxlarge') {
            width: 50%;
        }

        & + td {
            font-weight: get-font-weight('semi-bold');
        }
    }
}

@mixin apply-contact-sidebar-styles() {
    .contact-store-info-title {
        @include theme-typography-heading('h3');
        margin-bottom: map-safe-get(get-spacing('gutter'), 'small');
    }

    .contact-store-info-wrapper {
        @include spacing('large', $margin-sides: ('bottom'));
        @include spacing('medium', $padding-sides: ('all'), $max-size: 'small');
        @include border();
    }

    .contact-store-info-content {
        @include border($sides: ('bottom'));
        @include spacing('medium', $padding-sides: ('bottom'), $max-size: 'small');
        padding-left: rem-calc(20);
    }

    .contact-store-hours-content {
        @include spacing('medium', $padding-sides: ('top'), $max-size: 'small');
    }

    .contact-store-info-phone {
        @include typography-anchor();
        @include contact-icon($icon-phone);
        display: block;
    }

    .contact-store-info-email {
        @include typography-anchor();
        @include contact-icon($icon-email);
        display: block;
    }

    .contact-store-info-fax {
        @include contact-icon($icon-print);
        display: block;
    }

    .contact-store-info-address {
        @include contact-icon($icon-location-pin);
        display: block;
    }

    .contact-store-info-directions-anchor {
        @include button('primary', 'tiny');
    }

    .contact-store-hours-title {
        margin-bottom: map-safe-get(get-spacing('gutter'), 'small');
    }

    .contact-store-hours-table {
        @include contact-hours-table-styles();
    }

    .contact-store-hours-locations-anchor {
        @include button('primary', 'small');
        width: 100%;

        @include breakpoint('xlarge') {
            width: auto;
            padding-right: rem-calc(40);
            padding-left: rem-calc(40);
        }
    }
}
