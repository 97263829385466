@mixin checkout-toolbar-arrows() {
    @include icon($icon: $checkout-toolbar-steps-arrow-icon, $use-before: false) {
        vertical-align: text-bottom;
        font-size: $checkout-toolbar-steps-arrow-font-size;
        color: get-color($checkout-toolbar-steps-arrow-color);
    }
}

@mixin checkout-toolbar-step-state($state) {
    &.#{$state} {
        .checkout-toolbar-step-title {
            color: get-color(map-safe-get($checkout-toolbar-step-title-colors, $state));
        }

        @if $checkout-toolbar-steps-show-numbers-breakpoint != null or $checkout-toolbar-steps-show-numbers {
            .checkout-toolbar-step-number {
                @include set-background-color(map-safe-get($checkout-toolbar-step-number-colors, $state), $foreground: $checkout-toolbar-step-number-foreground-colors);
            }
        }
    }
}

@mixin checkout-toolbar-step-processed-icon-override() {
    &.processed {
        .checkout-toolbar-step-number {
            @include icon($icon: $checkout-toolbar-step-processed-state-icon) {
                font-weight: get-font-weight('normal');
            }
        }
    }
}

@mixin checkout-toolbar-step-number() {
    @include set-background-color(map-safe-get($checkout-toolbar-step-number-colors, 'inactive'), $foreground: $checkout-toolbar-step-number-foreground-colors);
    font-size: $checkout-toolbar-step-font-size;
    height: $checkout-toolbar-step-diameter;
    width: $checkout-toolbar-step-diameter;
    line-height: $checkout-toolbar-step-diameter;
    display: inline-block;
    flex-shrink: 0;
    border-radius: 50%;
    text-align: center;
    counter-increment: checkout-toolbar-step;

    &::before {
        content: counter(checkout-toolbar-step);
    }
}

@mixin checkout-toolbar-step-state-line($position) {
    position: relative;

    @each $state, $color in $checkout-toolbar-step-state-line-colors {
        &.#{$state} {
            &::after {
                @include property('height', $checkout-header-bottom-border-width);
                @include property(#{$position}, $checkout-header-bottom-border-width, $ratio: -1);
                content: '';
                display: block;
                position: absolute;
                background-color: get-color($color);
                right: 0;
                left: 0;
            }
        }
    }
}

@mixin checkout-toolbar-step-item {
    @include spacing($spacing-name: 'gutter', $padding-sides: ('left'), $ratio: 0.5);

    .checkout-toolbar-step-item-link {
        display: flex;
        align-items: center;
    }

    &:first-of-type {
        padding-left: 0;
    }

    @if $checkout-toolbar-steps-show-numbers {
        .checkout-toolbar-step-number {
            @include checkout-toolbar-step-number();
        }

        .checkout-toolbar-step-title {
            @include spacing($spacing-name: 'gutter', $padding-sides: ('left'), $ratio: 0.5);
        }

        @if $checkout-toolbar-step-processed-state-icon != null {
            @include checkout-toolbar-step-processed-icon-override();
        }
    }

    @if $checkout-toolbar-steps-show-numbers-breakpoint != null {
        @include breakpoint($checkout-toolbar-steps-show-numbers-breakpoint only) {
            .checkout-toolbar-step-number {
                @include checkout-toolbar-step-number();
            }

            .checkout-toolbar-step-title {
                display: none;
            }

            &.active {
                @if $checkout-toolbar-steps-mobile-show-active-number == false {
                    .checkout-toolbar-step-number {
                        @include visually-hidden();
                    }
                }

                .checkout-toolbar-step-title {
                    display: initial;
                }
            }

            &.inactive {
                .checkout-toolbar-step-title {
                    @include visually-hidden();
                }
            }

            @if $checkout-toolbar-step-processed-state-icon != null {
                @include checkout-toolbar-step-processed-icon-override();
            }
        }
    }

    @if $checkout-toolbar-steps-show-numbers == true or $checkout-toolbar-steps-show-numbers-breakpoint != null {
        @include checkout-toolbar-step-state('active');
        @include checkout-toolbar-step-state('inactive');
        @include checkout-toolbar-step-state('processed');
    }

    @if $checkout-toolbar-steps-show-arrows {
        .checkout-toolbar-step-item-link {
            @include checkout-toolbar-arrows();

            &::after {
                @include spacing($spacing-name: 'gutter', $margin-sides: ('left'), $ratio: 0.5);
            }
        }

        &:last-of-type {
            .checkout-toolbar-step-item-link {
                &::after {
                    display: none;
                    content: '';
                }
            }
        }
    }

    @if $checkout-toolbar-step-state-line-position != null {
        @include checkout-toolbar-step-state-line($checkout-toolbar-step-state-line-position);
    }
}

@mixin apply-checkout-toolbar-steps() {
    .checkout-toolbar-steps-list {
        @include unstyled-list();
        display: flex;
        align-items: stretch;
        counter-reset: checkout-toolbar-step;

        .checkout-toolbar-step-item {
            @include checkout-toolbar-step-item();
            @include spacing($spacing-name: 'xsmall', $padding-sides: ('top', 'bottom'), $ratio: 0.5); //@TODO remove ratio
            display: flex;
        }
    }
}

@mixin checkout-toolbar-base-font-styles() {
    @include font-size('checkout-step');
    font-family: get-font-stack($checkout-toolbar-font-family);
    font-weight: get-font-weight($checkout-toolbar-font-weight);
    color: get-color($checkout-toolbar-font-color);
}

@mixin apply-northern-checkout-toolbar-styles() {
    .checkout-toolbar {
        @include checkout-toolbar-base-font-styles();
        @include apply-checkout-toolbar-steps();
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
