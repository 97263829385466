// Header
$checkout-header-main-background-color: 'neutral-white';
$checkout-header-main-foreground-colors: ('primary', 'neutral-white');
$checkout-header-bottom-background-color: 'neutral-white';
$checkout-header-main-icon-font-size: 'checkout-header-main-icon';
$checkout-header-logo-width: (
    'small': rem-calc(130),
    'large': rem-calc(160),
    'xlarge': rem-calc(190)
);

$checkout-header-main-border-color: $global-border-color;
$checkout-header-bottom-border-color: $checkout-header-main-border-color;

$checkout-header-main-border-width: $global-border-width;
$checkout-header-bottom-border-width: $checkout-header-main-border-width;

$checkout-header-contact-icon: $icon-phone;
$checkout-header-trust-element-secure-icon: $icon-lock;

$checkout-toolbar-font-family: 'primary';
$checkout-toolbar-font-weight: 'semi-bold';
$checkout-toolbar-font-color: 'primary';

$checkout-toolbar-steps-show-numbers: false;
$checkout-toolbar-steps-show-numbers-breakpoint: small;
$checkout-toolbar-steps-mobile-show-active-number: false;
$checkout-toolbar-step-number-foreground-colors: ('white', 'primary');

$checkout-toolbar-steps-show-arrows: true;
$checkout-toolbar-steps-arrow-icon: $icon-chevron-right;
$checkout-toolbar-steps-arrow-color: 'neutral-light';
$checkout-toolbar-steps-arrow-font-size: rem-calc(12);

$checkout-toolbar-step-diameter: rem-calc(20);
$checkout-toolbar-step-font-size: rem-calc(10);

$checkout-toolbar-step-title-colors: (
    'active': 'secondary',
    'inactive': 'neutral-dark',
    'processed': 'primary'
);

$checkout-toolbar-step-number-colors: (
    'active': 'secondary',
    'inactive': 'neutral-dark',
    'processed': 'primary'
);

$checkout-toolbar-step-state-line-position: 'bottom';

$checkout-toolbar-step-state-line-colors: (
    'active': 'secondary',
    'processed': 'primary'
);

$checkout-toolbar-step-processed-state-icon: $icon-checkmark-strong;

// Steps/Main Body
$checkout-section-vertical-spacing: 'small';
$checkout-section-background-color: 'transparent';
$checkout-section-include-borders: false;
$checkout-section-include-padding: false;
$checkout-section-padding-ratio-default: 1;
$checkout-section-legend-subheading-type: 'h3';

$checkout-section-login-button-size: 'tiny';
$checkout-section-login-button-type: 'primary';

$checkout-actions-button-size: 'regular';
$checkout-actions-back-cart-button-type: 'primary';
$checkout-actions-back-cart-button-icon: $icon-cart;
$checkout-actions-back-button-type: 'primary';
$checkout-actions-back-button-icon: $icon-chevron-left;
$checkout-actions-next-button-type: 'primary';
$checkout-actions-next-button-icon: $icon-chevron-right;
$checkout-actions-complete-button-type: 'secondary';
$checkout-actions-complete-button-icon: $icon-success;

$checkout-option-set-background-color: transparent;
$checkout-option-set-body-background-color: 'neutral-lighter';
$checkout-step-edit-icon: $icon-edit;
$checkout-customer-info-email-error-icon: $icon-alert;
$checkout-customer-info-email-info-icon: $icon-info;
$checkout-shipping-method-option-price-font-weight: 'semi-bold';

$checkout-loader-overlay-spinner-size: rem-calc(40);
$checkout-loader-overlay-z-index: $overlay-z-index;

// Success Page
$checkout-success-sidebar-title-spacing: 'checkout-sidebar';
$checkout-success-sidebar-title-color: 'body';
$checkout-success-sidebar-body-color: 'neutral-dark';
$checkout-success-sidebar-body-font-size: 'small-copy';
$checkout-success-sidebar-accordion-title-padding: (
    'small': rem-calc(20 30)
);
$checkout-success-sidebar-accordion-body-padding: (
    'small': rem-calc(0 30 20)
);
$checkout-success-content-vertical-spacing: 'small';
$checkout-success-top-details-vertical-spacing: 'gutter';
$checkout-success-continue-shopping-button-icon: $icon-chevron-right;
$checkout-success-create-account-button-icon: $icon-account;
$checkout-success-actions-toolbar-mobile-breakpoint: 'medium';

// Sales Order Items
$checkout-sales-order-item-background-color: 'neutral-white';
$checkout-sales-order-item-border-color: 'neutral-light';
$checkout-sales-order-item-outer-spacing: 'checkout-sidebar';
$checkout-sales-order-item-inner-spacing: 'gutter';
$checkout-sales-order-item-image-sizes: (
    'small': column-width-percent(4)
);
$checkout-cart-order-item-image-sizes: (
    'small': 25%,
    'large': rem-calc(145)
);
$checkout-sales-order-item-content-sizes: (
    'small': column-width-percent(8)
);
$checkout-sidebar-order-item-image-sizes: (
    'small': column-width-percent(3)
);
$checkout-sidebar-order-item-content-sizes: (
    'small': column-width-percent(9)
);
$checkout-sales-order-item-info-spacing: 'xxsmall';

// Sidebar
$checkout-sidebar-grand-total-font-weight: 'semi-bold';
$checkout-sidebar-subtotal-font-weight: $checkout-sidebar-grand-total-font-weight;
$checkout-sidebar-totals-font-color: 'primary';
$checkout-sidebar-grand-total-background-color: 'neutral-lighter';
$checkout-sidebar-promo-code-remove-icon: $icon-warning;
$checkout-value-adds-list-color: 'primary';
$checkout-value-adds-mobile-breakpoint: 'small';
$checkout-sidebar-navigation-breakpoint: 'large';

// Cart
$cart-image-zoom-breakpoint: 'large';
