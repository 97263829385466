@mixin apply-customer-default-address-labels() {
    .customer-address-default-label {
        @include subheading-small();
        @include spacing($spacing-name: 'gutter', $margin-sides: ('right'));
        display: inline-block;

        &:last-of-type {
            margin-right: 0;
        }
    }
}
