@mixin apply-pdp-styles() {
    .catalog-product-view-page-main-content {
        @include apply-pdp-breadcrumb-styles();
        @include apply-pdp-layout-styles();
        @include apply-pdp-gallery-styles();
        @include apply-pdp-product-info-styles();
        @include apply-pdp-product-detail-styles();
        @include apply-product-detail-related-styles();
        @include apply-responsive-videos-styles();
        @include apply-product-detail-tooltip-styles();
        @include apply-product-detail-page-badge-styles();
    }
}

@mixin apply-pdp-breadcrumb-styles() {
    @include apply-breadcrumb-styles();

    .breadcrumbs {
        @include spacing(
            $spacing-name: 'gutter',
            $margin-sides: ('bottom')
        );
    }
}

@mixin apply-pdp-layout-styles() {
    // autoprefixer needs unique area names across the entire stylesheet to generate styles which will work in IE 11
    $left-top: grid-area-name('left-top');
    $left-bottom: grid-area-name('left-bottom');
    $right: grid-area-name('right');

    .product-content-container {
        @include breakpoint($pdp-layout-desktop-breakpoint) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: '#{$left-top} #{$right}' '#{$left-bottom} #{$right}';
            grid-template-rows: auto 1fr;
            grid-gap: rem-calc(55);
        }

        > * {
            @include breakpoint($pdp-layout-desktop-breakpoint) {
                min-width: 0; // prevent width of content from making grid flow off of screen
            }
        }
    }

    .product {
        &.media {
            margin-bottom: map-safe-get(get-spacing('gutter'), 'large');

            @include breakpoint($pdp-layout-desktop-breakpoint) {
                grid-area: $left-top;
                margin-bottom: 0;
            }
        }
    }

    .product-content-info-container {
        @include breakpoint($pdp-layout-desktop-breakpoint) {
            grid-area: $right;
        }
    }

    .product-related-container {
        @include breakpoint($pdp-layout-desktop-breakpoint) {
            grid-area: $left-bottom;
        }
    }
}

@mixin apply-pdp-gallery-styles() {
    .product {
        &.media {
            .skip {
                @include visually-hidden();
            }

            .gallery-placeholder {
                position: relative;
            }

            .product-gallery-disclaimer,
            .product-gallery-disclaimer-alternate {
                @include spacing($spacing-name: 'box', $padding-sides: ('left', 'right'));
                @include spacing($spacing-name: 'xsmall', $padding-sides: ('top', 'bottom'));
                @include subheading-small();
                background-color: get-color('neutral-lighter');

                .gallery-tooltip-button {
                    @include custom-tooltip-button-styles($icon: $icon-alert, $apply-button-position-styles: false);
                }

                @at-root {
                    .gallery-tooltip-content {
                        @include custom-tooltip-styles();
                    }
                }
            }

            .product-gallery-disclaimer {
                display: none;
            }

            .product-gallery-disclaimer-alternate {
                display: block;
            }

            .base-image-selected {
                .product-gallery-disclaimer {
                    display: block;
                }

                .product-gallery-disclaimer-alternate {
                    display: none;
                }
            }
        }
    }

    @at-root {
        .fotorama__stage__shaft-container {
            overflow: hidden;
            height: 100%;
        }

        .fotorama-item {
            &.fotorama {
                position: absolute;
                top: 0;
                overflow: visible;
                width: 100%;
            }
        }

        .fotorama__thumb-border {
            display: none;
        }

        .fotorama {
            .fotorama__nav__frame--dot {
                @include slider-dot-base-item-styles();
            }

            .fotorama__dot {
                @include slider-dot-base-styles();
                position: static;
                margin: 0;
            }

            .fotorama__active {
                .fotorama__dot {
                    @include slider-dot-base-active-styles();
                }
            }

            .fotorama__nav--dots {
                .fotorama__nav__frame {
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    .fotorama-item {
        &:not(.fotorama--fullscreen) {
            .fotorama__stage {
                @include border();
                background-color: get-color('neutral-white');
                overflow: visible;
            }

            .fotorama__video-close {
                display: none;
            }

            .fotorama__nav.fotorama__shadows--left,
            .fotorama__stage.fotorama__shadows--right {
                &::before,
                &::after {
                    display: none;
                }
            }

            .fotorama__wrap--no-controls {
                &.fotorama__wrap--toggle-arrows {
                    .fotorama__arr {
                        opacity: 1;
                    }
                }
            }

            .fotorama__nav {
                @include spacing(
                    $spacing-name: 'gutter',
                    $margin-sides: ('top'),
                    $ratio: 0.5
                );
            }

            .fotorama__wrap--slide {
                cursor: pointer;
            }

            .fotorama__arr {
                @include slider-arrow-base($slider-arrow-size);

                &.fotorama__arr--prev {
                    @include slider-arrow-prev-styles($slider-arrow-size, $important: true);
                }

                &.fotorama__arr--next {
                    @include slider-arrow-next-styles($slider-arrow-size, $important: true);
                }

                &:not(.fotorama__arr--disabled) {
                    display: block !important; // fix bug where arrows stay hidden after closing fullscreen gallery
                }

                .fotorama__arr__arr {
                    display: none;
                    background-image: none;
                }
            }

            .fotorama__thumb__arr {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: get-color($body-background-color);

                &.fotorama__thumb__arr--left {
                    .fotorama__thumb--icon {
                        @include icon($icon: $icon-chevron-left);
                    }
                }

                &.fotorama__thumb__arr--right {
                    .fotorama__thumb--icon {
                        @include icon($icon: $icon-chevron-right);
                    }
                }

                &.fotorama__arr--disabled {
                    opacity: 1;

                    .fotorama__thumb--icon {
                        color: get-color('accent-3');
                    }
                }

                .fotorama__thumb--icon {
                    background-image: none;
                    padding: 0;
                    width: auto;
                    font-size: rem-calc(20);
                    color: get-color('primary');
                }
            }
        }
    }
}

@mixin apply-pdp-product-info-styles() {
    @include apply-pdp-product-add-form-styles();
    @include apply-pdp-product-trust-icon-styles();

    .product-info-before-title {
        @include grid-row(get-spacing('xsmall'));
        display: flex;
    }

    .page-title-wrapper {
        margin: rem-calc(10 0);

        .page-title {
            display: inline;
        }
    }

    .product-info-brand {
        @include product-info-after-title();
    }

    .product-info-stock-sku {
        .available {
            @include product-info-before-title($icon-success, 'success');
        }

        .unavailable {
            @include product-info-before-title($icon-warning, 'error-dark');
        }
    }

    .product-info-currency {
        @include product-info-before-title($icon-star-on, 'primary');
    }

    .price-box {
        @include apply-price('price-large');
    }

    .msrp-tooltip-button {
        @include custom-tooltip-button-styles($apply-button-position-styles: false);
    }

    @at-root {
        .msrp-tooltip-content {
            @include custom-tooltip-styles();
        }
    }
}

@mixin product-info-before-title($icon, $color) {
    @include icon($icon: $icon) {
        margin-right: rem-calc(3);
    }
    @include font-size('small-copy');
    display: flex;
    color: get-color($color);
    font-family: get-font-stack('secondary');
}

@mixin product-info-after-title() {
    display: inline;
    color: get-color('primary');
}

@mixin custom-option-tooltips() {
    .custom-option-tooltip-button {
        @include reset-default-button-styles();
        @include font-size('tiny-copy');
        color: get-color('primary');
    }

    @at-root {
        .custom-option-tooltip-content {
            @include custom-tooltip-styles();
        }
    }
}

@mixin custom-option-info-links() {
    .custom-option-info-link {
        @include font-size('tiny-copy');
        color: get-color('primary');
        font-weight: get-font-weight('semi-bold');
    }
}

@mixin apply-pdp-product-add-form-styles() {
    .product-add-form {
        @include spacing(
            $spacing-name: 'small',
            $margin-sides: ('top'),
            $max-size: 'small'
        );
    }

    .product-options-wrapper {
        @include border(
            $sides: ('top')
        );
        @include spacing(
            $spacing-name: 'medium',
            $padding-sides: ('bottom', 'top'),
            $max-size: 'small'
        );
        @include form-styles();
        @include reset-last-form-field-margin();
        @include apply-swatch-picker-styles('.pdp-swatch-picker-modal');

        .field {
            &.no-display {
                display: none !important; //sass-lint:disable-line no-important
            }

            .custom-option-additional-content {
                @include custom-option-tooltips();
                @include custom-option-info-links();

                .option-tooltip-container,
                .value-tooltip-container {
                    display: flex;
                    justify-content: space-between;
                }

                .option-tooltip-container {
                    .custom-option-tooltip-button {
                        display: none;
                    }
                }

                .value-tooltip-container {
                    padding-left: rem-calc(12);
                }
            }
        }
    }

    .product-options-bottom {
        @include border(
            $sides: ('top')
        );
        @include spacing(
            $spacing-name: 'small',
            $padding-sides: ('top', 'bottom'),
            $max-size: 'small'
        );
        @include mage-error();

        .fieldset {
            @include breakpoint($pdp-quantity-mobile-breakpoint) {
                display: flex;
            }
        }

        .field {
            &.qty {
                @include spacing(
                    $spacing-name: 'xsmall',
                    $margin-sides: ('right', 'bottom')
                );
                @include form-styles();
                display: flex;

                @include breakpoint($pdp-quantity-mobile-breakpoint) {
                    margin-bottom: 0;
                }

                .label {
                    @include spacing(
                        $spacing-name: 'xsmall',
                        $margin-sides: ('right')
                    );
                    color: get-color('primary');
                    text-transform: $button-text-transform;
                    font-weight: get-font-weight($button-font-weight);
                    line-height: $pdp-add-to-cart-height;

                    @each $breakpoint, $values in map-safe-get($button-sizes, 'regular') {
                        @include breakpoint($breakpoint) {
                            font-size: map-safe-get($values, 'font-size');
                        }
                    }
                }
            }

            .input-text {
                height: $pdp-add-to-cart-height;
                max-width: 100%;
            }
        }

        .control {
            width: rem-calc(55);
        }

        .tocart {
            @include button-with-icon($icon-cart, 'secondary');
            height: $pdp-add-to-cart-height;
            width: 100%;

            @include breakpoint($pdp-quantity-mobile-breakpoint) {
                width: rem-calc(220);
                max-width: 100%;
            }
        }
    }
}

@mixin apply-pdp-product-trust-icon-styles() {
    .product-info-main {
        .trust-elements {
            @include border(
                $sides: ('top')
            );
            @include spacing(
                $spacing-name: 'xsmall',
                $padding-sides: ('top', 'bottom'),
                $max-size: 'small'
            );
            @include apply-trust-elements-styles(rem-calc(40));

            @include breakpoint($pdp-layout-desktop-breakpoint) {
                margin-bottom: map-safe-get(get-spacing('small'), 'small');
            }
        }
    }
}

@mixin apply-pdp-product-detail-styles() {
    @include apply-product-detail-tech-spec-styles();

    .product-detail-info-detailed {
        .item-description {
            .product {
                &.description {
                    @include apply-cms-typography-styles();
                }
            }
        }

        .product-data-items {
            @include theme-accordion-container();
        }

        .product-data-item {
            @include theme-accordion-item();
        }

        .data-item-content-accordion-title {
            @include theme-accordion-item-title-large();
        }

        .data-item-content-accordion-content {
            @include theme-accordion-item-body();

            .attribute {
                &.description {
                    color: get-color($body-font-color);
                }
            }
        }
    }
}

@mixin apply-product-detail-tech-spec-styles() {
    .product-detail-info-detailed {
        .table-caption {
            display: none;
        }

        .additional-attributes {
            @include apply-table-alternate-styles();
        }
    }
}

@mixin apply-product-detail-related-styles() {
    .product-related-container {
        .products-grid {
            @include apply-product-slider-styles();
        }
    }

    .product-related-title {
        @include spacing(
            $spacing-name: 'medium',
            $padding-sides: ('top', 'bottom'),
            $max-size: 'small'
        );
        @include border($sides: ('top'));

        @include breakpoint(get-previous-breakpoint($pdp-layout-desktop-breakpoint) 'down') {
            border-top: 0;
        }
    }
}

@mixin apply-product-detail-tooltip-styles() {
    @at-root {
        .delivery-timing-tooltip {
            @include custom-tooltip-styles(
                $side: null,
                $align: null
            );
            max-width: rem-calc(450);
        }
    }

    .delivery-timing-wrapper {
        @include spacing(
            $spacing-name: 'small',
            $padding-sides: ('bottom'),
            $max-size: 'small'
        );
        display: inline-flex;
        align-items: center;
    }

    .delivery-timing-text {
        @include font-size('small-copy');
        font-weight: get-font-weight('semi-bold');
        color: get-color('primary');
    }

    .delivery-timing-tooltip-button {
        @include custom-tooltip-button-styles($apply-button-position-styles: false);
        @include spacing($spacing-name: 'xxsmall', $margin-sides: ('left'));
    }

    .delivery-timing-tooltip {
        @include custom-tooltip-button-styles();
    }
}
