@mixin apply-customer-account-dashboard-styles() {
    .customer-account-dashboard {
        @include apply-customer-account-page-layout();
        @include apply-customer-account-sidebar();
        @include apply-customer-account-page-titles();
        @include apply-customer-account-forms();
        @include apply-customer-default-address-labels();
        @include apply-customer-account-section-titles();
        @include apply-customer-account-box-titles();

        .main {
            .block {
                @include spacing($spacing-name: 'small', $padding-sides: ('bottom'));
            }
        }

        .block-dashboard-info,
        .block-dashboard-addresses,
        .block-addresses-default,
        .block-addresses-list {
            .block-content {
                @include grid-row($vertical-gutters: true);
                @include apply-customer-account-boxes();
            }

            .account-box-wrapper {
                @include grid-column(
                    $responsive-sizes: (
                        'small': column-width-percent(12),
                        'large': column-width-percent(6)
                    )
                );
                display: flex;

                .box {
                    width: 100%;
                }

                .box-content {
                    flex-grow: 1;
                    flex-shrink: 0;
                }

                .box-actions {
                    @include spacing($spacing-name: 'xsmall', $margin-sides: ('top'));

                    a {
                        @include spacing($spacing-name: 'xsmall', $margin-sides: ('right'));

                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }

                .action {
                    &.edit {
                        @include link-with-icon($customer-account-action-edit-icon);
                    }

                    &.change-password {
                        @include button($size: 'tiny');
                    }

                    &.delete {
                        @include link-with-icon($customer-account-action-delete-icon);
                    }
                }
            }
        }
    }
}
