@mixin apply-animation-keyframes() {
    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes slide-from-left {
        0% {
            transform: none;
        }

        100% {
            transform: translateX(100%);
        }
    }

    @keyframes slide-from-right {
        0% {
            transform: none;
        }

        100% {
            transform: translateX(-100%);
        }
    }
}

@mixin slide-from-animation(
    $display: $slide-in-animation-display-value,
    $duration: $slide-in-animation-display-duration,
    $side: $slide-in-animation-direction
) {
    display: unquote($display);
    animation: slide-from-#{$side} forwards get-timing-function($global-timing-function) get-duration($duration);
}

@mixin slide-from-animation-x-positioning($side) {
    $x: '';
    @if ($side == 'left') {
        $x: 'right';
    } @else if ($side == 'right') {
        $x: 'left';
    }
    #{$x}: 100%;
}

@mixin fade-in-animation(
    $display: $fade-in-animation-display-value,
    $duration: $fade-in-animation-display-duration
) {
    display: unquote($display);
    animation: fade-in get-duration($duration);
}

@mixin fade-in-out-hidden-state(
    $duration: $fade-in-animation-display-duration
) {
    transition: opacity get-duration($duration), visibility get-duration($duration) step-end;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

@mixin fade-in-out-active-state(
    $duration: $fade-in-animation-display-duration
) {
    transition: opacity get-duration($duration), visibility get-duration($duration) step-start;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}
