@mixin responsive-accordion-container($breakpoint: $responsive-accordion-destroy-viewport) {
    @include breakpoint(get-previous-breakpoint($breakpoint) down) {
        @include theme-accordion-container();
    }
}

@mixin responsive-accordion-item($breakpoint: $responsive-accordion-destroy-viewport) {
    @include breakpoint(get-previous-breakpoint($breakpoint) down) {
        @include theme-accordion-item();
    }
}

@mixin responsive-accordion-item-heading($breakpoint: $responsive-accordion-destroy-viewport) {
    @include breakpoint(get-previous-breakpoint($breakpoint) down) {
        display: none;
    }
}

@mixin responsive-accordion-item-title($breakpoint: $responsive-accordion-destroy-viewport) {
    @include breakpoint(get-previous-breakpoint($breakpoint) down) {
        @include theme-accordion-item-title();
    }

    @include breakpoint($breakpoint) {
        display: none;
    }
}

@mixin responsive-accordion-item-body($breakpoint: $responsive-accordion-destroy-viewport) {
    @include breakpoint(get-previous-breakpoint($breakpoint) down) {
        @include theme-accordion-item-body();
    }
}
