@mixin apply-northern-checkout-success-styles() {
    .checkout-success {
        @include spacing($checkout-success-content-vertical-spacing, $margin-sides: ('bottom'));
        @include border($sides: ('bottom'));
        @include spacing($checkout-success-content-vertical-spacing, $padding-sides: ('bottom'));
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .checkout-success-details {
            @include spacing($checkout-success-top-details-vertical-spacing, $margin-sides: ('bottom'));
            flex-grow: 1;

            .checkout-success-details-order-number {
                @include heading-styles-h3();
                @include spacing($spacing-name: 'xsmall', $margin-sides: ('top', 'bottom'));
            }
        }

        .create-account-button-container {
            width: 100%;

            .create-account-link {
                @include button-with-icon($type: 'primary', $size: 'small', $icon: $checkout-success-create-account-button-icon);
                display: inline-block;
            }
        }

        .action.primary,
        .create-account-link {
            @include spacing('gutter', $margin-sides: ('bottom'), $ratio: 0.5);
            width: 100%;

            @include breakpoint($checkout-success-actions-toolbar-mobile-breakpoint) {
                width: auto;
            }
        }

        .actions-toolbar {
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 0;
            width: 100%;

            @include breakpoint($checkout-success-actions-toolbar-mobile-breakpoint) {
                width: auto;
            }

            .primary {
                @include button-with-icon($type: 'primary', $size: 'small', $icon: $checkout-success-continue-shopping-button-icon, $use-before: false);
            }
        }
    }

    .checkout-success-content {
        @include spacing($checkout-success-content-vertical-spacing, $margin-sides: ('bottom'));

        .order-items-container {
            @include apply-northern-checkout-success-order-item-styles();
        }
    }

    .order-success-sidebar {
        @include apply-order-success-sidebar-styles();
    }
}
