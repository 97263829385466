@mixin apply-customer-account-order-tables() {
    .table-wrapper {
        @include apply-table-responsive-styles($desktop-table-selector: '.table-order-items');

        .table-caption {
            @include visually-hidden();
        }

        .action {
            white-space: nowrap;

            &:not(:last-of-type) {
                margin-right: rem-calc(5);
            }

            &.view {
                @include link-with-icon($icon: $icon-cart);
            }

            &.order {
                @include link-with-icon($icon: $icon-refresh)
            }
        }
    }
}
