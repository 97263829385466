@mixin font-faces-from-map($font-family, $weights-styles-map) {
    @each $font-weight, $styles-map in $weights-styles-map {
        @each $font-style, $font-file in $styles-map {
            @font-face {
                text-rendering: optimizeLegibility;
                font-family: $font-family;
                font-weight: get-font-weight($font-weight);
                font-style: $font-style;
                src: url('#{$font-path}/#{to-lower-case($font-family)}/#{$font-file}.woff2') format('woff2'),
                url('#{$font-path}/#{to-lower-case($font-family)}/#{$font-file}.woff') format('woff');
            }
        }
    }
}
