@mixin apply-swatch-picker-modal-styles($modal-class-name) {
    @at-root {
        body {
            &.is-body-modal-open {
                .modal-overlay {
                    &.swatch-picker-modal-overlay {
                        overflow: auto;
                    }
                }
            }
        }

        #{$modal-class-name} {
            @include theme-modal(
                $width: rem-calc(850),
                $spacing-name: 'swatch-picker-modal'
            );
            @include property('max-width', $swatch-picker-widths);
            flex-direction: row;
            margin-top: rem-calc(10); // Leave this margin alone. It aligns the modal to the top to prevent the modal from jumping around due to different content height (without trying to break it). Issue can happen when modal is vertically centered and shorter than window.

            &.is-open {
                display: flex;
            }

            &.is-preview {
                width: auto;

                .swatch-picker-item-list-container {
                    display: none;
                }

                .swatch-picker-swatch-large {
                    display: block;
                    margin-right: 0;
                }
            }

            .swatch-picker-swatch-large {
                display: none;

                @each $breakpoint, $size in $swatch-picker-preview-size {
                    @include breakpoint($breakpoint) {
                        width: calc(#{$size} + (#{map-safe-get($global-border-width, 'small')} * 2));
                    }
                }

                @include breakpoint($swatch-picker-desktop-breakpoint) {
                    display: block;
                    flex: 0 0 auto;
                    margin-right: $swatch-picker-preview-gutter;
                }
            }

            .swatch-picker-swatch-large-inner {
                @include property('top', get-spacing('swatch-picker-modal'));
                position: sticky;
            }

            .swatch-picker-swatch-large-inner-empty {
                @include border();
                @include property('height', $swatch-picker-preview-size);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .swatch-picker-swatch-large-img {
                @include border();
                max-width: 100%;
            }

            .swatch-picker-swatch-large-caption {
                @include small-copy();
                margin-top: rem-calc(10);

                a {
                    color: inherit;
                }
            }

            .swatch-picker-item-list-container {
                flex: 1 1 auto;
                overflow: hidden; // Fix IE 11 issue where column breaks out of container.
            }

            .swatch-picker-item-list {
                @include unstyled-list();
            }

            .swatch-picker-item {
                position: relative;
            }

            .swatch-picker-item-label {
                display: flex;
                flex: 1 1 auto;
                justify-content: space-between;
                cursor: pointer;
                padding: rem-calc(10 15);
                line-height: 1;

                &::before {
                    display: none;
                }

                &.is-highlighted {
                    box-shadow: inset 0 0 0 1px get-color('primary'); // sass-lint:disable-line property-units
                }
            }

            .swatch-picker-item-radio {
                &:checked {
                    ~ .swatch-picker-item-label {
                        background-color: get-color('information-light');

                        .swatch-picker-item-swatch {
                            border-color: get-color('neutral-white');
                        }
                    }
                }
            }

            .swatch-picker-item-swatch {
                @include border($width: rem-calc(2));
                display: inline-block;
                flex-shrink: 0;
                margin-left: rem-calc(10);
                background-size: cover;
                width: rem-calc(40);
                height: rem-calc(20);
            }
        }
    }
}

@mixin apply-swatch-picker-styles($modal-class-name) {
    @include apply-swatch-picker-modal-styles($modal-class-name);

    .swatch-picker-container {
        display: flex;
    }

    .swatch-picker-trigger-container {
        position: relative;
        flex: 1 1 auto;
        z-index: get-z-index('neutral-sibling');
    }

    .swatch-picker-button {
        @include reset-default-button-styles($remove-outline: true);
        @include apply-base-dropdown-anchor-styles();
        position: absolute;
        top: 0;
        left: 0;
        z-index: get-z-index('above-sibling');
        background: get-color('transparent');
        cursor: default;
        width: 100%;
        height: 100%;

        &:hover,
        &:focus {
            ~ .form-label {
                .swatch-picker-select {
                    @each $breakpoint, $value in $input-border-width-hover {
                        @include breakpoint($breakpoint) {
                            @include button-border($value, $input-border-color-hover);
                        }
                    }
                }
            }
        }
    }

    .swatch-picker-swatch-thumbnail {
        @include border();
        display: block;
        margin-left: map-safe-get(get-spacing('gutter'), 'large') * 0.5;
        background-position: center;
        background-size: cover;
        width: rem-calc(50);
        max-height: rem-calc(46);
    }
}
