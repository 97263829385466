@mixin apply-checkout-sidebar-totals-styles() {
    .cart-totals .table-wrapper,
    .totals-table-wrapper {
        @include apply-table-styles();
        @include checkout-sidebar-section-padding($sides: ('left', 'right'));

        table {
            @include font-size('checkout-sidebar-totals');

            caption {
                @include visually-hidden();
            }

            > tbody {
                > tr {
                    color: get-color($checkout-sidebar-totals-font-color);

                    > td,
                    > th {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    th {
                        font-weight: normal;
                    }

                    &:last-of-type {
                        border-bottom: none;
                    }
                }

                .mark {
                    text-align: left;
                }

                .amount {
                    text-align: right;
                }
            }
        }

        .subtotal,
        .totals.sub {
            @include font-size('checkout-sidebar-subtotal');

            > td,
            > th {
                font-weight: get-font-weight($checkout-sidebar-subtotal-font-weight);
            }
        }

        .totals.grand {
            @include spacing($spacing-name: 'checkout-sidebar', $padding-sides: ('left', 'right'));
            @include spacing($spacing-name: 'xsmall', $padding-sides: ('top', 'bottom'));
            @include spacing($spacing-name: 'checkout-sidebar', $margin-sides: ('left', 'right'), $ratio: -1);
            @include border($sides: ('top'));
            @include font-size('checkout-sidebar-grand-total');
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            font-weight: get-font-weight($checkout-sidebar-grand-total-font-weight);
            background-color: get-color($checkout-sidebar-grand-total-background-color);
            color: get-color($checkout-sidebar-totals-font-color);

            .mark {
                text-transform: uppercase;
            }
        }
    }
}

@mixin apply-checkout-sidebar-order-items() {
    @include checkout-sidebar-section-padding($sides: ('left', 'right'));
    background-color: get-color($checkout-sales-order-item-background-color);

    .order-item-row {
        @include checkout-sales-order-item(
            $image-responsive-sizes: $checkout-sales-order-item-image-sizes,
            $content-responsive-sizes: $checkout-sales-order-item-content-sizes
        );
        @include border($sides: ('bottom'));
        @include checkout-sidebar-section-padding($sides: ('top', 'bottom'));
        display: flex;
        flex-wrap: wrap;

        &:last-of-type {
            border-bottom: 0;
        }

        .item-name {
            @include typography-anchor();
        }

        .item-price {
            @include font-size('price-medium');
        }

        .product-photo {
            @include checkout-sidebar-section-padding($sides: ('right'));
        }
    }
}

@mixin apply-checkout-sidebar-promo-form() {
    @include checkout-sidebar-section-padding($sides: ('right', 'bottom', 'left'));

    .checkout-sidebar-promo-intro-text {
        @include spacing($spacing-name: 'xsmall', $margin-sides: ('bottom'));
    }

    .applied-coupons {
        @include unstyled-list();

        .applied-coupon-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .message {
            color: get-color('success');
        }
    }

    .coupon-fieldset {
        @include font-size('small-copy');
        @include spacing($spacing-name: 'xsmall', $margin-sides: ('bottom'));
        display: flex;
        align-items: center;

        .field {
            flex-grow: 1;
        }
    }

    .action-apply {
        @include button('primary');
    }

    .action-cancel-coupon {
        @include reset-default-button-styles();
        @include link-with-icon($icon: $checkout-sidebar-promo-code-remove-icon, $icon-spacing: rem-calc(2));
    }
}

@mixin apply-northern-checkout-sidebar-styles() {
    .checkout-sidebar-container {
        .checkout-sidebar-accordion-container {
            @include theme-accordion-container();
        }

        .checkout-sidebar-accordion-item {
            @include theme-accordion-item();
        }

        .checkout-sidebar-accordion-header {
            @include theme-accordion-item-title($background-color: 'neutral-lightest');
            @include border($color: $checkout-sales-order-item-border-color, $sides: ('top'));
            @include spacing($spacing-name: 'checkout-sidebar', $padding-sides: ('left', 'right'));

            &::before,
            &[aria-expanded='true']::before {
                @include property('right', get-spacing('checkout-sidebar'));
            }

            &.sidebar-summary-header {
                @include border($color: $checkout-sales-order-item-border-color, $sides: ('left', 'right', 'bottom'));

                &::before {
                    @include breakpoint($checkout-sidebar-navigation-breakpoint) {
                        display: none;
                    }
                }
            }
        }

        .checkout-sidebar-accordion-body {
            @include theme-accordion-item-body();
            @include font-size('small-copy');
            color: get-color('body');
            padding-bottom: 0;

            .field {
                margin-bottom: 0;
            }

            &.sidebar-content {
                @include border($color: $checkout-sales-order-item-border-color, $sides: ('left', 'right'));

                @include breakpoint($checkout-sidebar-navigation-breakpoint) {
                    display: block;
                }
            }
        }

        .checkout-sidebar-order-items {
            @include apply-checkout-sidebar-order-items();
        }

        .checkout-totals {
            @include apply-checkout-sidebar-totals-styles();
        }

        .checkout-sidebar-promo-form {
            @include apply-checkout-sidebar-promo-form();
        }

        .checkout-sidebar-footer {
            .checkout-value-adds {
                @include apply-checkout-value-adds-list();
                @include spacing($spacing-name: 'xxsmall', $margin-sides: ('top'));
                display: none;

                @include breakpoint('large') {
                    display: block;
                }
            }
        }
    }
}
