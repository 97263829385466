@mixin apply-slider-styles($display: block) {
    @include apply-slider-base-styles();
    @include slider-prevent-fouc-by-hiding-until-ready($display: $display);
    @include apply-slider-dot-styles();
    @include apply-slider-arrow-styles();
}

@mixin apply-hero-slider-styles() {
    @include apply-slider-base-styles();
    @include slider-prevent-fouc-by-hiding-until-ready($display: block);
    @include apply-slider-dot-styles($color: 'neutral-white');
    @include apply-slider-arrow-styles($size: $hero-slider-arrow-size);
    @include apply-image-slider-base-styles();
}

@mixin apply-slider-dot-styles(
    $size: $slider-dot-size,
    $spacing: $slider-dot-spacing,
    $color: 'primary',
    $bottom: $slider-dot-bottom
) {
    .slick-dots {
        @include unstyled-list();
        @include property('bottom', $bottom);
        display: flex !important; // sass-lint:disable-line no-important
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        li {
            @include slider-dot-base-item-styles($spacing);

            &.slick-active {
                button {
                    @include slider-dot-base-active-styles($color);
                }
            }
        }

        button {
            @include slider-dot-base-styles($size, $color);
        }
    }
}

@mixin slider-dot-base-item-styles($spacing: $slider-dot-spacing) {
    margin-right: $spacing;

    &:last-child {
        margin-right: 0;
    }
}

@mixin slider-dot-base-active-styles($color: 'primary') {
    background-color: get-color($color);
}

@mixin slider-dot-base-styles(
    $size: $slider-dot-size,
    $color: 'primary'
) {
    @include slider-reset-button-styles();
    border-radius: 50%;
    background-color: rgba(get-color($color), 0.4);
    cursor: pointer;
    width: $size;
    height: $size;
}

@mixin apply-image-slider-base-styles() {
    img {
        width: 100%;
    }

    a {
        display: block;
    }
}

@mixin apply-slider-arrow-styles($size: $slider-arrow-size) {
    .slick-arrow {
        @include unstyled-list();
        @include slider-arrow-base($size);

        &.slick-prev {
            @include slider-arrow-prev-styles($size);
        }

        &.slick-next {
            @include slider-arrow-next-styles($size);
        }
    }
}

@mixin slider-arrow-prev-styles($size: $slider-arrow-size, $important: false) {
    @include icon($icon: $icon-chevron-left);
    @include slider-arrow-offset($size, -1, $important: $important);
    left: 0;
}

@mixin slider-arrow-next-styles($size: $slider-arrow-size, $important: false) {
    @include icon($icon: $icon-chevron-right);
    @include slider-arrow-offset($size, $important: $important);
    right: 0;
}

@mixin slider-arrow-base($size: $slider-arrow-size) {
    @include slider-reset-button-styles();
    @include property('width', $size);
    @include property('height', $size);
    position: absolute;
    top: 50%;
    z-index: get-z-index('above-sibling');
    border-radius: 50%;
    background-color: get-color('primary');
    cursor: pointer;
    color: get-color('neutral-white');

    @each $breakpoint, $value in $size {
        @include breakpoint($breakpoint) {
            @include icon-selector() {
                font-size: calc(#{$value} * 0.5);
            }
            width: $value;
            height: $value;
        }
    }

    &:hover,
    &:focus {
        background-color: get-color('accent-3');
    }
}

// Prevent horizontal scrollbar caused by arrows when they are larger than max width container gutter
@mixin slider-arrow-offset($arrow-sizes, $ratio: 1, $important: false) {
    @each $breakpoint, $value in $arrow-sizes {
        $gutter-size: get-breakpoint-value(get-spacing('gutter'), $breakpoint);
        $offset-value: if($gutter-size < ($value / 2), ($ratio * $gutter-size), ($ratio * 50%));

        @include breakpoint($breakpoint) {
            transform: translate($offset-value, -50%) if($important, !important, null); // sass-lint:disable-line no-important
        }
    }
}

@mixin slider-reset-button-styles() {
    display: block;
    outline: none;
    border: 0;
    padding: 0;
    font-size: 0; // make text take up no space while leaving it readable for screen readers
}

@mixin slider-prevent-fouc-by-hiding-until-ready($display: block) {
    display: none;

    &.slick-initialized {
        display: $display;
    }
}

// Fix flexbox bug where slick slider grows to millions of pixels wide.
// The cause of this problem is display flex on one of it's parent elements.
//
// Apply this mixin to the parent of the slider.
// https://github.com/kenwheeler/slick/issues/982
@mixin slider-bug-fix-flexbox() {
    min-width: 0;
    min-height: 0;
}

@mixin apply-slider-base-styles() {
    @include apply-slider-slick-base-styles();
    position: relative;
    z-index: get-z-index('neutral-sibling'); // create stacking context to prevent child item z-index from interacting with foreign elements
}

// Styles ported from slick repo base styles. Try to avoid modifying these since some styles are essential for slick to work.
@mixin apply-slider-slick-base-styles() {
    &.slick-slider {
        display: block;
        position: relative;
        box-sizing: border-box;
        user-select: none;
        touch-action: pan-y;
    }

    &.slick-initialized {
        .slick-slide {
            display: block;
        }
    }

    &.slick-loading {
        .slick-slide {
            visibility: hidden;
        }

        .slick-track {
            visibility: hidden;
        }

        img {
            display: none;
        }
    }

    .slick-list {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        overflow: hidden;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
        }
    }

    .slick-track {
        display: block;
        position: relative;
        top: 0;
        left: 0;

        &::before,
        &::after {
            display: table;
            content: '';
        }

        &::after {
            clear: both;
        }
    }

    .slick-track,
    .slick-list {
        transform: translate3d(0, 0, 0);
    }

    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px; // sass-lint:disable-line property-units

        img {
            display: block;
            width: 100%;
        }

        &.dragging {
            img {
                pointer-events: none;
            }
        }
    }

    .slick-arrow {
        &.slick-hidden {
            display: none;
        }
    }
}
