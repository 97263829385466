@mixin apply-sales-view-styles() {
    .sales-order-shipment,
    .sales-order-invoice,
    .sales-order-creditmemo,
    .sales-order-view,
    .sales-order-print {
        @include apply-sales-order-view-page-title-content-styles();
        @include apply-sales-order-view-navigation-tab-styles();

        .order-details-items {
            @include apply-order-item-details-styles();
        }

        .block-order-details-view {
            @include apply-order-details-styles();
        }
    }
}
