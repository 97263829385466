@mixin product-item-wrapper() {
    @include card-link-overlay();
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    z-index: get-z-index('neutral-sibling');
    height: 100%;
    min-height: 1px;
}

@mixin product-item-section(
    $item-section-border-sides: $product-grid-item-section-border-sides,
    $item-section-padding-sides: $product-grid-item-section-padding-sides,
    $item-section-spacing-size: $product-grid-item-section-spacing-size,
    $item-section-background-color: $product-grid-item-section-background-color
) {
    @include border($sides: $item-section-border-sides);
    @include spacing($item-section-spacing-size, $padding-sides: $item-section-padding-sides);

    @if $item-section-background-color {
        background-color: get-color($item-section-background-color);
    }
}

@mixin product-grid-item(
    $background-color: $box-body-background-color,
    $text-color: $global-foreground-colors,
    $section-border-color: $global-border-color,
    $section-border-width: $global-border-width,
    $section-borders: null,
    $section-divider: $product-grid-item-section-divider,
    $ratio: 0.5
) {
    position: relative;

    .product-item-info {
        @include product-item-wrapper();
        @include set-background-color($background-color, $text-color);
        @include product-grid-item-hover-styles();
    }

    .product-item-photo {
        text-align: center;

        .image {
            display: inline-block;
        }
    }

    .product-item-top {
        $border-sides: null;
        $padding-sides: ('top', 'right', 'left');

        @if $section-borders == 'top' {
            $border-sides: ('all');
            $padding-sides: ('all');
        } @else if $section-borders == 'full' {
            $border-sides: ('top', 'right', 'left');
        }

        @include product-item-section($item-section-border-sides: $border-sides, $item-section-padding-sides: $padding-sides);
        min-height: 1px; // IE 11 image stretch fix

        @if $section-divider {
            &::after {
                @include border($sides: ('bottom'), $width: $section-border-width, $color: $section-border-color);
                @include spacing($product-grid-item-section-spacing-size, $margin-sides: ('top'));
                display: block;
                width: 100%;
                height: 0;
                content: '';
            }
        }
    }

    .product-item-details {
        $border-sides: null;

        @if $section-borders == 'bottom' {
            $border-sides: ('all');
        } @else if $section-borders == 'full' {
            $border-sides: ('right', 'bottom', 'left');
        }

        @include product-item-section($item-section-border-sides: $border-sides);
        @include product-item-details();
    }
}
