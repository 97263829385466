@mixin apply-customer-account-action-button($button-type) {
    @include grid-column(
        $responsive-sizes: (
            'small': column-width-percent(12),
            'medium': auto
        ),
        $flex-grow: 0
    );
    @include spacing($spacing-name: 'gutter', $margin-sides: 'bottom');

    @include breakpoint('medium') {
        margin-bottom: 0;
    }

    .action {
        @include button($button-type);
        display: block;
    }
}

@mixin apply-customer-account-forms() {
    .form-edit-account,
    .form-address-edit {
        @include form-styles();
        @include apply-address-form-layout();

        .default-address-labels {
            @include spacing($spacing-name: 'gutter', $margin-sides: ('bottom'));
        }

        .password-info {
            display: none;
        }
    }

    .actions-toolbar {
        @include grid-row();
        justify-content: space-between;

        .primary {
            @include apply-customer-account-action-button($customer-account-action-buttons-submit-type);
        }

        .secondary {
            @include apply-customer-account-action-button($customer-account-action-buttons-back-type);
        }
    }
}
